import { computed, ref } from "vue";
import { PlatformType } from "@/modules/pwa/types";

export function usePlatform() {
  const platform = ref<PlatformType>("desktop");

  const detectPlatform = () => {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      platform.value = "android";
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      platform.value = "ios";
    } else {
      platform.value = "desktop";
    }
  };

  const isAndroid = computed(() => platform.value === "android");
  const isIOS = computed(() => platform.value === "ios");
  const isDesktop = computed(() => platform.value === "desktop");

  detectPlatform();

  const isPWADownload = ref(false);

  const checkDownloadPwa = () => {
    if (isAndroid.value) {
      //@ts-ignore
      if (navigator.getInstalledRelatedApps) {
        //@ts-ignore
        navigator.getInstalledRelatedApps().then((apps) => {
          isPWADownload.value = apps.length > 0;
        });
      }
    } else {
      isPWADownload.value = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
    }
  };

  checkDownloadPwa();

  return {
    platform: computed<PlatformType>(() => platform.value),
    isAndroid,
    isIOS,
    isDesktop,
    isPWADownload,
  };
}
