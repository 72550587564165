<script lang="ts" setup>
import { computed } from "vue";
import UiButton from "@/ui/UiButton.vue";
import UiBottomSheet from "@/ui/UiBottomSheet/UiBottomSheet.vue";

defineOptions({
  name: "NotShowAnymoreModal",
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const notShow = () => {
  _modelValue.value = false;
  localStorage.setItem("isShowMoreDownloadPwaModal", "no");
};
</script>

<template>
  <UiBottomSheet
    v-model="_modelValue"
    title="Почему не хотите установить приложение?"
    subtitle="Мы больше не покажем вам эту информацию, но будем очень признательны за обратную связь!"
  >
    <template #footer>
      <UiButton
        size="l"
        color="grey"
        label="Не понимаю, что это"
        full-width
        @click="notShow"
      />

      <UiButton
        size="l"
        color="grey"
        label="Пользуюсь через браузер"
        full-width
        @click="notShow"
      />

      <UiButton
        size="l"
        color="grey"
        label="Установлю позже"
        full-width
        @click="notShow"
      />
    </template>
  </UiBottomSheet>
</template>
