import { usePlatform } from "@/modules/pwa/composables/use-platform";
import { useDownloadAndroid } from "@/modules/pwa/composables/use-download-android";
import { useRouter } from "vue-router";

export function useDownloadPwa() {
  const { platform, isPWADownload } = usePlatform();
  const { downloadPWAAndroid } = useDownloadAndroid();
  const router = useRouter();

  const scrollToIos = () => {
    goToPwaPage().then(() => {
      const element = document.getElementById("instruction-ios");

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  };

  const scrollToAndroid = async () => {
    goToPwaPage().then(() => {
      const element = document.getElementById("instruction-android");

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  };

  const goToPwaPage = async () => {
    await router.push({
      name: "PWAView",
    });
  };

  const goToAndroid = async () => {
    switch (platform.value) {
      case "ios":
        await goToPwaPage();
        break;

      case "android":
        if (!isPWADownload.value) {
          await downloadPWAAndroid();
        } else {
          await goToPwaPage();
        }
        break;

      case "desktop":
        await goToPwaPage();
        break;

      default:
        await goToPwaPage();
        break;
    }
  };

  return {
    scrollToAndroid,
    scrollToIos,

    goToAndroid,
    goToPwaPage,
  };
}
