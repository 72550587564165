import landing from "./landing";
import { balanceKz } from "@/modules/balance/i18n/kz";

export default {
  balance: {
    ...balanceKz,
  },

  //SYSTEM
  sys: {
    error: "Қате: ",
    descError: "Сипаттама: ",
    wrongLogin: "Логин дұрыс емес",
    wrongPass: "Құпиясөз дұрыс емес",
    unknown: "Белгісіз",
    unauthenticated: "Авторланбаған",
    iikAlready: "Бұл ЖСК-мен заңды тұлға бар",
    noMoney: "Теңгерім жеткіліксіз",
    wrongPeriod: "ProPlus тек бір жылға",
    noPermissions: "Пайдаланушыда қажетті рұқсаттар жоқ.",
    firstLoginManager: "Кіру бірінші рет орындалды, құпия сөз ойластырыңыз",
    limitShops: "Сауда нүктесін құру тарифпен шектелген",
    limitLegalEntity: "Заңды тұлғаны құру тарифпен шектелген",
    wrongTariff: "Сізді тарифте бұл қол жетімді емес",
    wrongTariffPromo: "Промокод тіркеу үшін жарамсыз",
    tagExist: "Бұл тег қазірде бар",
    503: "Сервер қол жетімді емес",
    blockManager: "Менеджер бұғатталған",
    wrongCode: "Қате код",
    codeExists: "Серіктестің промокоды қате енгізілген",
    noExam: "Емтихан тапсырылған жоқ",
    noActivateAccount: "Аккаунт белсендірілмеген",
    emailExist: "Бұл электрондық поштаның мекен жайымен иесі бар",
    userExist: "Мұндай пошта басқа сервистерде бұрын тіркелген",
    noAccepted: "Жаңа келісім қабылданған жоқ",
    balanceNotEnough: "Теңгерімді қаражат жеткіліксіз",
    innExist: "Бұл ЖСН-мен заңды тұлға бар",
    noTodayChangeTariff:
      "Тарифті ағымдағы тариф аяқталғанға дейін бір күн бұрын ғана өзгертуге болады",
    badToken: "Бұл сілтеме жарамсыз",
    wrongEmail: "Пошта дұрыс емес",
    emailTaken: "Электрондық мекенжай бос емес",
    notAvailableCurrentTariff: "Ағымдағы тариф бойынша қол жетімді емес",
    dataNoCorrect: "Мәліметтер қате",
    accountNotFound: "Аккаунт табылған жоқ",
    TPExist: "Сауда нүктесі бар",
    serverError: "Сервердің қателігі",
    ownerExist: "Аккаунт табылған жоқ",
    unauthorizedAction: "сіздің құқығыңыз жеткіліксіз",
    legalEntityExist: "Заңды тұлға табылған жоқ",
    tariffChangeOneDay:
      "Тарифті ағымдағы тариф аяқталғанға дейін бір тәулік бұрын өзгертуге болады",
    blockedByTariff: "Тариф бойынша бұғатталған",
    documentInProcessOfCreation: "Документ в процессе формирования",
    emailAlreadyVerified: "Ваша почта уже подтверждена!",
    emailConfirmed: "Ваша почта успешно подтверждена!",
    codeDoesNotExist: "Введенный код недействителен",
    defaultCashbackCanNotBeDeleted:
      "Вознаграждение для клиентов без брендированной карты не может быть удалено",
    cashbackUpdateSuccessful: "Процент вознаграждения был успешно обновлен",
    cashbackDeleteSuccessful: "Вознаграждение удалено",
    cashbackCreateSuccessful: "Вознаграждение создано",
    fullCashback: "Клиент без брендированной карты",
    clientGroupAlreadyInUse:
      "Группа клиентов уже используется в группе кешбека",
    enteredAmountIsAlreadyInUse:
      "Введенная сумма вознаграждения уже выставлена",
    dateStartIsInvalid: "Дата начала периода введена некорректно",
    dateEndIsInvalid: "Дата конца периода введена некорректно",
    organizationNotConfirmed: "Юр.лицо не подтверждено",
    periodNotAvailable: "Выбранный период недоступен для покупки",
    notAvailableInYourCountry: "Выбранный период недоступен для покупки",
    exceededActivatedPromotionLimit:
      "Только 3 активированных акции на одну торговую точку",
    tradingPointLimitOnProPlus:
      "На тарифе ProPlus возможно создание не более 30 торговых точек",
    tradingPointLimitOnPromo:
      "На тарифе ProPlus возможно создание не более 15 торговых точек",
    tradingPointLimitOnLight:
      "На тарифе ProPlus возможно создание не более 5 торговых точек",
    wrongDateOrder:
      "Дата начала периода должна быть раньше, чем дата конца периода",
    descriptionFormatInvalid: "Описание не должно содержать хештегов и emoji",
    descriptionMustNotContainEmoji: "Описание не должно содержать emoji",
    descriptionMinLength:
      "Описание должно быть длинее 600 символов без учета пробелов",
    descriptionMaxLength: "Описание не должно быть длинее 3000 символов",
    maxFileSizeError: "Размер файла превышает лимит в 10 Мбайт",
    addFile:
      "Перетащите или <span style='color: #447ECB; cursor: pointer'>загрузите файлы</span>",
    balance: "Баланс",
    changeMainOrganizationError:
      "Изменить главную организацию возможно только через 30 дней после предыдущего изменения",
    resetWallet: "Не удалось сбросить дизайн wallet pass",
    updateWallet: "Не удалось обновить дизайн wallet pass",
    userUnauthorized: "Логин или пароль введены неверно",
    noData: "Нет данных",
    kb: "КБ",
    dateMask: "дд.мм.гггг",
    sort: "Сортировка",
    noDataTitle: "Данных пока нет",
    syncError: "Проблема с синхронизацией. Попробуйте еще раз позже.",

    enterName: "Введите название",
    countries: {
      ru: "Ресей",
      kz: "Қазақстан",
      md: "Молдова",
    },
    select_country: "Eлді таңдаңыз",
    country: "Ел",
    q_date: {
      days: "Жексенбі_Дүйсенбі_Сейсенбі_Сәрсенбі_Бейсенбі_Жұма_Сенбі",
      daysShort: "Же_Дү_Сей_Сә_Бе_Жұ_Сен",
      months:
        "Қаңтар_Ақпан_Наурыз_Сәуір_Мамыр_Маусым_Шілде_Тамыз_Қыркүйек_Қазан_Қараша_Желтоқсан",
      monthsShort: "Қаң_Ақп_Нау_Сәу_Мам_Мау_Шіл_Там_Қыр_Қаз_Қар_Жел",
    },
    errorTitle: "Что-то пошло не так",
    dateFrom: "Данные доступны с ",
    dateToToday: " по сегодняшний день",
    dateFormat: "Формат дд.мм.гггг или дд.мм.гггг - дд.мм.гггг",
    showBy: "Показывать по: ",
    showByMobile: "По: ",
    searchResponseEmpty: "По вашему запросу ничего не найдено",
    search: "Поиск",
    tagSearchResponseEmpty:
      "Тег не найден. Для его добавления нажмите Enter или Пробел.",
    from: "От:",
    to: "До:",
    gender: {
      m: "Мужской",
      f: "Женский",
    },
    500: "Что-то пошло не так. Что-то пошло не так. Проверьте интернет-соединение.",
  },
  entity: {
    title: "Сіздің ұйымның мәліметтерін қосыңыз",
    thirdStepTitle:
      "Мәліметтердің дұрыстығын тексеруіңізді өтінеміз. Енді біз сіздің заңды тұлға екендігіңізді растау үшін 24 сағаттан 48 сағатқа дейін күту қажет.",
    accept: "Мен шарттың талаптарын қабылдаймын",
    offers: "оферталар",
    titleReject: "Өтінім қабылданған жоқ!",
    rejectDescription:
      "Өкінішке орай, сіз ұсынған мәліметтер қате болды. модераторлардың жауабымен мұқият таныңыз және заңды тұлға туралы ақпаратқа өзгеріс енгізіңіз.",
    message: "Модераторлардан хабарлама:",
    change: "Мәліметтерді өзгерту",
    // titleWaiting: "Күткеніңіз үшін рахмет", – (NK-589) өзгертілді
    titleWaiting: "Тіркелгеніңіз үшін рахмет!",
    descriptionWaiting:
      "Қазіргі сәтте сіздің ұйым модерациядан өтіп жатыр. Біз сіздің мәліметтеріңіздің дұрыстығына көз жеткізгеннен кейін сіз жеке кабинетке кіре аласыз. Әдетте тексеру 24 сағаттан 48 сағатқа дейін уақыт алады.",
    descriptionWaitingRu:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p> <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    descriptionWaitingRuWithoutPos:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p> <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    // downloadAccept: "Акцептік төлем бойынша шотты жүктеу", – (NK-589) өзгертілді
    downloadAccept: "Тесттік төлемді төлеуге шотты жүктеу",
    addDone: "Поздравляем с созданием юрлица!",
    waitingApprove:
      "<p>В данный момент ваше юрлицо проходит проверку. Она занимает от 24 до 48 часов.</p><p>Для прохождения проверки вам необходимо оплатить счет с указанного юрлица, скачав его по кнопке ниже. Вы также можете найти счет в личном кабинете: в разделе «Мои  предприятия» - «Документы» под названием «Тестовый платеж».</p>",
  },

  landing,

  contacts: {
    title: "Байланыстар",
    subtitle:
      '"РК-ТЕХНОЛОГИИ" жауапкершілігі шектеулі серіктестігі( "РК-ТЕХНОЛОГИИ" ЖШҚ) <br/> Заңды мекенжайы: 191124, г. Санкт-Петербург, вн.тер.г. муниципальный округ Смольнинское, ул. Красного Текстильщика, д.10-12, литера О, помещ. 1-Н <br/>ИНН/КПП 7806578914/780601001 ОГРН 1207800157256',
    mailAddress: "Электрондық поштаның мекенжайы: ",
  },

  failed: "Қате. Әрекет орындалған жоқ",
  success: "Әрекет орындалды",
  navbar: {
    page1: "Басты бет",
    page2: "Тіркелу",
    page3: "Авторлану",
    page4: "ЖК",
  },
  registerCode: {
    ttl: "Серіктестің промокодын енгізіңіз",
    promocode: "Промокод",
    invalidLength: "Промокодтың ұзындығы қате",
    hasAccount: "Аккаунт бар ма?",
  },
  newPassword: {
    passwordChange: "Құпия сөзді өзгерту",
    enterNewPassword:
      "Жеке кабинетке кіру үшін қолданылатын жаңа құпия сөзді енгізіңіз",
  },
  registerEmail: {
    ttl: "Сіздің E-mail-ді енгізіңіз",
    hasEmail1: "Мұндай пошта бар. Авторланғыңыз ",
    hasEmail2: " келеді ме?",
    enterEmailCode: "Сіздің поштаға жіберілген кодты енгізуіңізді өтінеміз",
    noEmailCode: "Код келген жоқ па?",
    sendCodeAgain: "Кодты қайтадан ",
    spamTitle:
      "Егер сіз электрондық поштаны алмасаңыз, «Спам» қалтаңызды тексеріңіз",
  },
  resetPage: {
    title: "Құпиясөзді қалпына келтіру",
    titleOk: "Құпиясөз сәтті өзгертілді!",
    backToAuth: "Авторлануға қайту",
    messageToMail: "Көрсетілген мекенжайға сілтеме келеді!",
    enterEmail: "Жаңа поштаны енгізіңіз",
    descriptionNewEmail:
      "Қауіпсіздік мақсаттарында серіктес кабинетінің поштасы айрықша болуы тиіс. өтінеміз, жаңа поштаны енгізііз.",
    oldEmail: "Ескі пошта",
    newEmail: "Жаңа пошта",
    descriptionPassword: "Құпиясөз өзгертілмейді",
    notice:
      "Уайымдамаңыз, біз сізге электрондық пошта арқылы қалпына келтіру сілтемесін жібереміз.",
    loginInformation:
      "Жүйеге кіру ақпараты сіздің электрондық поштаңызға жіберілді",
    resetPasswordLink:
      "Құпия сөзіңізді өзгерту сілтемесін мекенжайына жібердік",
    messageNotReceived: "Хатты алған жоқсыз ба?",
    resendMail: "00:%{variable}-тан кейін кодты қайта жөнелту",
  },
  //cabinet layout
  leftPanel: {
    counterparties: "Проверка контрагентов",
    entities: "Юрлица",
    tradingPoint: "Сауда нүктелері",
    employees: "Қызметкерлерді басқару",
    documents: "Құжаттар",
    businesses: "Бизнес",
    tariffs: "Тарифтер",
    historyPayment: "Төлемдер тарихы",
    promotions: "Акции",
    actsAndAccounts: "Актілер мен шоттар",
    accrualCashback: "Баллдарды есептеу",
    managers: "Менеджерлерді басқару",
    spread: "Распространение карт RC",
    analytics: "Аналитика",
    points: "Очки кассирам",
    enterprises: "Мои предприятия",
    paymentDecision: "Платежное решение",
    portfolio: "Портфель",
    employees_and_managers: "Мои сотрудники",
    loyalty: "Бейілділік бағдарламасы",
    clients: "Клиенттер",
    claims: "Өтінімдер",
    claims_history: "Өтінімдер тарихы",
    claims_groups: "Группировка клиентов",
    reviews: "Пікірлер",
    extendTariff: "Тарифтің қолданысын ұзартыңыз",
    changeTo: "тариф өзгереді",
    wallet: "Wallet картасының дизайны",
    accountIs: "Сіз кірдіңіз",
    manager: "менеджер",
    owner: "иесі",
    terminals: "Кассалық аппараттар",
    terminals_MSPOS: "RC Processing 4 в 1",
    terminals_LIMON: "Технология MPOS",
    FAQ: "FAQ",
    corporate: "Связь",
    push: "Push-уведомления",
    tips: "Безналичные чаевые",
    eMenu: "Электронное меню",
    giftCard: "Подарочные карты",
    processing: "Processing",
    services: "Сервисы",
    wallets: "Карты лояльности",
    clientsList: "Список клиентов",
    marketplaces: "Маркетплейсы",
    PromotionServices: "Продвижение",
  },
  headerButtons: {
    addCoin: "Толтыру",
    profile: "Профиль",
    exit: "Шығу",
  },
  //organization page
  organization: {
    title: "Менің ұйымдарым",
    tooltip:
      "Главное Юр лицо используется для выставления счета при пополнении баланса. Изменять главное Юр лицо можно не чаще чем раз в 2 недели",
    name: "Заңды тұлғаның атауы",
    iik: "ЖСК",
    inn: "ЖСН",
    bin: "БСН",
    bic: "Банктің Бск",
    codeFiscal: "Cod Fiscal",
    status: "Мәртебе",
    answear: "Модерация жауабы",
    button: "Толығырақ",
    bill: "Тарихы",
    deposit_sum: "Сумма депозита",
    head_name_description:
      "Введите Ф.И.О. ответственного лица, на которое будут автоматически создаваться акты и счета",
    mainOrganization: "Сіздің негізгі ұйым",
    mainOrganizationHint:
      "Основное юрлицо используется для покупки тарифа и остается активным при его деактивации. Изменить основное юрлицо можно не чаще чем раз в 14 дней",
    chooseMainOrganization: "Выберите Юр лицо",
    maxOrganizationError: "Ұйымдардың максималдық саны құрылды",
    moderOrganizationError:
      "Сіз модерация процессі өтіп жатқанда жаңа ұйымды қоса алмайсыз",
    // about organization
    aboutTitle: "Сіздің ұйым туралы ақпарат",
    typeOrganization: "Ұйымның түрі",
    bankName: "Банктің атауы",
    legalAddress: "Заңды мекенжайы",
    currentAccountNumber: "Есептік шоттың нөмірі",
    correspondentAccount: "Кор.шот",
    buttonChangeOrganization: "Ұйымның мәліметтерін өзгерту",
    buttonChangeRequisites: "Изменить данные",
    dataModeration: "Модерациядағы мәліметтер",
    organizationNotExist: "Мұндай ұйым жоқ",
    vatCode: "Код НДС",
    internationalBank: "Международный номер банковского счета",
    prompt: "Минимальный остаток на счете",
    deposit_min: "Минимальное значение ",
    addressFieldRequired: "Не введен адрес юр.лица",
    metaIsRequired: "Не введены банковские данные",
    //add organization
    add: {
      title: "Сіздің ұйымның мәліметтерін қосыңыз",
      enterData: "Сіздің ұйымның мәліметтерін енгізіңіз",
      commercial: "Коммерциялық ұйым",
      halal: "Халал ұйым",
      button: "Создать организацию",
    },
    // popup Aliexpress
    link: "Сайтқа сілтеме (міндетті емес)",
    addAliexpress: "Тауарларды AliExpress-ке қосу",
    entities: "ЮР лица",
    modalTitle: "Изменение основного юрлица",
    modalText:
      "Основное юрлицо используется для выставление счета\n" +
      "        при пополнении баланса. Ваше основное юрлицо “{old}”,\n" +
      "        будет изменено на “{new}”",
    modalSubtext: "Изменить главное Юрлицо можно не чаще чем раз в 14 дней.",
    head_name: "Басшының Т.А.Ә.",
    ogrn: "НМТН",
    legalTypes: "Заңды тұлғаның нысаны",
    taxSystem: "Салық салу түрі",
    monthlyTurnover: "Ай сайынғы айналым мөлшері",
    depositSum: "Депозит сомасы",
    okvedLabel: "Негізгі қызмет түрі",
  },
  //Trading Points page
  shop: {
    title: "Сауда нүктелері",
    name: "Сауда нүктесінің атауы",
    organization: "Ұйым",
    status: "Мәртебе",
    answear: "Модерация жауабы",
    button: "Толығырақ",
    hashtag: "Теги для торговой точки",
    searchTags: "Искать по тегам",
    //about Trading point
    sale: "RC клиенттері үшін жеңілдік",
    acceptPoints: "Бұл нүкте RC баллдарын қабылдайды",
    noAcceptPoints: "Бұл нүкте RC баллдарын қабылдамайды",
    phoneAdmin: "Администратор телефоны",
    mailAdmin: "Администратор почтасы",
    address: "Адрес",
    addressHint:
      "Егер карта сіздің розеткаңыздың мекен-жайы болмаса, ең жақын таңдаңыз және дұрыс мекенжайды орнату үшін техникалық қолдауымен байланысыңыз",
    addressComment: "Сауда нүктесі мекенжайының түсініктемесі",
    workTime: "Жұмыс уақыты",
    buttonChangeShop: "Сауда нүктесінің мәліметтерін өзгерту",
    closeShop: "Сауда нүктесін жабу",
    checkboxFeedback: "Жазу және кері байланыс үшін пайдалануға болады",
    checkboxPublish: "Сайтта жариялау",
    changePoint: "Сауда нүктесін өзгерту",
    maxShops:
      "Вы создали максимальное количество торговых точек для Вашего тарифа",
    noShops: "Сізде әлі сауда нүктесі жоқ",
    addShopButton: "Сауда нүктесін қосу",
    confirmationCloseShop:
      "Сіз сауда нүктесін өшіргіңіз келетініне сенімдісіз бе?",
    //add trading point
    add: {
      emenu: "Товары и услуги",
      reviews: "Отзывы",
      title: "Сауда нүктесін құру",
      checkboxInternational: "Халықаралық нұсқаны өздігінен жасау",
      descriptionInternational:
        "Егер сіз халықаралық нұсқаны толтырмасаңыз, ол автоматты түрде аударылатын болады",
      name: "Сауда нүктесін табуға болатын атауын енгізіңіз",
      description: "Описание",
      hashtag: "Сауда нүктесін табуға болатын хэштегтерді енгізіңіз",
      internationalName: "Халықаралық атауды енгізіңіз (тек ағылшын тілінде)",
      sale: "RC бағдарлама қатысушыларына арналған жеңілдікті енгізіңіз",
      additionalOptions: "Қосымша опциялар",
      address: "Сауда нүктесіне мекенжай мен пікірді енгізіңіз",
      internationalAddress:
        "Сауда нүктесіне халықаралық мекенжай мен түсініктемені енгізіңіз (тек ағылшын тілінде)",
      phone: "Номер телефона",
      phoneInternational:
        "Халықаралық жұмыс телефоны мен администратор поштасын енгізіңіз",
      image: "Пайдаланушыларда көрінетін фотосуреттерді қосыңыз",
      workTime: "Жұмыс уақытын көрсетіңіз",
      button: "Сауда нүктесін қосыңыз",
      options: {
        baby_chair: "Детское кресло",
        children_room: "Детский уголок/комната",
        parking: "Парковка",
        wifi: "WiFi",
        animals: "Животные",
        round_the_clock: "Круглосуточно",
        over_sixteen: "16+",
        yandex_program: "Программа Яндекс",
        no_smoking: "Курение запрещено",
      },
      yandexTooltip: "Ваше заведение есть в сервисах Яндекс.Еда, Яндекс Лавка",
      errorNoTime: "Вы не можете создать торговую точку без времени работы",
      addNewTag: "Новый тэг создан",
      mainInfo: "Основная информация",
      subInfo: "Дополнительная информация",
      bonusProgram: "Бонусная программа",
      isBonusPayable: "Скидка участникам программы RC",
      activeWait: "Задержка начислений",
      cashbackPercent: "Начисление вознаграждения",
      addressAndWorkTime: "Адрес и время работы",
      addressAndWorkTimeWithoutSpace: "Адрес и время&nbsp;работы",
      contactInfo: "Контакты",
      siteLink: "Ссылка на сайт",
      addLinks: "Добавить другие социальные сети",
      addAddressAndTime: "Добавить адрес и время работы",
      step: "Шаг",
      of: "из",
      comment: "Комментарий",
      email: "Пошта",
      time: "Часы работы",
      weekend: "Только по выходным",
      weekdays: "Только по будням",
      another: "Работает в указанные часы работы",
      contacts: "Контакты",
      links: "Ссылки",
      thatPointUseRC: "Эта точка принимает баллы RC",
      logoAndPhotos: "Логотип и фотографии",
      logo: "Логотип торговой точки",
      filling: "Фотографии торговой точки",
      banner: "Баннер торговой точки",
      logoFormat: "Соотношение сторон для логотипа — 1:1,",
      anotherImagesFormat: "Соотношение сторон для фото — 16:9",
      bannerImagesFormat: "Соотношение сторон для баннера — 2:1",
      imagesFormat: "в формате PNG, JPG и JPEG и весом не более 10 Мб",
      onlyOnline: "Торговая точка работает онлайн",
      useForRegistration: "Использовать для записи клиентов",
      addMorePhone: "Добавить резервный номер телефона",
      addMoreEmail: "Добавить резервный адрес электронной почты",
      linkTo: "Ссылка на ",
      linkName: "Название ссылки",
      cropLogo: "Обрежьте логотип",
      cropBanner: "Обрежьте баннер",
      cropPhoto: "Обрежьте фото",
      publicTradingPoint: "Опубликовать торговую точку на сайте rcpay.ru",
      maxLengthDescription: "> 600 символов",
      //edit
      editTitle: "Просмотр торговой точки",
      noImage:
        "Если Вы не видите изображение для обрезки, значит загружаемый Вами файл не соответствует формату изображений. Пожалуйста, попробуйте загрузить другой файл.",
      noCanvas:
        "Не удалось обрезать изображение. Пожалуйста, попробуйте загрузить другой файл.",
      newTag: "(Новый тег)",
      saveEditChanges:
        "Информация о Вашей торговой точке обновится после прохождения модерации",
      noCheck: "У Вас еще нет ни одного чека",
    },
    // history
    historyOperation: "История чеков",
    search: "Сатып алушыны енгізуді бастаңыз",
    noHistory: "Сіздің тарих бос",
    employee: "Қызметкер",
    customer: "Сатып алушы",
    source: "Дереккөзі",
    typeOperation: "Операция түрі",
    writing: "Шегеру",
    accrual: "Есептеу",
    sumBuy: "Сауда сомасы",
    sumWrite: "Шегеру сомасы",
    toPay: "Төлеуге",
    cashback: "Баллы",
    totalCashback: "Общее вознаграждение",
    salePopup: "Жеңілдік",
    types: {
      balance_up: "Пополнение баланса",
      balance_down: "Списание средств",
      tsp_approve: "Взаиморасчет по чеку",
      balance_refill: "Пополнение баланса",
      tariff_change: "Покупка тарифа",
      cashback_approve: "Клиентское путешествие",
      cashback_charge: "Чек. Списание баллов",
      certificate_charge: "Начисление. Использование подарочной карты",
      certificate_debt: "Списание. Выпуск подарочной карты",
      certificate_refund: "Возврат за выпуск подарочной карты",
      rc_withdraw: "Чек. Прием баллов",
      tsp_commission_fee: "Чек. Комиссия",
      tsp_commission_revert:
        "Доначисление некорректно списанной суммы за период с 1 апреля по дату начисления",
      tsp_commission_correct:
        "Корректировка взаиморасчетов в размере 1% от принятых баллов",
      manual_commission_approve: "Чек. Взаиморасчет по коммиссии",
      manual_withdraw: "Вывод средств",
      cashback_decline: "Отмена чека",
      rc_processing_receipts: "Чек кассового аппарата",
      cashback_approve_decline: "Отмена начисления за клиентское путешествие",
      partner_manual_withdraw: "Списание средств",
      inner_cashback_charge: "Чек. Списание вознаграждения",
      inner_cashback_approve: "Взаиморасчет по чеку",
      tsp_approve_payment: "Чек. Списание средств",
      rc_withdraw_approve: "Чек. Начисление за оплату баллами",
      withdraw_payment: "Чек. Списание средств",
      prodazha_top_mesta: "Покупка топ-места в rcpay",
      reserving_funds_withdrawal: "Зарезервировано к выплате",
      refund_reserved_funds_withdrawal: "Возврат резерва к выплате",
    },
    organizations: "Юр лицо",
    organizationsSelector: "Юрлица",
    moderationModalTitle: "Сообщение от модерации",
    tableSearch: "Поиск по таблице",
    customersSearch: "Поиск по покупателю",
    delete: "Вы уверены, что хотите удалить торговую точку?",
    nameSearch: "Поиск по названию",
    //edit
    editTitle: "Просмотр торговой точки",
    dataTooltip:
      "Для круглосуточно работающего заведения часы работы устанавливаются следующим образом: начало рабочего дня 00:00, конец рабочего дня 00:00.",
    preview: {
      title: "Режим предпросмотра торговой точки",
      points: "Баллы от заведения",
      discount: "Скидка",
      payPoints: "Оплата баллами",
      fromCost: "от стоимости",
      enroll: "Связаться",
      onMap: "На карте",
      aroundTheClock: "Круглосуточно",
      yandex: "Программа Яндекс",
      online: "Онлайн",
      comment: "Комментарий к адресу: ",
      flags: {
        wifi: "WiFi",
        baby_chair: "Детское кресло",
        children_room: "Детская комната",
        animals: "Можно с животными",
        parking: "Парковка",
        no_smoking: "Курение запрещено",
        over_sixteen: "",
      },
    },
    clients: "клиенту: ",
    other: "комиссия: ",
    total: "Итого: ",
  },
  //Employee page
  employee: {
    title: "қызметкерлер",
    personName: "Тегі аты",
    shop: "Сауда нүктесі",
    login: "Логин",
    password: "Құпиясөз",
    last_name: "Тегіңізді енгізіңіз",
    first_name: "Атыңызды енгізіңіз",
    createEmployee: "қызметкерді жасау",
    noShops: "Қызметкерді жасау үшін сізге кемінде бір сауда нүктесі қажет",
    createShop: "Сауда нүктесін жасауға өту",
    noEmployees: "Сізде әлі қызметкерлер жоқ",
    addEmployee: "Қызметкерді қосу",
    deleteEmployee: "Сіз қызметкерді өшіргіңіз келетініне сенімдісіз бе?",
    downloadRCPay: " RC KASSA-ны жүктеу",
    //score
    titleScore: "Қызметкердің баллдары",
    points: "баллдар",
    manageEmployee: "Кассиры",
  },
  // Managing managers page
  managers: {
    title: "Менеджерлерді басқару",
    noManagersError: "Сізде әлі менеджер жоқ",
    addManager: "Менеджерді қосу",
    managerCreated: "Менеджерді құру",
    managerReset: "Менеджердің құпиясөзі жойылды!",
    oneTimePass: "Менеджердің бір ретік құпиясөзі",
    messageManagerCreated:
      "Назар аударыңыз! Бұл құпиясөзді жазып қойыңыз, менеджердің бірінші авторизациялануы кезінде оған жаңа құпиясөз жасау керек болады.",
    changePass:
      "Внимание! Запишите данный пароль, при первой авторизации менеджера, ему необходимо будет создать новый пароль",
    add: {
      title: "Менеджерді қосу",
      second_name: "Менеджердің тегі",
      first_name: "Менеджердің аты",
      patronymic: "Менеджердің әкесінің аты",
      position: "Менеджердің лауазымы",
      phone: "Менеджердің телефон нөмірі",
      permissions: "Кабинет беттеріне кіру",
      button: "Менеджерді құру",
    },
    //change
    changeTitle: "Менеджерді өзгерту",
    buttonChange: "Менеджердің мәліметтерін өзгерту",
    buttonResetPassword: "Құпиясөзді жою",
    //reset password
    newPassword: "Жаңа құпиясөзді енгізіңіз",
    newPassRules1: "Қемінде 8 таңба болуы тиіс",
    newPassRules2: "Латын әліпбиінің әріптері мен сандарынан тұрады",
    //delete manager
    deleteManager: "Сіз менеджерді өшіргіңіз келетініне сенімдісіз бе?",
  },
  //Documents page
  documents: {
    title: "Құжаттар",
    descriptionAcceptance: "Тесттік төлем",
    descriptionCertificate: "Сертификат",
    instruction: "Нұсқаулық",
    shortInstruction: "Краткая инструкция",
    instructionDescription: "RC KASSA қосымшасын пайдалану үшін",
    instructionCabinetDescription: "ТСП жеке кабинетімен жұмыс бойынша",
    shortInstructionRCKassa: "по работе с приложением RC KASSA",
    marketingMaterials: "Маркетингтік материалдар",
    marketingMaterialsDescription1:
      "Нүктелерді рәсімдеуге арналған файлдар мен флаер шаблондары",
    agreementMerchantOffer: "ТСП көмегімен ұсыныс",
    agreementMerchantOfferDescription: "ТСП-мен жұмыс бойынша шарт",
    confidentialPolitics: "Политика конфиденциальности",
    confidentialPoliticsDescription:
      "Политика конфиденциальности персональных данных интернет-сайта",
    rewardScenario: "Сценарии распределения вознаграждения",
    rewardScenarioDescription:
      "Описание сценариев распределения вознаграждения",
    tspTariffs: "Тарифы для подключения ТСП",
    tspTariffsDescription: "Информация о тарифах",
    instructionCashier:
      "Кассирге арналған кассалық аппаратты қолдану бойынша нұсқаулық",
    commercialOffer: "Коммерциялық ұсыныс",
    instructionRCKassa: "RC KASSA қосымшасын пайдалану бойынша",
    dateOfSigningContract: "Дата подписания договора ",
  },

  //check counterparties page
  counterparties: {
    title: "Проверка контрагентов",
    buttonCheck: "Проверить",
    question:
      "Дата регистрации, ИНН, КПП, ОГРН,  Основной вид деятельности, Статус юрлица,ИФНС, Юридический адрес, Ген. директор, Учредители, Уставный капитал",
    instructionButton: "Инструкция для проверки контрагентов",
    listTitle: "Как использовать Бот для проверки контрагентов?",
    listItemFirst:
      "Введите ИНН или ОГРН организации, которую собираетесь проверять.",
    listItemSecond: "Telegram-Бот покажет вам всю необходимую информацию.",
    listItemThird:
      "Для проверки блокированных счетов нужно нажать на кнопку «Проверить блокировку счетов».",
    listItemFourth:
      "После нажатия на кнопку «Проверить блокировку счетов» Бот покажет все заблокированные счета (при их наличии), ФНС, дату блокировки, № постановления, основание  в соответствии со статьей. Данную информацию можно скачать в PDF-формате для подробного изучения: нажмите кнопку «Скачать файл отчетом».",
    instructionCardTitleFirst: "Зачем проверять контрагентов?",
    instructionCardTitleSecond: "Своевременно узнавайте о фактах.",
    instructionCardTextFirst:
      "<p>Проверка контрагентов необходима для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений — продолжить или прекратить сотрудничество. <br> Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания.</p>",
    instructionCardTextSecond:
      "<p>С помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретной выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.</p>",
  },

  //business page
  business: {
    title: "Бизнес",
    name: "Бизнестің атауы",
    type: "Қызмет түрі",
    status: "Мәртебе",
    answear: "Модерация жауабы",
    noBusiness: "У сізде әлі бизнес жоқ",
    addBusiness: "Бизнесті қосу",
    createBusiness: "Создать бизнес",
    //about
    logoAndBanner: "Компанияның логотипі мен баннері",
    shops: "Сауда нүктелері",
    socials: "Әлеум.желілерге сілтемелер",
    description: "Бизнестің сипаттамасы",
    changeBusiness: "Бизнес мәліметтерін өзгерту",
    fileSizeLogo: "Логотип жақтарының қатынасы 1-ге 1",
    fileSizeBanner: "Баннер жақтарының қатынасы2-ге 1",
    website: "Вебсайт",
    inst: "Instagram",
    vk: "VK",
    fb: "Facebook",
    //add
    add: {
      title: "Бизнесті құру",
      name: "Бизнестің атауын енгізіңіз",
      nameInternational:
        "Бизнестің халықаралық атауын енгізіңіз (тек ағылшын тілінде)",
      logo: "Компанияның логотипін жүктеңіз",
      banner: "Каталог үшін баннерді жүктеңіз",
      type: "Қызмет түрін көрсетіңіз",
      typeInternational: "Қызметтің халықаралық түрін көрсетіңіз",
      shops: "Сауда нүктелерін қосыңыз",
      socials: "Әлеум.желілерге сілтеме қосыңыз",
      description: "Бизнестің сипаттамасын қосыңыз",
      descriptionInternational:
        "Бизнестің халықаралық сипаттамасын қосыңыз (тек ағылшын тілінде)",
      button: "Бизнесті қосу",
      activity_type: "Тип деятельности",
      filePrompt:
        "<li>Файл не может быть больше 10МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    },
    //change
    titleChange: "Бизнесті өзгерту",
    categories: {
      titles: {
        food: "Еда",
        shopping: "Шопинг",
        health_and_beauty: "Красота и здоровье",
        technique: "Техника",
        house: "Дом",
        for_children: "Детям",
        entertainment: "Развлечения",
        sport: "Спорт",
        hobby: "Хобби",
        services: "Услуги",
        other: "Другой",
        "arenda-i-bronirovanie": "Аренда и бронирование",
      },
      activities: {
        cafe: "Кафе",
        restaurants: "Рестораны",
        shops: "Магазины",
        bars_and_clubs: "Бары и клубы",
        fast_food: "Фастфуд",
        coffee_shops: "Кофейни",
        canteens: "Столовые",
        special: "Специальное",
        clothing: "Одежда",
        shoes: "Обувь",
        accessories: "Аксессуары",
        cosmetics: "Косметика",
        care: "Уход",
        spa: "Спа",
        medicine: "Медицина",
        pharmacy: "Аптеки",
        electronics: "Электроника",
        appliances: "Бытовая техника",
        office_equipment: "Оргтехника",
        communication_salons: "Салоны связи",
        household_goods: "Хозяйственные товары",
        repair: "Ремонт",
        furniture: "Мебель",
        garden: "Сад и огород",
        clothes_and_footwear: "Одежда и обувь",
        toys: "Игрушки",
        school: "Школа",
        education: "Обучение",
        parks: "Парки",
        cinema: "Кино",
        spaces: "Пространства",
        tourism: "Туризм",
        special_form: "Спец. форма",
        movement: "Передвижение",
        gyms: "Спортзалы",
        sections: "Секции",
        goods_for_creativity: "Товары для творчества",
        specialty_stores: "Специализированные магазины",
        studios: "Студии",
        renovation_and_construction: "Ремонт и строительство",
        health_and_beauty: "Красота и здоровье",
        computer_help: "Компьютерная помощь",
        photo_and_video: "Фото и видео",
        gifts_holidays: "Подарки, праздники",
        supermarkets: "Супермаркеты",
        leisure: "Досуг",
        auto: "Авто",
        pet_supplies: "Зоотовары",
        renovations_and_interiors: "Ремонт и интерьеры",
        rent_and_hire: "Аренда и прокат",
        books_and_stationery: "Книги и канцтовары",
        jewelry: "Ювелирные изделия",
        trips: "Путешествия",
        gas_station: "АЗС",
        halal: "Халяль",
        children_clothes_and_footwear: "Одежда и обувь",
        sport_clothes_and_footwear: "Одежда и обувь",
        other: "Другое",
      },
    },
    messages: {
      message: "Не верный тип файла!",
      caption: "Файл должен быть jpg или png",
      error_message: "Загрузите логотип и баннер!",
    },
  },
  //Tariffs page
  tariff: {
    title: "Тарифтер",
    yourTariff: "Сіздің тариф",
    lite_p: {
      title: "Лайт",
      list: [
        "Регистрация 1 организации",
        "Создание 5 торговых точек",
        "Упрощенная статистика",
        "Доступ к RC KASSA",
        "Электронный документооборот",
        "Взаиморасчеты 2 раза в месяц",
        "Раздел вопросов и ответов",
      ],
    },

    lite_p_nko: {
      title: "Базовый",
      list: [
        "Первоначальный тариф",
        "Доступен ограниченный функционал кабинета",
      ],
    },

    promo_p: {
      title: "Промо",
      beforeTariff: "Доступно всё из тарифа Лайт...",
      list: [
        "Регистрация 15 организаций",
        "Создание 15 торговых точек",
        "Добавление 150 товаров",
        "Push-рассылка для клиентов",
        "Оцифровка клиентов",
        "Распространение карт RC",
        "Дизайн Wallet-карты",
        "Электронное меню",
        "Функция обратной связи",
        "Приоритетная поддержка",
      ],
    },

    pluspro_p: {
      title: "Про+",
      beforeTariff: "Доступно всё из тарифов Лайт и Промо...",
      list: [
        "Регистрация 30 организаций",
        "Создание 30 торговых точек",
        "Расширенная статистика",
        "Добавление бесконечного числа товаров",
        "Раздел электронного меню и категории",
        "Приоритетная поддержка",
      ],
    },

    corporate: {
      title: "Корпоративный",
      list: [
        "Оцифровка клиентов",
        "Функционал «Пригласить»",
        "Подраздел «Список клиентов»",
        "Дизайн собственной Wallet-карты",
        "Push-рассылка для клиентов",
        "Функция обратной связи",
        "Акция «-50% на продление тарифа»",
        "<p>Монетизация до 30% от начисленного \n" +
          'вознаграждения клиента <span style="color: #CD5959">*</span></p>',
      ],
    },

    faq: [
      {
        title: "Как происходит оцифровка клиента?",
        text:
          "<p>Для оцифровки клиента вам необходимо предоставить ему промокод. Он может быть в виде реферальной ссылки, QR-кода или непосредственно самого кода оцифровки. С его использованием клиенту необходимо пройти регистрацию в клиентском сервисе rcpay.ru.</p>" +
          "<p>Обратите внимание: чтобы считать QR-код, клиенту понадобится камера с соответствующей функцией или специальное приложение для считывания QR-кодов.</p>",
      },
      {
        title: "Что значит «активный» клиент?",
        text:
          "<p>Это оцифрованный вами клиент, который совершил покупку в онлайн- или офлайн-заведении экосистемы RC Group. Онлайн-покупки подразумевают шопинг в RC Market, приобретение сертификатов, активацию сервисов Яндекс, покупку пользовательского тарифа. Операции в офлайн — это покупки в заведениях в городе с использованием RC.Kassa.</p>" +
          "<p>При покупке тарифа «Корпоративный» каждый партнер участвует в акции  «скидка 50%» на продление тарифа. Основное условие этой акции — оцифровать от 500 «активных» клиентов.</p>",
      },
      {
        title: "Что будет, если не продлить тариф?",
        text: "<p>В таком случае функционал кабинета сократится до уровня тарифа «Базовый». Вам останется доступен подраздел «Список клиентов», но вы лишитесь возможности выпускать карты Wallet, отправлять Push-уведомления и доступа к сервису «Безналичные чаевые».</p>",
      },
      {
        title: "Как привлечь и оцифровать больше клиентов?",
        text: "<p>Используйте функционал «Поделиться» для размещения в соцсетях реферальной ссылки на оцифровку. А также размещайте тейбл-тенты, наклейки-стикеры, листовки, воблеры в вашем заведении.</p>",
      },
      {
        title: "Моего вопроса нет, как получить ответ?",
        text:
          "<p>Задайте вопрос в техподдержку. Для этого нажмите на значок RC Group в правом нижнем углу, выберите «Telegram» и опишите в сообщении свою проблему, по необходимости приложите скрины. С вами обязательно свяжется наш специалист, чтобы решить вопрос в кратчайшие сроки. </p>" +
          "<p>Также можно отправить письмо на email: <b>hello@rc.company</b> </br>" +
          "или позвонить по телефону: <b>8 (800) 500-57-62.</b></p>",
      },
    ],
    // developmentTariff: "Тариф әзірленуде",
    // lite: {
    //   ttl1: "1 ұйымды тіркеу",
    //   descr1: "5 сауда нүктелерін құру",
    //   product1: "Возможность создать 20 товаров",
    //   ttl2: "Жеңілдетілген статистика",
    //   descr2: "Ұйымдармен және сауда нүктелерімен жұмыс",
    //   ttl3: "RC KASSA-ға қол жетімділік",
    //   descr3: "Шоттар мен актілер онлайн",
    //   ttl4: "Промо-материалдар",
    //   descr4: "FAQ"
    // },
    // promo: {
    //   ttl1: " Lite тарифінің барлық артықшылықтары",
    //   descr1: "15 ұйымды тіркеу",
    //   ttl2: "15 сауда нүктесін құру",
    //   descr2: "Қосымша статистика",
    //   product2: "Возможность создать 150 товаров",
    //   ttl3: "Клиенттің портреті",
    //   descr3: "Клиенттер үшін Push-жіберілім",
    //   ttl4: "Кері байланыс қызметі",
    //   descr4: "Жеке меншік Wallet-картаның дизайны"
    // },
    // proplus: {
    //   ttl1: " Promo тарифінің барлық артықшылықтары",
    //   descr1: "30 ұйымды тіркеу",
    //   ttl2: "30 сауда нүктесін құру",
    //   descr2: "Кеңейтілген статистика",
    //   product2: "Безлимит на создание товаров",
    //   ttl3: "Клиенттер тізімі",
    // },
    // popup: {
    //   chosePeriod: "Кезеңді таңдаңыз",
    //   description: "Оны кез келген уақытта ұзартуға болады",
    //   organization: "Шегеру ұйымы",
    //   fullPrice: "Қорытынды баға",
    // },
  },
  //History of payment page
  history: {
    title: "Төлемдер тарихы",
    number: "Нөмірі",
    type: "Түрі",
    typeBuyTariff: "Тарифті сатып алу",
    typeAddBalance: "Теңгерімді толтыру",
    typePaymentAct: "Акт бойынша шотты төлеу",
    typePaymentAcceptance: "Тестік төлемді төлеу",
    amount: "Сома",
    currency: "Валюта",
    date: "Күні және уақыты",
    noHistory: "Сіздің тарихыңыз бос",
    entityName: "Название организации",
    operationsTab: "Все операции",
    processingTab: "RC Processing",
    reward: "Вознаграждение",
    paidFromPurchase:
      "{cash} {currency} оплачено <span>(с покупки на {amount} {currency})</span>",
    downloadModal: {
      title: "Скачать файл",
      fileType: "Тип файла",
      sendOnEmail: "Отправить копию на почту",
    },
  },
  //actsAndAccounts page
  actsAndAccounts: {
    title: "Актілер мен шоттар",
    number: "Нөмірі",
    name: "Атауы",
    amount: "Сомасы",
    date: "Күні және уақыты",
    status: "Мәртебе",
    button: "Жүктеу",
    accounts: "Шоттар",
    acts: "Актілер",
    actsNew: "Акты (новые)",
    noActs: "Сізде әлі актілер жоқ",
    buttonDetail: "Толық қарау",
    creteAccount: "Шотты құрастыру",
    accountsTypeText: "Төлемге шоттар",
    actsTypeText: "Кезең ішінде жасалған операциялардың акті",
    noAccounts: "Шоттар жоқ",
    arbitraryAct: "Кездейсоқ акт құру",
    choseDate: "Датаны таңдаңыз",
    downloadAct: "Актіні жүктеу",
    viewAct: "Актіні қарау",
    downloadArbitraryAct: "Кездейсоқ актіні жүктеу",
    noCustomActs: "Таңдалған кезең үшін чектер жоқ",
    hideTitle: "«Акты и счета» временно находятся на обновлении",
    hideBody:
      "<div>" +
      "<p>Обращаем Ваше внимание, что подраздел <b>«Акты и счета»</b> <br> в разделе <b>«Мои предприятия»</b> временно находится на обновлении.</p>" +
      "<p> Сформировать счет на оплату Вы сможете в разделе <b>«Баланс»</b>. <br> Выберите опцию <b>«Пополнить»</b> и <b>«Ввести сумму»</b>, введите сумму <br> и нажмите <b>«Выставить счет»</b>, после чего — <b>«Скачать счет»</b>. </p>" +
      "<p> Акт сверки можно запросить через тех. поддержку, написав на почту <a href='mailto:{link}'>{link}</a>.<br> Для обращения укажите юр. наименование и период,<br> за который Вам необходим акт сверки. </p>" +
      "<p>Приносим извинения за доставленные неудобства.</p>" +
      "<p><b>С уважением, RC Group.</b></p>" +
      "</div>",
  },
  // Claims and history claims page
  claims: {
    title: "Өтінімдер",
    historytitle: "Өтінімдер тарихы",
    group_name: "Название группы",
    group_nomination: "Наименование группы",
    group_sum: "Сумма покупок (без учета скидок)",
    create_group: "Создать новую группу",
    edit_group: "Редактировать группу",
    delete_group: "Вы уверены что хотите удалить группу?",
    sum: "Сумма",
    percent: "Процент начисления",
    noClients: "Осы уақытқа дейін бірде-бір клиентіңіз болған жоқ",
    oneClient: "Сізде бір ғана клиент болды",
  },
  // Reviews page
  review: {
    title: "Отзывы в RC PAY",
    pageHint:
      "Данный подраздел предназначен для сбора обратной связи от посетителей ваших заведений, которая будет размещена на сайте rcpay.ru. Вы можете сами управлять какие отзывы отображать, кликнув на значок «глаз»",
    goodFirst: "Сначала положительные",
    badFirst: "Сначала отрицательные",
    newFirst: "По новизне",
    noTradingPointsData:
      "Для просмотра отзывов, необходимо создать торговую точку",
    hint: "Отзыв зависит от фильтра по датам",
    noReviews: "У вас еще нет отзывов",
    all: "Все отзывы",
    shown: "Отображаемые",
    hidden: "Скрытые",
    hideStatus: "Отзыв будет скрыт со страницы отзывов на сайте rcpay.ru",
    showStatus: "Показать отзыв на странице Вашей торговой точки на rcpay.ru?",
    noDataLabel: "Данных по отзывам нет",
    noTradingPointText: "У вас пока нет Торговых точек ",
    noReview: "Пользователи еще не оставляли отзывы о Вашей торговой точке",
  },
  //login manager an login page
  login: {
    title: "Сәлеметсіз бе!",
    subTitle: "Өтінеміз, авторланыңыз.",
    loginInput: "Логин",
    email: "Пошта",
    passwordInput: "Құпиясөз",
    approvePasswordInput: "Құпиясөзді растау",
    passwordMismatch: "Құпиясөздер сәйкес келмейді",
    backArrow: "Басты бетке оралу",
    forgotPassword: "Құпиясөзді ұмыттыңыз ба?",
    noAccount: "Аккаунт жоқ па?",
    register: "Тіркелу",
    backToService: "Отменить и вернуться к сервису",
    repeatPassword: "Құпия сөзді қайталаңыз",
  },
  //analytic page
  analytics: {
    title: "Аналитика",
    organizationSelect: "Выберите Юр лицо",
    organizationButton: "Юр лица",
    organizationCheckbox: "Барлық ұйымдар",
    newOrganizationCheckbox: "Все Юр лица",
    calendar: "Күнтізбе",
    organizations: {
      newClients: "Жаңа клиенттер",
      allClients: "Барлық клиенттер",
      sales: "Сатылымдар",
      amount: "Сатылымдар сомасы",
      amountCashback: "Олардың ішінде балдармен берілгендер",
      average: "Орташа чек",
      averageCashback: "Орташа сыйлықақы",
    },
    countClients: "клиенттер саны",
    topSumEmployee: "Сатылым сомасы бойынша топ қызметкерлер",
    topSaleEmployee: " Сатылымдар бойынша топ қызметкерлер",
    points: "Баллдар",
    addPoints: "Есептелген ұпайлар",
    writePoints: "Шегерілген ұпайлар",
    rfmAnalysis: "RFM Анализ",
    rfmGroupSizes: "Размер RFM групп",
    clientPortrait: {
      btn: "Портрет клиента",
      title: "Клиенты",
      customerCount: "Количество клиентов:",
      receiptAvg: "Средний чек:",
      receiptCount: "Количество продаж:",
      receiptSum: "Сумма продаж:",
      maleFemale: "М/Ж:",
      noGender: "Пол не указан:",
    },
  },
  // Add points page
  points: {
    title: "Ұпайларды есептеу",
    subTitle:
      "Операцияны жүргізгені үшін қызметкерлерге есептелетін баллдар санын енгізіңіз.",
    pointsForPoints: "Баллдарды есептеу үшін ұпай",
    pointsForNewClient: "Жаңа клиентке баллдарды есептеу үшін ұпай",
    employeePoints: "Очки кассирам",
  },
  // Cashback page
  cashback: {
    title: "Баллдарды есептеу",
    subTitle:
      "Обратите внимание!<br>Клиенты на тарифе FREE получают 25%,<br> Клиенты на тарифе SMART 50%,<br> Ваши оцифрованные клиенты получают 50%<br> (комиссия не взымается)<br><br>Подробнее с тарифами Вы можете ознакомиться в разделе «Мои предприятия» далее «Тарифы»",
    statusName: "Брендтелген картасы жоқ клиенттер",
    delay: "Задержка начислений (В днях)",
    setDelay: "Изменить задержку",
    editDelay: "Изменить задержку начислений",
    maxDelay: "Максимальная задержка 14 дней",
    setReward: "Установить вознаграждение",
    selectGroup: "Выберите группу",
    rewardPercent: "Введите процент начисления",
    minReward: "Минимальный процент начисления - 4",
    maxReward: "Максимальный процент начисления - 25",
    delayTitle: "Текущая задержка торговой точки:",
    withoutActiveWait:
      "Ваш пользователь получит вознаграждение в течении 30 минут с момента проведения операции",
  },
  // Spread page
  spread: {
    title: "Распространение карт RC",
    p1: "Бұл кодты сканерлеу арқылы сіздің клиенттер картаны ала алады.",
    p2: "Біз QR-коды мен « Бонустық картаны жүкте! Бонустық баллдарды жина және сауда жасауға ерекше шарттарды ал» жазбасы бар тейбл-тентті кассаның жанына немесе сіздің сатып алушылар есептесетін орынның жанына орналастыруды ұсынамыз. Мысалы, кафедеге үстелдерде, дүкендегі кассаның жанында күту аймағында немесе сіздің клиенттерінің осы ақпаратты көретініне кепілдік болатындай басқа орында.",
    getCard: "Сілтеме бойынша картаны алу",
    ttl1: " RC KASSA арқылы картаны алу",
    p4: "Сіздің QR-кодты көрсету үшін RC KASSA қосымшасын қолданыңыз: ол үшін «Wallet картасын беру» мәзірінің пунктіне өтіңіз. Клиентке ұялы телефонмен кодты сканерлеуді ұсыныңыз. Картаны мұндай тәсілмен алудың қарапайымдығына қарамастан, біз QR-кодты кассаның жанында қолдануға шақыру арқылы басып шығаруды және орналастыруды ұсынамыз.",
    ttl2: "Қызметкерлермен жұмыс",
    p5: "Әлемдік тәжірибе қызметкерлердің көбінесе бейілділік бағдарламаларын енгізуге байкот жасайтындығын көрсетеді. Бұл тек ескірген пластик қана емес, сонымен қатар RC Group сияқты сандық шешімдерге де жатады. Көптеген қызметкерлерге бейілділік бағдарламасы туралы клиенттерге айта отырып, олар ұйымның түсімін азайтатын сияқты болып көрінуі мүмкін. Мұндай растау қателік болып табылады. бейілділік бағдарламасының таралуы қаншалықты жоғары болса, орташа чек, сатып алу жиілігі және брендке бейілділік соншалықты жоғары болады. Оған қоса, бағдарламаның жоғары таралуы СМС және басқа да жіберілімдерге бизнес шығындарын азайтуға және жеке клиенттерді жақсырақ білуге мүмкіндік береді.",
    coped: "Көшірілді",
  },

  // Wallet page
  wallet: {
    title: "Wallet картасының дизайны",
    viewCard: "Wallet-картасының түрі",
    orderCard: "Картаның дизайнына тапсырыс беру",
    imageFont: "Сіздің Wallet-картаның фоны үшін суретті қосыңыз",
    imageLogo: "Сіздің Wallet-картаның логотипін қойыңыз",
    choseTitle: "QR-код, тақырып және карта нөмірінің түсін таңдаңыз",
    colorQR: "QR-код түсі",
    colorTitle: "Тақырыпша түсі",
    colorNumberCard: "Карта нөмірінің түсі",
    confirmDesign: "Дизайнды растау",
    clearDesign: "Стандартты дизайнды қайтару",
    titleNumber: "Картаның нөмірі",
    claimSend: "Өтінім жіберілді!",
    expectCall: "Біздің менеджердің қоңырауын күтіңіз.",
    orderConfirmDesign: "Сіздің дизайн жасауға өтініміңізді жіберу керек пе?",
    //new
    selectShop: "Выберите торговую точку",
    settingWalletColor: "Настройте цвет карты",
    colorBackground: "- Цвет фона",
    colorText: "- Цвет надписей",
    colorSubstrate: "- Цвет подложки",
    addLogo: "Добавьте логотип",
    withoutLogo: "Нет логотипа",
    haveLogo: "Есть логотип",
    addSubstrate: "Выберите цвет подложки",
    defaultColor: "Залить основным цветом",
    customColor: "Залить собственным цветом",
    customImage: "Установить изображение",
    logoPrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    substratePrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон подложки 3 к 1</li>",
    saveCustomDesign: "Сохранить дизайн",
    backDefaultDesign: "Вернуть стандартный дизайн",
    saveModal: "Отправить ваш запрос на создание дизайна?",

    // wallet-card
    walletCard: {
      title: "Вид Wallet карты",
      discount: "Скидка",
      balance: "Баланс",
    },
  },

  // Push notifications page
  notifications: {
    title: "Push-уведомления",
    modal:
      "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    description:
      "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
    notificationText: "Текст уведомления",
    sendError: "Не удалось отправить Push-уведомление",
    sendTime: "По московскому времени",
    noNotifications: "У вас нет созданных push-уведомлений",
    buttonNoData: "Создать PUSH-сообщение",
    table: {
      shopName: "Название торговой точки",
      header: "Заголовок",
      date: "Дата",
      time: "Время",
    },

    view: {
      shop: "Торговая точка",
      header: "Заголовок",
      message: "Текст сообщения",
      date: "Дата",
      time: "Время",
    },

    create: {
      warning:
        "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
      shop: "Выбор торговой точки",
      header: "Текст заголовка",
      message: "Текст сообщения",
      button: "Создать рассылку",
      messageAfterCreate:
        "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    },
  },

  //Clients Page
  clients: {
    title: "Список клиентов",
    allClients: "Все клиенты",
    internalClients: "Внутренние",
    externalClients: "Внешние",
    clientsType: "Тип клиента",
    noTradingPoints: {
      label: "Данных по клиентам нет",
      text: "У вас пока нет Торговых точек",
    },
    noClients: {
      label: "Данных по клиентам нет",
      text: "Оцифровывайте клиентов, чтобы они отображались в вашем списке",
    },
    table: {
      inviteDate: "Дата регистрации",
      fullName: "ФИО",
      phone: "Номер телефона",
      receiptsCount: "Количество покупок",
      receiptsSum: "Сумма покупок",
    },

    clientCard: {
      title: "Информация о клиенте",
      client: {
        title: "Клиент",
        fields: {
          fullName: "ФИО",
          gender: "Пол",
          birthDate: "Дата рождения",
          phone: "Номер телефона",
          clientType: "Тип клиента",
          inviteDate: "Дата регистрации",
          rcCard: "Номер карты RC",
          note: "Заметка",
          noteMax: "Макс. 1000 символов",
        },
      },
      statistics: {
        title: "Статистика",
        titles: {
          avgReceiptsSum: "Средний чек",
          receiptsCount: "Кол-во покупок",
          receiptsSum: "Оборот",
          writeOffPointsSum: "Опл. баллами",
        },
        totalNumberOfVisit: "Общее кол-во посещений",
        firstVisit: "Первое посещение",
        lastVisit: "Последнее посещение",
      },
      receipts: {
        title: "Чеки",
        status: {
          approved: "Проведен",
          new: "В ожидании",
          declined: "Отменен",
        },
        noReceipts: "Данных по чекам нет",
      },
      reviews: {
        title: "Отзывы",
        noReviews: "Данных по отзывам нет",
      },
    },
  },

  // Client Portrait Page
  clintPortrait: {
    title: "Клиенттің портреті",
  },

  // Terminals Page
  terminal: {
    listServiceCenters: "Список сервисных центров",
    mspos: "Инструкция mspos",
    instructions1C: "Инструкция 1С",
    title: "Кассалық аппараттар",
    typeTerminal: "Кассалық аппараттың түрі",
    terminalName: "MSPOS E-Ф бақылау-кассалық аппараты",
    fullNameTitle: "Алушының мәліметтері",
    fullName: "Алушының аты-жөні",
    postAddress: "Пошталық мекенжай",
    acquirerBank: "Эквайер банкті таңдау",
    countTerminal: "Аппараттар саны",
    fullPrice: "Қорытынды бағасы",
    delivery:
      "Жеткізілімнің жеке төленетіндігіне назарыңызды аударамыз. Жеткізу сұрақтары бойынша сізбен қосымша менеджер байланысады.",
    buy: "Сатып алу",
    documents: "Құжаттар",
    status: "Мәртебе",
    // Documents
    specifications:
      "Кассирге арналған кассалық аппаратты пайдалану бойынша нұсқаулық",
    specificationsDescription: "Құрылғының сипаттамасы",
    instruction: "Нұсқаулық",
    instructionDescription: "Құрылғының сипаттамасы",
    manual: "Аппаратты қолдану бойынша нұсқаулық",
    manualDescription: "Құрылғының сипаттамасы",
    commercialOffer: "Коммерциялық ұсыныс",
    commercialOfferDescription: "Құрылғының сипаттамасы",
    // Status
    buttonAddClaim: "Тағы бір өтінім қалдыру",
    device: "Құрылғы",
    deviceName: "Атауы",
    count: "Саны",
    aboutOrder: "О заказе",
    // Buy
    firstBlock: {
      title:
        "RC Group өзінің серіктестеріне MSPOS 4-уі 1-де кешенді шешімін ұсынады",
    },
    secondBlock: {
      title: "MSPOS E-Ф бақылау-кассалық аппараты",
      circle1: "Жасырын төлемсіз",
      circle2: "54-ФЗ сәйкес келеді",
      circle3: "1С лицензиясы сыйлыққа",
      circle4: "Клиенттердің мәліметтерін сатпаймыз",
      characteristics: "Сипаттамалар",
      OS: "Операциялық жүйе",
      dimensions: "Габариттер",
      cm: "см",
      weight: "Салмағы",
      g: "гр",
      memory: "Жады",
      screen: "Дисплей",
      screenDescription:
        "5.5 дюйм, 1280x720 px (пиксель), 320 ppi (пиксель дюймге)",
    },
    thirdBlock: {
      title: "Төлемдік БЖ",
      description:
        "Сауда және интернет-эквайерингтің кез келген міндеттерін автоматтандыруға арналған әмбебап төлем платформасы",
    },
    fourthBlock: {
      title: "Кассалық БЖ",
      description:
        "<ul> <li>игеруде қарапайым кассирдің жұмыс орыны </li> <li>Таңбаланған тауарларды сату</li> <li>Жаңа тауарларды енгізу және редакциялау, мақсаттарды басқару</li> <li>Алкогольді сату (сыртқы УТМ-ға қосу қажет)</li> <li>көрсетілген e-mail-ге сатылым туралы есепті тікелей жіберу</li> <li>1С:Касса интернет-сервисімен қарапайым синхронизация</li> <li>Enterprise Data және ККМ-оффлайн хаттамалары бойынша интеграция</li> <li> Өздігінен қызмет көрсету кассалары режимінде пайдалану мүмкіндігі бар</li> </ul>",
    },
    fifthBlock: {
      title: "Бейілділік баағдарламасы",
      subtitle1: "Жеңілдіктерді рәсімдеңіз және төлемді баллмен қабылдаңыз",
      kassaAllows: "RC KASSA мүмкіндік береді",
      description:
        "<li>Транзакциялар туралы есептер құру</li> <li>Төлемді қолма-қол ақшамен, банктік картамен және RC баллдарымен қабылдау </li> <li>Қызметкердің ұпайларын бақылау</li> <li>Қызметкердің әріптестері ұпайларын бақылау</li>",
      stayClaim: "Өтінім қалдыру",
    },
    //LIMON
    Limon: {
      title: "Технология MPOS",
      subTitle:
        "Аренда кассовых аппаратов. Зачем покупать, если можно арендовать?",
      button: "Оставить заявку",

      firstBlock: {
        title: "mPOS + Android",
        pros: [
          "Простой и удобный интерфейс приема платежа",
          "Можно отправить банковского слип-чек клиенту по смс/email",
          "История всех совершенных операций всегда под рукой",
          "В описании платежа можно вводить дополнительную информацию",
          "Работает в связке по  bluetooth со смартфоном (версия андроид от 8.1)",
          "Банковские карты (с магнитной полосой, чипом и NFC-модулем)",
          "Интеграция с любыми программами и системами",
        ],
        tableTitle: "Технические характеристики",
        table: [
          [
            "Интеграция с ККМ:  да",
            "Тип терминала:  мобильный",
            "Беспроводное подключение: да",
          ],
          [
            "Печать слипа: нет",
            "Бесконтактные платежи: да",
            "Проводное подключение: нет",
          ],
          ["Магнитные карты: да", "Экран: монохромный", "Карты с чипом: да"],
        ],
        benefit: [
          "Бесплатное оборудование для приема карт",
          "Соответствует 54-ФЗ",
          "Кассовая программа работает на платформе Android",
          "Принимает платежи всеми доступными на рынке способами",
          "Интеграция с любыми программами и системами, автоматизация финансового учета",
          "Оптимально для такси, курьерских служб и стационарных точек продаж с небольшим оборотом",
        ],
      },

      secondBlock: {
        title: "Смарт-терминал 4 в 1 со встроенным эквайрингом",
        pros: [
          "Кассовая программа",
          "Кассовое оборудование",
          "Терминал для приема банковских карт",
          "Программа лояльности",
          "Облачный бэк офис для предпринимателей",
        ],
        tableTitle: "Технические характеристики",
        table: [
          ["Размеры: 185х75х65 мм", "Вес: 408 грамм "],
          ["Доступ к интернету: Wi-Fi/2G /3G/4G", "Основная камера: 8 МП "],
          ["Навигация: GPS", "Необходим РКО: Нет"],
          [
            "Аккумулятор: 8 часов без подзарядки",
            "Фронтальная камера: 0,3 МП ",
          ],
        ],
        benefit: [
          "Быстрый запуск",
          "Соответствует 54-ФЗ",
          "Бесплатная аренда при обороте свыше 500 000 руб/мес",
          "Не требует РКО",
          "Подходит для всех отраслей бизнеса",
          "Интегрируется с большим количеством учетных системам",
        ],
      },

      loyalty: {
        title: "Программа лояльности",
        kassa: "RC KASSA",
        aboutKassa: "Оформляйте скидки и принимайте оплату баллами",
        kassaOpportunities: "RC KASSA позволяет:",
        opportunitiesList: [
          "Формировать отчеты о транзакциях",
          "Принимать оплату наличными, банковской картой и баллами RC",
          "Отслеживать очки сотрудника",
        ],
      },

      documents: {
        title: "Документы",
        about: "Описание устройства",
        date: "18.04.2022",
      },

      modal: {
        warning: "Банком эквайером является ПАО Банк ВТБ",
        fullNameTitle: "ФИО",
        fullNamePlaceholder: "Введите ФИО",
        emailTitle: "Почта",
        emailPlaceholder: "Введите почту",
        phoneTitle: "Телефон",
        phonePlaceholder: "Введите телефон",
        commentTitle: "Комментарий",
        commentPlaceholder: "Введите текст сообщения",
        organization: "Выбор организации",
        terminal: "Выбор аппарата",
        success: "Заявка успешно создана",
      },
    },
    // Terminals About Order Page
    fromOrder: "О заказе от",
    recipient: "Получатель",
    cashRegister: "Контрольно-кассовый аппарат",
    fiscalAccumulator: "Фискальный накопитель",
    courierDelivery: "Доставка курьером",
    pickupDelivery: "Самовывоз из пункта",
    deliveryError: "В выбранный город доставка не осуществляется",
    insuranceAndPackaging: "Страховка товаров и упаковка",
    standardInsurance: "Стандартная страховка",
    finalSum: "Итоговая сумма",
    downloadInvoiceForPayment: "Скачать счет на оплату",
    // Buy-terminal page
    deleteFromClaim: "Убрать из заявки",
    recoverToClaim: "Восстановить",
    driveExpirationDate: "Срок действия накопителя (в месяцах)",
    descriptionFiscalSelect:
      "Выбор зависит от специфики бизнеса (продажа акцизных товаров, общепит и т.д.), организационно-правовой формы и режима налогообложения. По окончании срока действия накопитель подлежит замене.",
    countFiscal: "Количество накопителей",
    countFifteenMonth: "Количество накопителей на 15 месяцев",
    contThirtySixMonth: "Количество накопителей на 36 месяцев",
    amountOfGoods: "Сумма за товары",
    proceedToCheckout: "Перейти к оформлению заказа",
    mixedOrder: "Смешанный заказ",
    // Order-terminal Page
    orderTerminalTitle: "Заказ кассовых аппаратов",
    toOrder: "Заказать",
    clickAgree: "Нажимая, я соглашаюсь с условиями",
    ifNeedHelp:
      "Если вам нужна помощь в регистрации кассового аппарата в ФНС, сообщите об этом менеджеру",
    outcome: "Итог",
    issueCEP: "Регистрация в налоговой",
    issueOFD: "Регистрация ОФД",
    nalogReg: "Настройка кассы",
    issueCEP_description: "Создание квалифицированной электронной подписи",
    issueOFD_description:
      "Регистрация вашей кассы в операторе фискальных данных",
    nalogReg_description: "Настройка кассы для работы сразу после получения",
    serviceProvision: "Предоставление услуги",
  },

  // FAQ Page
  faq: {
    title: "FAQ",
    cabinetPartner: "RC CITY",
    rcKassa: "RC KASSA",

    sections: {
      analytics: {
        title: "Аналитика",

        subsections: {
          organization: {
            title: "Юр.лица",
            description:
              "В разделе <b>«Аналитика»</b> представлена аналитика по всем вашим заведениям и клиентам, которые используют в RC PAY. Информация представлена на пяти вкладках с акцентом на разные данные." +
              "<br><br>" +
              "На вкладке <b>«Юр лица»</b> можно посмотреть общие данные по клиентам, в том числе новым, а также узнать средний чек, количество покупок и общую сумму продаж. Кроме того, предусмотрена возможность получить подробную информацию по каждой зарегистрированной организации и выбрать интересующий Вас период, что позволит узнать количество продаж за день, неделю, месяц, год.",
            video: [],
          },
          shops: {
            title: "Торговые точки",
            description:
              "На вкладке <b>«Торговые точки»</b> расположена расширенная аналитика предыдущего подраздела: " +
              "<ul class='doted_list'>" +
              "<li>данные по клиентам;</li>" +
              "<li>продажи;</li>" +
              "<li>сумма продаж за необходимый период;</li>" +
              "<li>данные по каждой организации;</li>" +
              "<li>информация об активности сотрудников;</li>" +
              "<li>сумма продаж по каждому сотруднику;</li>" +
              "<li>очки кассирам.</li>" +
              "</ul> Выбрав <b>«Все торговые точки»</b>, можно просмотреть общую информацию по всем имеющимся торговым точкам.",
            video: ["//video.platformcraft.ru/embed/61f795da0e47cf171e558af5"],
          },
          employee: {
            title: "Сотрудники",
            description:
              "На вкладке <b>«Сотрудники»</b> Вы можете отслеживать активность Ваших сотрудников и оценивать их продуктивность за тот или иной период по следующим критериям:" +
              "<ul class='doted_list'>" +
              "<li>имя сотрудника, проводившего транзакцию;</li>" +
              "<li>торговую точку;</li>" +
              "<li>количество продаж;</li>" +
              "<li>сумму продаж;</li>" +
              "<li>очки кассира;</li>" +
              "<li>количество списанных баллов;</li>" +
              "<li>количество начисленных баллов.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/61f795ed0e47cf171e558af6"],
          },
          RFM: {
            title: "RFM-анализ",
            description:
              "На вкладке <b>«RFM-анализ»</b> представлен анализ сегментации клиентов по частоте и сумме покупок. Он помогает выявлять тех клиентов, которые больше всего покупают у Вас." +
              "<br> <br>" +
              "Для получения информации выберите <b>«Юр лицо»</b> из всплывающего меню и период в <b>«Календаре»</b>. По итогу отобразится таблица с результатами анализа групп, которые вы сформировали в <b>«Начислении баллов»</b>.",
            video: ["//video.platformcraft.ru/embed/63aeb61c0e47cf7b45610124"],
          },
          clients: {
            title: "Портрет клиента",
            description:
              "На вкладке <b>«Портрет клиента»</b> Вы можете детально просмотреть информацию обо всех клиентах выбранной организации, выявить постоянных клиентов. На графике возраста можно ознакомиться с дополнительными данными по возрастным категориям:" +
              "<ul class='doted_list'>" +
              "<li>количеством клиентов данной возрастной категории;</li>" +
              "<li>их средним чеком;</li>" +
              "<li>количеством покупок;</li>" +
              "<li>суммой покупок.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/63aeb91c0e47cf7b45610131"],
          },
        },
      },

      loyaltyProgram: {
        title: "Программа лояльности",

        subsections: {
          addPoints: {
            title: "Начисление баллов",
            description:
              "В разделе «Начисление баллов» Вы можете настроить размер начисляемых баллов. Минимальный процент начисления – 4%, а максимальный – 25%. Чтобы усилить лояльность клиентов, группируйте их в зависимости от суммы покупок и назначайте каждой группе свой процент вознаграждения." +
              "<br>" +
              "Для создания группы клиентов выполните следующие действия:" +
              "<br>" +
              "<ol class='number_list'>" +
              "<li>Выберите торговые точки, для которых Вы хотите создать группу клиентов. Например:" +
              "<ul class='doted_list circle'>" +
              "<li>Новый клиент с покупками на сумму до 10 000 рублей – вознаграждение составит 5%.</li>" +
              "<li>Постоянный клиент с покупками от 10 000 рублей – вознаграждение составит 10%.</li>" +
              "<li>Любимый клиент с покупками от 50 000 рублей – вознаграждение составит 20%.</li>" +
              "</ul></li>" +
              "<li>Нажмите кнопку «Создать группу».</li>" +
              "</ol>" +
              "<br>На этой странице также есть возможность установить <b>«Текущую задержку торговой точки»</b> – это количество дней, спустя которое клиенту будет начислено вознаграждение за покупку. Значение может быть от 0 до 14 дней. Нажмите кнопку <b>«Изменить задержку»</b>, чтобы указать желаемый срок, а затем – <b>«Сохранить»</b>. Обратите внимание: функция будет работать корректно только при положительном балансе в RC CITY.",
            video: ["//video.platformcraft.ru/embed/658b5dd1ef3db51ca61a4ee1"],
          },
          promotions: {
            title: "Акции",
            description:
              "В подразделе <b>«Акции»</b> Вы можете создавать и отслеживать акции для своих заведений сразу или по отдельности. Информация представлена в виде таблицы со столбцами следующего содержания: название акции, торговая точка, где она проходит, процент начисления баллов (не суммируется с процентами начисления баллов из предыдущего раздела), процент скидки. Также напротив каждой акции предусмотрена возможность скрыть из <b>RC Кассы</b> у сотрудника редактировать и удалить." +
              "<br><br>" +
              "Для того, чтобы создать акцию в всплывшем окне укажите:" +
              "<ul class='doted_list'>" +
              "<li>торговую точку,</li>" +
              "<li>название акции,</li>" +
              "<li>процент начисления баллов,</li>" +
              "<li>процент скидки.</li>" +
              "</ul>" +
              "После успешного создания акции Вы можете зайти, как сотрудник в <b>RC KASSA</b>, и при нажатии кнопки <b>«Акции»</b>, отобразиться созданная в кабинете акция. ",
            video: ["//video.platformcraft.ru/embed/63aebbf9ef3db55eb798f8f2"],
          },
          historyPayment: {
            title: "История операций",
            description:
              "На вкладке <b>«Все операции»</b> в подразделе <b>«История операций»</b> будет отображена таблица со всеми пополнениями и списаниями средств с баланса в RC CITY. Чтобы получить более подробную информацию, Вы можете указать нужный вам период в календаре." +
              "<br> <br>" +
              "Также на этой странице Вы можете скачать историю операций за определенный период или за все время использования кабинета. Чтобы сделать это, нажмите кнопку <b>«Скачать»</b>, выберите формат скачивания и, по желанию, установите флажок <b>«Отправить копию на электронную почту»</b>. В этом случае файл будет отправлен на Вашу электронную почту, указанную при регистрации в RC CITY. После этого снова нажмите кнопку <b>«Скачать»</b>." +
              "<br> <br>" +
              "На вкладке <b>RC Processing</b> Вы можете увидеть информацию по всем проведенным операциям на кассовых аппаратах.",
            video: ["//video.platformcraft.ru/embed/658aeba50e47cf2ffec366a7"],
          },
        },
      },

      clients: {
        title: "Клиенты",

        subsections: {
          clients: {
            title: "Список клиентов",
            description:
              "В разделе <b>«Список клиентов»</b> доступна информация о Ваших покупателях. Чтобы просмотреть данные, выберите организацию из выпадающего списка или отметьте флажком пункт «Все организации»." +
              "<br> <br>" +
              "На вкладке <b>«Все клиенты»</b> вы увидите информацию обо всех клиентах Вашего заведения. <br>" +
              "Вкладка <b>«Внутренние клиенты»</b> покажет данные о клиентах, оцифрованных в Вашем заведении. <br>" +
              "На вкладке <b>«Внешние клиенты»</b> представлена информация о клиентах, которые посещали Ваше заведение и сделали в нем хотя бы одну покупку." +
              "<br> <br>" +
              "Внизу страницы Вы также найдете кнопку <b>«Пригласить»</b>, нажав на которую можно скопировать ссылку или скачать QR-код для регистрации клиента.",
            video: ["//video.platformcraft.ru/embed/658b5e700e47cf2ffec3675c"],
          },
          feedback: {
            title: "Отзывы",
            description:
              "В подразделе <b>«Отзывы»</b> отображаются отзывы всех клиентов, которые посещали Ваше заведение и оставили отзыв на сайте rcpay или в приложении RC PAY. <br> Для удобной работы с отзывами доступно несколько фильтров:  " +
              "<ol class='number_list'>" +
              "<li><ul class='doted_list circle'>" +
              "<li>При нажатии кнопки <b>«Выберите торговую точку»</b> отобразятся все отзывы, оставленные клиентом о данной точке</li>" +
              "<li>При выборе пункта <b>«Все торговые точки»</b> Вы сможете увидеть отзывы на все торговые точки.</li>" +
              "</ul></li>" +
              "<li>Предусмотрена возможность посмотреть отзывы за определенный период или дату. Для этого откройте календарь, выберете из списка нужный период или задайте его в календаре</li>" +
              "<li>Отфильтровать отзывы можно по 3 характеристикам: <ul class='doted_list circle'>" +
              "<li>по новизне;</li>" +
              "<li>только положительные;</li>" +
              "<li>только отрицательные. </li>" +
              "</ul></li>" +
              "</ol>" +
              "<br><br>" +
              "Также Вы можете выбрать конкретные отзывы, которые будут отображены на сайте rcpay и в приложении RC PAY. Для этого нажмите на знак просмотра у тех отзывов, которые хотите, чтобы отображались на сайте и в приложении. Обратите внимание, что скрытые отзывы не будут влиять на оценку заведения, которая будет отображаться на сайте.",
            video: ["//video.platformcraft.ru/embed/658b5e01ef3db51ca61a4ee2"],
          },
          pushNotifications: {
            title: "Push-уведомления",
            description:
              "В подразделе <b>«Push-уведомления»</b> можно настроить рассылку для Ваших клиентов, чтобы они всегда были в курсе Ваших акций и мероприятий." +
              "<ol class='number_list'>" +
              "<li>Для этого нажмите плюс напротив «Создать Push-рассылку» и заполните соответствующие поля;</li>" +
              "<li>Выберите Торговую точку, на клиентов которой планируете делать рассылку;</li>" +
              "<li>Укажите текст заголовка (например: «праздничные скидки всем»);</li>" +
              "<li>Введите текст сообщения push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.</li>" +
              "</ol>" +
              "<br>" +
              "Ваши клиенты получат push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.\n",
            video: ["//video.platformcraft.ru/embed/63aeb56e0e47cf7b45610121"],
          },
        },
      },

      wallet: {
        title: "Карты Wallet",

        subsections: {
          spread: {
            title: "Распространение карт Wallet",
            description:
              "Карта Wallet — это электронный эквивалент карт магазинов и заведений. В подразделе <b>«Распространение карты Wallet»</b> можно получить QR-код для использования его в оцифровке клиентов, а также ссылку для скачивания карты, которую можно разместить в социальных сетях, на сайте и любых других источниках, что позволит оцифровывать клиентов в любой точке мира.",
            video: ["//video.platformcraft.ru/embed/61f796daef3db553eb6a2312"],
          },
          designWallet: {
            title: "Дизайн карты Wallet",
            description:
              "В разделе <b>«Дизайн карты Wallet»</b> предусмотрена возможность создания уникального дизайн ваших карт. Для этого необходимо:" +
              "<ol class='number_list'>" +
              "<li>Выбрать вариант из выпадающего списка в разделе <b>«Выбрать организацию»</b>, для которого Вы хотите создать карты Wallet.</li>" +
              "<li>Выбрать фон: перетащите или загрузите файл с изображением в окно <b>«Вставьте картинку для фона вашей Wallet-карты»</b>. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 16:9.</li>" +
              "<li>Выбрать логотип. Перетащите или загрузите файл с изображением в окно Вставьте логотип вашей Wallet-карты. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 1:1.</li>" +
              "<li>Выберите цвет QR-кода, заголовка и номера карты.</li>" +
              "</ol>" +
              "Чтобы сохранить настройки, нажмите <b>«Изменить дизайн»</b>, а для сброса параметров — <b>«Вернуть стандартный дизайн»</b>.",
            video: ["//video.platformcraft.ru/embed/61f796e7ef3db553eb6a2313"],
          },
        },
      },

      employeesAndManagers: {
        title: "Мои сотрудники",

        subsections: {
          managingManagers: {
            title: "Менеджеры",
            description:
              "В разделе <b>«Менеджеры»</b> отображены все менеджеры, которых зарегистрировал владелец ТСП:" +
              "<ul class='doted_list'>" +
              "<li>Ф.И.О сотрудника;</li>" +
              "<li>должность;</li>" +
              "<li>номер телефона;</li>" +
              "<li>логин.</li>" +
              "</ul>" +
              "Также можно добавить, изменить, заблокировать и удалить менеджера. Для того, чтобы добавить менеджера нужно нажать плюс, указать фамилию, имя, отчество, должность, номер телефона и настроить доступ к страницам личного кабинета. Логин и пароль для входа будут сформированы программой автоматически. Помните, что пароль, после добавления менеджера, показывается единожды. Рекомендуем его сохранить в удобном для Вас месте, так как он не будет отображен в программе." +
              "<br><br>" +
              "Обратите внимание, что менеджер не имеет доступа к проведению транзакций в приложении RC.Kassa.",
            video: ["//video.platformcraft.ru/embed/61f796ad0e47cf171e558b08"],
          },
          managingEmployee: {
            title: "Кассиры",
            description:
              "В разделе <b>«Кассиры»</b> представлены данные по всем созданным сотрудникам. Для создания сотрудника необходимо нажать плюс и ввести фамилию, имя, а также выбрать торговую точку сотрудника. В таблице вы найдете логин, пароль, которые необходимы для входа в RC.Kassa, и статус сотрудника." +
              "<br><br>" +
              "Кроме того, в этой вкладке можно посмотреть информацию по набранным баллам, заблокировать, либо удалить сотрудника.",
            video: ["//video.platformcraft.ru/embed/61f796c10e47cf171e558b09"],
          },
          addPoints: {
            title: "Очки кассирам",
            description:
              "В разделе <b>«Очки кассирам»</b> можно настроить количество выдаваемых сотруднику очков за проведение операции и проведение операции с новым клиентом.\n",
            video: ["//video.platformcraft.ru/embed/61f796ccef3db553eb6a2311"],
          },
        },
      },

      enterprises: {
        title: "Мои предприятия",

        subsections: {
          organization: {
            title: "Юр лица",
            description:
              "В подразделе <b>«Юр лица»</b> Вы можете добавить имеющуюся у Вас организацию, чтобы впоследствии на ее заведения распространялась программа лояльности. Для этого нажмите знак плюса напротив «Юр лица» и заполните все имеющиеся поля: " +
              "<ul class='doted_list'>" +
              "<li>ИНН организации;</li>" +
              "<li>юридическое наименование;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>банк, обслуживающий организацию; </li>" +
              "<li>БИК;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>Ф.И.О руководителя.</li>" +
              "</ul>" +
              "Обратите внимание особое внимание на коррекспондентский и расчетный счета. В них должно быть одинаковое колличество цифр и отсутствовать лишние знаки (пробелы и точки). Также при выборе типа организации «Халяль», необходимо загрузить подтверждающий документ в формате pdf, jpeg или png. " +
              "<br><br>" +
              "Кроме того, возможность добавить вторую и последующие организации присутствует только на платных тарифах. Если у Вас несколько организаций, то Вы можете выбрать, какая из них станет основной и будет действовать даже в случае понижения тарифа. После создании организации Вы сможете отслеживать статус проверки внесенной вами информации со стороны модерации:" +
              "<ul class='doted_list'>" +
              "<li>«Подтверждено», если все данные заполнены верно и проверка пройдена успешно;</li>" +
              "<li>«Отклонено», если информация заполнена неверно или требует уточнения. Пожалуйста, обращайте внимание на комментарии модератора, они подскажут Вам, какую информацию нужно скорректировать;</li>" +
              "<li>«На модерации», если организация ожидает проверки или уже проверяется модератором.</li>" +
              "</ul>" +
              "Если модерация будет отклонена, то в поле «Ответ модерации» появится поясняющее причину сообщение. Внимательно изучите его и выполните рекомендации модератора." +
              "<br><br>" +
              "Также Вы всегда можете посмотреть информацию о выбранной организации, нажав на кнопку «Подробнее» напротив наименования:" +
              "<ul class='doted_list'>" +
              "<li>наименование организации;</li>" +
              "<li>ИНН;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>БИК банка;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>сумма депозита.</li>" +
              "</ul>" +
              "На этой странице Вы сможете поменять данные и задать сумму депозита, нажав на кнопку «Изменить реквизиты организации»." +
              "<br><br>" +
              "Сумма депозита — это размер минимального остатка на счете, т.е. при превышении выбранного вами значения со стороны RC Group будет осуществлена выплата вашей компании, а при недостижении — оплата не происходит.",
            video: ["//video.platformcraft.ru/embed/61f795f9ef3db553eb6a2303"],
          },
          shops: {
            title: "Торговые точки",
            description:
              "В разделе <b>«Торговые точки»</b> Вы найдете список всех зарегистрированных точек, историю чеков. Также здесь есть возможность при необходимости удалить точку ." +
              "<br><br>" +
              "Для добавления новой торговой точки перейдите на вкладку <b>«Торговые точки»</b> и нажмите кнопку <b>«Создать»</b> в правом верхнем углу. Затем заполните все обязательные поля. Для Вашего удобства рядом с полями есть подсказки. Постарайтесь максимально подробно и точно описать деятельность Вашей торговой точки и подчеркнуть ее уникальность с помощью описания и фотографий. " +
              "<br><br>" +
              "Важно указать юрлицо, с которым будут производиться взаиморасчеты. Для этого выберите соответствующее юридическое лицо из выпадающего списка." +
              "<br>" +
              "Заполненная информация будет отправлена на модерацию." +
              "<br>" +
              "Вы сможете отслеживать статус проверки после того, как данные торговой точки будут отправлены на модерацию:" +
              "<ul class='doted_list'>" +
              "<li><i>«Подтверждено»</i>, если все данные заполнены корректно и проверка пройдена успешно. В этом случае Ваша точка будет размещена на сайте rcpay и в приложении RC PAY.</li>" +
              "<li><i>«Отклонено»</i>, если недостаточно информации или требуются уточнения. Вы увидите пояснительное сообщение от модератора с рекомендациями по исправлению.</li>" +
              "<li><i>«На модерации»</i>, если точка ожидает проверки или уже проверяется модератором.\n</li>" +
              "</ul>" +
              "<br><br>" +
              "<b>«История чеков»</b><br>" +
              "Чтобы просмотреть историю чеков, нажмите на кнопку <b>«История чеков»</b> в строке соответствующей торговой точки. Вы можете отфильтровать и отсортировать таблицы или просмотреть конкретную транзакцию более детально, нажав кнопку <b>«Подробнее»</b>.",
            video: ["//video.platformcraft.ru/embed/658b5ee80e47cf2ffec3675e"],
          },
          // paymentMethods: {
          //   title: "Способы оплаты",
          //   description:
          //     "Теперь Вы можете подключить электронный тип оплаты (банковская карта, СБП, Yandex Pay и др.) <br>" +
          //     "На странице <b>«Способы оплаты»</b> выберите юрлицо и перейдите на вкладку <b>«Документы»</b>. В этом разделе Вы найдете документы для подписания: <b>«Заявление о присоединении»</b>, <b>«Ставки банковского эквайринга»</b>, а также <b>«Инструкцию по активации ключей»</b>. В разделе <b>«Статус»</b> Вы можете отследить процесс подключения." +
          //     "<br><br>" +
          //     "После того как документы будут подписаны и пройдут модерацию, Вы увидите сообщение модератора о ставке, которая вам одобрена. <br>" +
          //     "Далее ожидайте ключи от нашего партнера «Рокет Ворк» на электронную почту, которую Вы указали при регистрации. <br>" +
          //     "С помощью <b>«Инструкции по активации ключей»</b> Вы сможете самостоятельно активировать кассу и начать принимать платежи в этот же день.",
          //   video: ["//video.platformcraft.ru/embed/658b5e9a0e47cf2ffec3675d"],
          // },
          documents: {
            title: "Документы",
            description:
              "В разделе <b>«Документы»</b> на вкладках «Документы» и «Маркетинговые материалы» Вы сможете ознакомиться с инструкциями и материалами, относящимся к продукции RC GROUP.",
            video: ["//video.platformcraft.ru/embed/61f79688ef3db553eb6a230f"],
          },
          actsAndAccounts: {
            title: "Акты и счета",
            description:
              "В подразделе <b>«Акты и счета»</b> можно просмотреть счета и акты по вашим организациям, выбрав нужную. На вкладке «Счета» можно ознакомиться с наименованием, суммой, датой и временем, статусом платежа, также скачать нужный Вам счет в любое время. На вкладке «Акты» представлены все выставленные акты, а также есть возможность сформировать по ним счет, скачать или просмотреть детальную информацию. Кроме того, в разделе «Акты» Вы можете указать период, за который Вы бы хотели сформировать акт." +
              "<br><br>" +
              "Для удобства клиентов с 01.04.2022 в отображающуюся информацию в актах сверки внесены изменения. В старой версии документа (сформированной до 31.03.2022) отражена информация только в баллах, а в новой версии (с 01.04.2022) — информация о фактическом пополнении и списании средств с баланса в RC CITY. Чтобы скачать документы, достаточно выбрать необходимый вам вид документа и нажать кнопку «Сформировать».",
            video: ["//video.platformcraft.ru/embed/61f796680e47cf171e558af9"],
          },
          tariffs: {
            title: "Тарифы",
            description:
              "В разделе <b>«Тарифы»</b> показаны все имеющиеся тарифы. Чтобы приобрести тариф, выберите интересующее Вас предложение и нажмите «Купить тариф», выберите «Юрлицо» и нажмите кнопку «Оплатить». <br>" +
              "Для оплаты тарифа вы должны пополнить баланс, для этого сформируйте счет в разделе «Баланс» на сумму стоимости тарифа и произведите оплату с реквизитов, принадлежащих выбранной организации.",
            video: ["//video.platformcraft.ru/embed/658b5e43ef3db51ca61a4ee3"],
          },
        },
      },

      services: {
        title: "Сервисы",

        subsections: {
          checkingCounterparties: {
            title: "Проверка контрагентов",
            description:
              "В подразделе <b>«Проверка контрагентов»</b> Вы можете с помощью бота проверить контрагентов. Это необходимо для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений: продолжить или прекратить сотрудничество." +
              "<br><br>" +
              "Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания." +
              "<ol class='number_list'>" +
              "<li>Статус юридического лица: «действующая», «в процессе ликвидации», «ликвидирована».</li>" +
              "<li>Руководители.</li>" +
              "<li>Уставной капитал.</li>" +
              "<li>Состояние счетов (если они заблокированы, то вряд ли контрагент сможет расплатиться).</li>" +
              "</ol>" +
              "Кроме того, с помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретно выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.",
            video: ["//video.platformcraft.ru/embed/63aec1bb0e47cf7b45610151"],
          },
          eMenu: {
            title: "Электронное меню",
            description:
              "На странице <b>«Электронное меню»</b> Вы можете добавить свои товары или услуги, чтобы клиенты могли ознакомиться с ассортиментом Вашего заведения или магазина на сайте rcpay или в приложении RC PAY." +
              "<br>" +
              "Также внизу страницы Вы можете сформировать и скачать QR-код, который позволит перейти к меню Вашей торговой точки в несколько кликов.",
            video: ["//video.platformcraft.ru/embed/658aedae0e47cf2ffec366af"],
          },
        },
      },

      contacts: {
        title: "Контакты",

        subsections: {
          contacts: {
            title: "Способы связи",
            description:
              "По всем интересующим вопросам обращайтесь по телефону <a href='tel:88005005762' class='phone'>8 (800) 500-57-62</a> или по электронной почте  <a href='mailto:hello@rc.city' class='email'>hello@rc.city</a>. Либо напишите свой вопрос в форму обратной связи, которая расположена в правом нижнем углу, и мы свяжемся с Вами удобным для Вас способом.",
            // video: ["//video.platformcraft.ru/embed/658aecadef3db51ca61a4e4a"],
          },
        },
      },
    },
  },

  // Corporate Communication Page
  corporate: {
    title: "Корпоративная связь",
    view: "Просмотр",
    createClaim: "Создать заявку",
    suitableFor: "Для чего подойдет?",
    suitableParagraph:
      "Уникальное предложение на рынке мобильной связи — соотношение цены и качества, а также эксклюзивные функции выгодно выделяют клиентов RC Mobile от пользователей других сотовых операторов",
    suitable: "Подойдет",
    forTraveling: "Для путешествий",
    forCorporateTasks: "Для корпоративных задач",
    forBusinessTrips: "Для командировок",
    benefits: "Какие преимущества?",
    easyConnection: "Простое подключение",
    easyConnectionDescription:
      "Автоматическое определение оптимального оператора мобильной связи позволяет пользователям платить по ценам местных операторов связи, где действует RC Mobile",
    favorableRates: "Выгодные тарифы",
    favorableRatesDescription:
      "После оплаты тарифа вне зависимости от региона пребывания владельца сим-карты — он может пользоваться тарифом без дополнительных затрат не меняя мобильного оператора",
    technicalSupport: "Техподдержка",
    technicalSupportDescription:
      "Оперативная и удобная поддержка сервисной службы RC консультирует пользователей RC Mobile по всем возникающим вопросам прямо из приложения в пункте “Поддержка”",
    ourTariffs: "Наши тарифы",
    modernApplication: "Современное приложение",
    countSimCard: "Количество сим-карт",
    purposesPurchaseSIMCards: "Для каких целей приобретаете сим-карты",
    thanksForClaim:
      "Благодарим за Вашу заявку. <br> В ближайшее время с Вами свяжется менеджер для уточнения деталей.",
    forUseAbroad: "Для использования за границей",
    foUseYourCountry: "Для использования в своей стране",
    newGenerationMobileCommunication: "Мобильная связь нового поколения!",
    joinUs:
      "Присоединяйтесь к нам уже сейчас, скачав приложение от нашего технологического партнера Глонасс",
    countSim: "Кол-во сим-карт",
    enterFullName: "Введите ФИО",
    enterEmail: "Введите E-mail",
    contactPhone: "Контактный телефон",
    enterPhone: "Введите телефон",
    writeCountOfSIM:
      "Укажите сколько сим-карт Вы бы хотели заказать для корпоративного пользования",
    different: "Другое",
    comment: "Комментарий",
    commentDescription: "Введите цель приобретения",
  },

  // Answer moderation
  status: {
    moderation: "Модерацияда",
    confirmation: "Расталды",
    reject: "Қабылданбады",
    paid: "Төленді",
    active: "Белсенді",
    locked: "Бұғатталды",
    activateProcess: "Белсендіру процессінде",
    awaitingPayment: "Төлемді күтуде",
    sent: "Жіберілді",
    received: "Алынды",
    canceled: "Жойылды",
    unknown: "Неизвестно",
    confirm: "Подтвержден",
    base: "Базовая",
  },
  // Table
  table: {
    status: "Мәртебе",
    nameStatus: "Мәртебенің атауы",
    percentageAccrual: "Есептелу пайызы",
    fullName: "Аты-жөні",
    position: "Лауазымы",
    phone: "Телефон нөмірі",
    login: "Логин",
    number: "Нөмірі",
    sum: "Сома",
    customer: "Сатып алушы",
    cashback: "Баллы",
    currency: "Валюта",
    dateTime: "Күні және уақыты",
    date: "Күні",
    paid: "Төленді",
    purchase: "Саудадан",
    transaction: "Транзакция",
    type: "Түрі",
    points: "Баллдар",
    name: "Аты",
    visits: "Визиттер жиілігі",
    averageCheck: "Сатып алу сомасы",
    birthday: "Туған күні",
    age: "Жасы",
    nameAndSurname: "Тегі және аты",
    store: "Сауда нүктесі",
    operationType: "Тип операции",
    entityName: "Название организации",
  },
  // Common text
  common: {
    organizationSelect: "Ұйымды таңдаңыз",
    allOrganizationCheckbox: "",
    tradingPointsSelect: "Сауда нүктесін таңдаңыз",
    allTradingPointsCheckbox: "Барлық сауда нүктелері",
    from: "бастап",
    util: "дейін",
    to: "бойынша",
    fillField: "Осы өрісті толтырыңыз",
    invalidEmail: "Пошта дұрыс емес",
    minLengthPass: "Құпиясөз 8 таңбадан аз",
    fillTime: "Уақытты толтырыңыз",
    calendar: "Күнтізбе",
    filter: "Фильтр",
    back: "Артқа",
    loadDescription: "Өнімнің халалдық сертификатын жүктеуіңізді өтінеміз: ",
    loadFormat: ".PDF .JPG .PNG форматындағы сертификаттар қолданылады",
    loadSize: "Файлдың салмағы 10 МБ артық емес болады",
    dropFiles: "Файлды осы жерге тасымалдаңыз немесе",
    uploadFiles: "жүктеңіз",
    chooseFile: "Файлды таңдау",
    fileName: "Файлдың атауы",
    Monday: "Дүйсенбі",
    Tuesday: "Сейсенбі",
    Wednesday: "Сәрсенбі",
    Thursday: "Бейсенбі",
    Friday: "Жұма",
    Saturday: "Сенбі",
    Sunday: "Жексенбі",
    1: "Дүйсенбі",
    2: "Сейсенбі",
    3: "Сәрсенбі",
    4: "Бейсенбі",
    5: "Жұма",
    6: "Сенбі",
    7: "Жексенбі",
    dayAndNightTime: "Нүкте тәулік бойы жұмыс істейді",
    notWorkTime: "Нүкте жұмыс істемейді",
    month: "айы",
    year: "жылы",
    organizations: "Ұйымдар",
    shops: "Сауда нүктелері",
    employees: "Қызметкерлер",
    managers: "Менеджерлер",
    manager: "Менеджер",
    owner: "Иесі",
    business: "Бизнес",
    tariffs: "Тарифтер",
    historyOperations: "Операциялар тарихы",
    actsAndAccounts: "Актілер мен шоттар",
    cashback: "Ұпайларды есептеу",
    documents: "Құжаттар",
    addBalance: "Аванстық төлем",
    addPoints: "Ұпайларды есептеу",
    application: "Кассалық аппаратты төлеу",
    claims: "Өтінімдер",
    analytics: "Аналитика",
    sortNew: "Жаңа өнім бойынша",
    sortAscStars: "Жұлдыздардың азаюы бойынша",
    sortDscStars: "Жұлдыздардың өсуі бойынша",
    email: "E-mail",
    lastName: "Тегі",
    firstName: "Аты",
    error404: "Қате 404",
    noPage: "Бет табылған жоқ",
    errorTypeFile: "Файлдың типі қате!",
    tryTypeFile: "Файл jpg немесе png болуы тиіс",
    clients: "Клиенттер",
    exitAccount: "Аккаунттан шығу",
    accountsForAct: "Акт бойынша шот",
    downloadOn: "Қол жетімді",
    downloadOnRuStore: "Жүктеу",
    phone: "Телефон",
    comment: "Түсініктеме",
    pcs: "шт",
    gb: "ГБ",
    min: "мин",
    group: "Группа",
    emptyField: "Заполните все поля",
    correctField: "Все поля должны быть корректны!",
    bigFile: "Большой размер файла",
    "e-menu": "Электронное меню",
  },
  // Buttons
  button: {
    invite: "Пригласить",
    download: "Жүктеу",
    edit: "Редакциялау",
    save: "Сақтау",
    saveChange: "Өзгерістерді сақтау",
    accept: "Қабылдау",
    change: "Өзгерту",
    login: "Кіру",
    create: "Құру",
    confirm: "Растау",
    details: "Толығырақ",
    copy: "Көшіру",
    topUp: "Толтыру",
    back: "Артқа",
    send: "Жіберу",
    sendAgain: "Қайта жіберу",
    sendCode: "Кодты жіберу",
    next: "Одан әрі",
    proceed: "Жалғастыру",
    buy: "Сатып алу",
    goto: "Өту",
    open: "Ашу",
    delete: "Жою",
    cancel: "Болдырмау",
    pay: "Төлеу",
    ok: "Хорошо!",
    cancelClaim: "Отменить заявку",
    generate: "Сгенерировать",
    apply: "Применить",
    reset: "Сбросить",
    clearAll: "Очистить все",
    calendarButton: {
      today: "Сегодня",
      yesterday: "Вчера",
      lastWeek: "Последние 7 дней",
      last30Days: "Последние 30 дней",
      lastMonth: "Прошлый месяц",
      thisMonth: "Этот месяц",
      lastYear: "Последний год",
      thisYear: "Текущий год",
      allPeriod: "Весь период",
      selectRange: "Свой период",
    },
    preview: "Предпросмотр",
    resetAll: "Сбросить все",
    showResult: "Показать результаты",
    upload: "Загрузить",
    expand: "Развернуть",
    hide: "Скрыть",
    try: "Попробовать",
    add: "Добавить",
    reveal: "Показать",
    readAll: "Читать полностью",
    collapse: "Свернуть",
    logout: "Шығу",
    show: "Показать",
    moreDetails: "Подробнее",
    settings: "Настройки",
  },
  month: {
    1: "Қаңтар",
    2: "Ақпан",
    3: "Наурыз",
    4: "Сәуір",
    5: "Мамыр",
    6: "Маусым",
    7: "Шілде",
    8: "Тамыз",
    9: "Қыркүйек",
    10: "Қазан",
    11: "Қараша",
    12: "Желтоқсан",
  },
  charts: {
    pointsAccrued: "Есептелген баллдар",
    pointsWritten: "Шегерілген баллдар",
  },

  tips: {
    instruction: {
      title: "Инструкция для работы чаевых",
      tipsTitle: "Безналичные чаевые",
      tipsDescription:
        "Помощь ресторанному бизнесу и салонам красоты <br> 80% посетителей заведений оплачивают товары и услуги картами, а не наличными средствами. Это приводит к падению мотивации сотрудников и качества обслуживания: ведь персонал перестает получать чаевые. Безналичные чаевые — сервис, позволяющий мастерам и официантам получать чаевые от клиентов. Бизнес ничего не теряет — все комиссии лежат на мастерах и клиентах, к тому же чаевые не облагаются налогом.",
      howTitle: "Как это работает?",
      howDescription:
        "Персонал регистрируется в системе сервиса <br> Каждый зарегистрированный участник получает QR-код <br> QR-код можно разместить на чеке, визитке или любой другой печатной продукции <br> Клиент переводит чаевые по QR-коду <br> Персонал получает деньги <br> Успех! <br> Клиент также может взять на себя комиссию: тогда сотрудник получит полную сумму чаевых.",
    },
    presentation: "Презентация",
    forRestaurants: "Для ресторанов",
    forShops: "Для салонов",
    arrange: "Оформить заявку",
  },

  eMenu: {
    title: "Электронное меню",
    hint: '<p>С подробной информацией о работе функционала «Электронное меню» Вы можете ознакомиться в разделе Мои предприятия -  <a href={link} target="_blank">Документы</a> - Маркетинговые материалы - Инструкция по использованию RC CITY</p>',
    searchPlaceholder: "Поиск по товарам",
    productsStatuses: {
      all: "Все товары",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    categoriesStatuses: {
      all: "Все категории",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    noData: {
      title: "Данных по электронному меню нет",
      noTradingPoints: "У вас пока нет Торговых точек ",
      noProducts: "У вас пока не создано ни одного товара",
    },
    category: "Категория",

    table: {
      productName: "Название товара",
      category: "Категория",
      price: "Цена",
      status: "Статус",
      noCategory: "Без категории",
    },

    qr: "QR - код",

    QRModalTitle: "QR-код на меню",
    QRModalSubTitle:
      "Выберите торговую точку, QR-код на меню которой хотите скачать",

    productCard: {
      createTitle: "Создание карточки товара",
      editTitle: "Информация о товаре",
      images: "Изображения",
      productName: "Название товара",
      price: "Цена, {currency}",
      discount_price: "Цена со скидкой, {currency}",
      category: "Категория",
      description: "Описание",
      descriptionMax: "Макс. 1000 символов",
      image: {
        cover: "Обложка",
        coverTitle: "Загрузить обложку",
        cropPhoto: "Обрежьте фото",
        imageTitle: "Загрузить изображение",
        tooltip:
          "Добавьте обложку и изображения к товару. Максимальное количество изображений — 5.",
      },
      moderationMessageTitle: "Ответ модерации",
      dimensions: "Единицы измерения",
      dimensionsPlaceholder: "Выберите единицу измерения",
      noDimension: "Без единиц измерения",
    },

    pointsListTitle: "Отображение в торговой точке",

    deleteProduct: "Вы уверены, что хотите удалить “{label}”?",
    hideProduct: "Вы уверены, что хотите скрыть “{label}”?",
    revealProduct: "Вы уверены, что хотите показать “{label}”?",
    categoryPlaceholder: "Выберите категорию",

    limits: {
      lite: "Вы создали 20 товаров, ваш лимит исчерпан. <br> Чтобы добавить больше позиций, повысьте тариф до Promo.",
      promo:
        "Вы создали 150 товаров, ваш лимит исчерпан. <br> Для добавления товаров без ограничений повысьте тариф до Pro+",
      tariffs: "Тарифы",
    },
  },

  validationErrors: {
    emptyField: "Это поле не может быть пустым",
    invalidEmailFormat: "Введите email вида {email}",
    invalidSiteFormat: "URL должен содержать http:// или https://",
    invalidFormat: "Неверный формат",
    incorrectValueStart: "Необходимо указать значение от",
    incorrectValueEnd: "до",
    invalidValueMaxLength: "Максимальное количество символов ",
    invalidValueMinLength: "Минимальное количество символов ",
    invalidHouseNumber: "Уточните номер дома",
    invalidAddress: "Уточните адрес",
    invalidWorkDays: "Необходимо выбрать хотя бы один рабочий день",
    invalidWorkTime: "Неверный формат времени",
    errorWorkTime:
      "Время открытия не может быть позже времени закрытия или совпадать с ним",
    notEnoughElement: "Недостаточное количество элементов",
    manyElement: "Вы превысили максимальное количество элементов",
    maxLessMin: "Меньше минимального",
    invalidAge: "Некоректный возраст",
    minDate: "Дата должна быть не позднее {date}",
    maxDate: "Дата должна быть не ранее {date}",
    email: "Пошта енгізіңіз",
    requiredPhone: "Телефон нөмірін енгізіңіз",
    reqiredPromocode: "Промокод қате енгізілді",
    requiredCountry: "Елді таңдау қажет",
    havePhone: "Бұл телефон әлдеқашан тіркелген",
    haveMail: "Бұл пошта әлдеқашан тіркелген",
    invalidEmail: "Дұрыс емес пошта",
    first_name: "Атыңызды енгізіңіз",
    last_name: "Тегіңізді енгізіңіз",
    password: "Құпиясөзді енгізіңіз",
    corresp_account: "Корреспонденттік шотты көрсету қажет",
    number_account: "Есепшот нөмірін көрсету қажет",
    bik_of_bank: "Банк БСК көрсету қажет",
    international_bank: "Банктік шоттың халықаралық нөмірін көрсету қажет",
    vat_code: "ҚҚС кодын көрсету қажет",
    inn: "СТСН көрсету қажет",
    entityAddress: "Заңды мекенжайды көрсету қажет",
    fullName: "ТАӘ көрсету қажет",
    ogrn: "НМТН көрсету қажет",
    monthly_turnover: "Ай сайынғы айналым мөлшерін көрсету қажет",
    deposit_sum: "Депозит мөлшерін көрсету қажет",
    tax_system: "Салық салу түрін көрсету қажет",
    legal_type: "Заңды тұлғаның нысанын көрсету қажет",
    okved_code: "Қызметтің негізгі түрін көрсету қажет",
    minFillingPhotos: "Файлдың минималды саны - {amount}",
    notValidPromocode: "Промокод жоқ",
    cyrillicOnly: "Тек кириллица және латынша",
  },

  //  marketplaces Page
  marketplaces: {
    title: "Маркетплейсы",
    inn: "ИНН",
    phone: "Номер телефона",
    link: "Ссылка на сайт (не обязательно)",
    error: "Поля заполнены неверно",
  },

  promotions: {
    title: "Акции",
    createNewPromo: "Создать новую акцию",
    headersList: {
      name: "Название акции",
      tradingPoint: "Торговая точка",
      cashback: "Процент начисления",
      discount: "Процент скидки",
    },
    name: "Название акции",
    cashback: "Процент начисления",
    discount: "Процент скидки",
    createTitle: "Создание новой акции",
    aboutPageModal: {
      title: "Функционал подраздела “Акции”",
      body:
        'Подраздел "Акции" помогает проводить товары с разным значением скидки и разным значением начисления' +
        "вознаграждения. Для того чтобы создать акцию, нажмите соответствующую кнопку в подразделе “Акции“, затем," +
        " в всплывающем окне укажите торговую точку, название акции, процент начисления и процент скидки.\n" +
        "После успешного создания акции, зайдите как сотрудник в RC Кассу и нажмите на иконку всплывающего " +
        "меню в правом верхнем углу смартфона. Нажав на кнопку “Акции“, выберите созданную в кабинете акцию и " +
        "проведите стандартную операцию списания или начисления баллов.",
    },
    delete: "Вы уверены, что хотите удалить ",
    editTitle: "Редактор акций",
    deleteTitle: "Удалить акцию",
    activate: "Активировать акцию",
    deactivate: "Деактивировать акцию",
    noPromotions: "У вас еще нет акций",
    createWarning: "*До трёх активированных акций на одну торговую точку",
  },

  tooltips: {
    tradingPoints: {
      name: "Данное название будет отображаться у пользователей в клиентском сервисе.",
      tags: "Будут использоваться для поиска Вашего заведения в клиентском сервисе. Правила написания: начало с букв русского/латинского алфавита, цифр,  до 50 символов, возможны с нижним подчеркиванием. Максимальное количество тегов - 20 шт.",
      description: "Будет отображаться у пользователей в клиентском сервисе.",
      businessType:
        "Служит для фильтрации и поиска Вашего заведения в клиентском сервисе.",
      isBonusPayable:
        "Укажите процент скидки, который будет предоставляться пользователям экосистемы RC Group, при покупке любого товара.",
      cashbackPercent: "Укажите общий процент вознаграждения. ",
      addressComment:
        "Вы можете указать уточнения к адресу, а именно: как пройти, предварительно позвонить и другую сопутствующую информацию.",
      links:
        'В "Название ссылки" укажите как бы Вы хотели, чтоб отображалась ссылка в клиентском сервисе. Например, https://vk.com/id123456  -  petrivanov.',
      logo: "Изображение с фирменным знаком, которое будет использоваться в качестве логотипа вашей торговой точки.",
      filling:
        "Должны быть в максимально хорошем качестве, не содержать надписи, коллажи и водяные знаки",
      banner:
        "Обложка вашего заведения в каталоге торговых точек клиентского сервиса. Не должна быть логотипом, коллажом, содержать надписи и водяные знаки",
      activeWait:
        "Количество дней, через которые будет начислено вознаграждение",
    },
  },

  globalMessagesButton: {
    newTradingPointPage: "Перейти в новый подраздел “Торговые точки”",
    "clients-update": "Попробовать",
    "product-release": "Попробовать",
    "review-update-release": "Попробовать",
  },

  declinationHelperWords: {
    years: [" лет", " год", " года"],
    days: [" дней", " день", " дня"],
    grades: [" оценок", " оценка", " оценки"],
    symbols: [" символов", " символа", " символов"],
    result: [" рузальтатов", " результат", " результата"],
    review: [" отзывов", " отзыв", " отзыва"],
    star: [" звезд", " звезда", " звезды"],
    clients: [" клиентов", " клиент", " клиента"],
    product: [" товаров", " товар", " товара"],
  },

  noData: {
    title: "Данных нет",
    emptyHistory: "Ваша история пуста",
  },

  mobileFiltersName: {
    "trading-points": "Торговые точки",
    category: "Категория",
    "product-status": "Статус товара",
    "review-status": "Тип отзыва",
    sort: "Сортировка",
    date: "Период",
  },

  debt: {
    title: "Задолженность",
    text:
      "Оплатите ее с помощью счета, отправленного на электронную почту, или создайте его самостоятельно\n" +
      "в разделе “Баланс”",
    link: "Перейти к погашению",
    message:
      "Вам необходимо оплатить задолженность в размере {debt}&nbsp;{currency} <br>" +
      "Оплату можно произвести одним из способов: создать счет в личном кабинете или скачать счет, отправленный на электронную почту.",
  },

  paymentMethod: {
    title: "Способы оплаты",
    hint: "Быстрое и простое увеличение вариантов оплаты в вашей торговой точке с помощью инновационного платежного решения для бизнеса RC POS",
    nodataTitle: "Данных по способам оплаты нет",
    nodataDescription: "У вас пока нет торговых точек ",
    noModerationDataDescription:
      "У вас пока нет торговых точек, прошедших модерацию",
    haveAccountTitle: "Ваша почта уже зарегистрирована в RC POS",
    haveAccountText:
      "Если у вас ранее не были подключены способы оплаты, перейдите в свой кабинет RC POS",

    create: {
      firstStep: {
        asideTitle: "Несколько шагов и инновационное решение у вас в кармане!",
        asideText:
          "<p>Эта разработка позволяет не использовать дополнительное оборудование — нет необходимости приобретать кассу, терминал \n" +
          "и фискальный накопитель, а чековую ленту успешно заменили электронные чеки. </p>" +
          "<p>Подключение дополнительных способов оплаты\n" +
          "доступно на одну торговую точку.</p>" +
          "<p>Требования законодательства и партнерских договоров не позволяют нам обслуживать некоторые категории бизнеса:</p>",

        asideText_open:
          "<ul>" +
          "<li>— продающие алкоголь любого вида;</li>" +
          "<li>— продающие табачные изделия, наркосодержащие препараты, курительные смеси и их аналоги, включая электронные сигареты и их наполнители;</li>" +
          "<li>— продающие лекарственные средства, включая БАДы;</li>" +
          "<li>— продающие товары и контент сексуальной направленности («товары для взрослых», секс-шопы и т.п.);</li>" +
          "<li>— продающие б/у запчасти для автомобилей;</li>" +
          "<li>— продающие огнестрельное и холодное оружие, запасные части и боеприпасы к нему, а также любые товары и услуги военного назначения;</li>" +
          "<li>— организующие куплю-продажу валюты, включая криптовалюты, ценных бумаг, финансовых инструментов и их аналогов;</li>" +
          "<li>— связанные с азартными играми;</li>" +
          "<li>— букмекерские конторы;</li>" +
          "<li>— продающие товары или оказывающие услуги, нарушающие действующее законодательство или способные ввести в заблуждение или обмануть покупателя.</li>" +
          "</ul>" +
          '<p>С полным <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/ограничения.pdf">списком ограничений</a> можно ознакомиться в документе ограничений </p>',
        asideSubText:
          "На этом этапе происходит создание кабинета в RS POS. <br> Логин и пароль для входа в RC POS будут отправлены на указанную при регистрации почту.",
        //статичные данные
        title: "Юридическое лицо",
        tradingPoint: "Торговая точка",
        inn: "ИНН:",
        bic: "Бик банка:",
        headName: "Ф.И.О. руководителя:",
        bankName: "Название банка:",
        organizationType: "Тип организации:",
        currentAccountNumber: "Номер расчетного счета",
        address: "Юридический адрес:",
        correspondentAccount: "Корсчет:",
        // изменяемые даннык
        entityType: "Форма юрлица",
        taxType: "Тип налогообложения",
        monthlyTurnover: "Размер ежемесячного оборота",
        ogrn: "ОГРН",
        okved: "Основной вид деятельности",
        phone: "Телефон",
        oferta:
          'Я принимаю условия <a href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">договора</a> сервиса RC POS',
      },
      secondStep: {
        title1: "Выберите подключаемые способы оплаты",
        online: "Онлайн",
        sbp: "СБП",
        // блок 2
        title2: "Личные данные",
        passportSeries: "Серия паспорта",
        passportNumber: "Номер паспорта",
        issuedPlace: "Кем выдан",
        dateOfIssue: "Дата выдачи",
        departmentCode: "Код подразделения",
        dateOfBirth: "Дата рождения",
        placeOfBirth: "Место рождения",
        // блок 3
        title3: "Данные о компании",
        headPosition: "Должность руководителя",
        VATPayer: "Плательщик НДС",
        procents: "Процентная ставка",
        procentsHint:
          "Будет внесена автоматически после прохождения проверки данных",
        preferredCommunicationChanel: "Канал для связи",
        factAddress: "Фактический адрес",
        processSellingGoodsAndServices: "Процесс реализации товаров/услуг",
        processSellingGoodsAndServicesHint:
          "Как это происходит и где, если у вас нет торговой точки/офиса",
        descriptionOfActivity: "Описание деятельности",
        descriptionOfActivityHint: "За что будет приниматься оплата",
        note: "Примечание",
      },

      thirdStep: {
        title: "Подписание документа",
      },
    },

    card: {
      tabs: {
        entities: "Юрлицо",
        "payment-methods": "Способы оплаты",
        documents: "Документы",
      },
    },
  },

  registration: {
    loyalty:
      'Мен RC Group серіктестерінің <a target="_blank" href="{link}">адалдық бағдарламасының</a> шарттарын қабылдаймын',
    posOferta:
      'Мен RC POS сервисінің <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">оферта</a> шартының талаптарын қабылдаймын',
    1: "Негізгі ақпарат",
    2: "Дербес ақпарат",
    3: "Заңды тұлға",
    haveAcc: "Аккаунт бар ма?",
    login: "Кіру",

    mainInfo: {
      title: "Негізгі ақпарат",
      promocode: "Промокод",
      country: "Ел",
      phone: "Телефон нөмірі",
      checkbox:
        "Құпиялылық саясатына сәйкес менің дербес деректерімді өңдеуге <a download href='{link}'>келісімімді беремін</a>",
    },

    profileInfo: {
      title: "Дербес ақпарат",
      firstName: "Аты",
      lastName: "Тегі",
      middleName: "Әкесінің аты",
      email: "Пошта",
      password: "Құпия сөз",
      confPassword: "Құпия сөзді қайталаңыз",
      registerCode: "Поштадан код",
      notSendCode: "Код келмеді ме?",
      sendAgain: "Қайтадан жіберу",
    },

    emailCode: {
      title: "Растау коды сіздің поштаңызға жіберілді",
      subtitle: 'Егер Сіз "{mail}" хат алмасаңыз, «Спам» папкасын тексеріңіз',
      emailCode: "Поштадан код",
      sendAgainTime: "Хатты арқылы жіберу",
      dontSend: "Хат келмеді ме?",
      sendAgain: "Қайтадан жіберу",
      haveInn: "Бұл СТСН-мен заңды тұлға жоқ",
      addressWithoutNumber:
        "Необходимо указать номер здания/сооружения, помещения",
      login: "Введите логин",
    },

    entity: {
      title: "Заңды тұлға",
      subTitle:
        "Өтінеміз, “Бұдан әрі” батырмасын басар алдында енгізілген деректерді тексеріңіз",
      entityType: "Заңды тұлғаның түрі",
      halalCertificate: "Заңды тұлғаның Халал сертификаты бар",
      fileType: "PNG, JPG, PDF - 1 файл 10 Мб-ға дейін",
      commercial: "Коммерциялық",
    },

    payment: {
      title: "Тіркелгеніңіз үшін алғыс білдіреміз!",
      subtitle:
        "Енді сіз RC Group. адалдық бағдарламасының жеке кабинетіне кіре аласыз, модерациядан өтеді. Әдеттегідей, тексеру 24 сағаттан 48 сағатқа дейін уақыт алады",
      examination:
        "Тексеруден өту үшін сізге көрсетілген заңды тұлғаны оны төмендегі батырма бойынша жүктеп шотты төлеу қажет. Сіз сондай-ақ жеке кабинеттен: «Менің кәсіпорындарым» - «Құжаттар» тарауындағы «Тестілік төлем» атауымен шотты таба аласыз.",
      pos: "RC POS сервисіне қолжетімділікті тексеру аяқталғаннан кейін аласыз. Кіру үшін деректер тіркеу кезінде көрсетілген поштаға жіберілетін болады.",
      toCabinet: "Кабинетке өту",
    },
  },

  permissions: {
    analytics: "Аналитика",
    "legal-entity": "Юрлица",
    "trading-point": "Торговые точки",
    employee: "Кассиры",
    document: "Документы",
    tariff: "Тарифы",
    operation: "История операций",
    "act-and-receipt": "Акты и счета",
    manager: "Менеджеры",
    cashback: "Начисление баллов",
    balance: "Баланс",
    points: "Очки кассирам",
    // 'claim': 'claim',
    client: "Список клиентов",
    spread: "Распространение  карт Wallet",
    "wallet-pass": "Дизайн карты Wallet",
    review: "Отзывы",
    // 'application': 'application',
    "noncash-tips": "Безналичные чаевые",
    "check-counterparties": "Проверка контрагентов",
    "push-notifications": "Push-уведомления",
    // 'group': 'Начисление баллов (группы клиентов)',
    "client-portrait": "Портрет клиента",
    "active-wait": "Начисление баллов (Активное ожидание)",
    "e-menu": "Электронное меню",
    faq: "FAQ",
    terminal: "Processing",
    promotion: "Акции",
    cashback_group: "Начисление баллов (Группы клиентов)",
    payment_info: "Способы оплаты",
    orders: "Заказы",
    "trading-point-promotion": "Продвижение",
    "gift-card": "Подарочные карты",
  },

  promotionServices: {
    servicesList: {
      "xxl-tag": {
        name: "XXL-размер карточки",
        description:
          "Увеличение размера карточки вашего заведения и размещение на ней бейджа «Топ место»",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет увеличена в размере, а также выделена бейджем «Топ место» на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 1 неделя, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      tag: {
        name: "Бейдж «Топ место»",
        description:
          "Размещение на карточке вашего заведения бейджа «Топ место» и перемещение её выше в общем списке",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет выделена бейджем «Топ место» и перемещена выше в списке на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения –  1 неделя, 2 недели, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      top: {
        name: "Топ 1 или 2 место",
        description:
          "Размещение карточки вашего заведения на 1 или 2 местах в списке (без изменения её внешнего вида и размера)",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет размещена на 1 или 2 месте на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Размещение на 1 или 2 месте определяется очередностью оформления заказа и оплаты данной услуги.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 2 недели с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      hint: "В настоящий момент все доступные слоты по данной услуге в городе вашей торговой точки заняты. Заказ будет возможен с указанной даты.",
    },
    periodsList: {
      "7 days": "1 неделя",
      "14 days": "2 недели",
      "30 days": "1 месяц (30 дн)",
      "90 days": "3 месяца (90 дн)",
    },
  },

  //   pre-orders page
  preOrders: {
    title: "Заказы",
    notAvailablePreOrders: "Заказы пока недоступны",
    noOrders: "Заказов пока нет",
    noTradingPoints:
      "<p>У вас пока нет торговых точек, которые прошли модерацию и размещены на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY</p>",
    noProducts:
      "Чтобы принимать заказы необходимо добавить товары или услуги в электронное меню",
    noOrdersText:
      "<p>Чтобы ваши товары или услуги были доступны для заказа на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY, необходимо добавить их в электронное меню и включить прием предзаказов в настройках</p>",
  },
};
