import yandex_program_1 from "@/assets/images/yandex_program_1.png";
import yandex_program_2 from "@/assets/images/yandex_program_2.png";
import yandex_program_3 from "@/assets/images/yandex_program_3.png";
import yandex_program_4 from "@/assets/images/yandex_program_4.png";
import yandex_program_5 from "@/assets/images/yandex_program_5.png";

import landing from "./landing";
import { balanceRu } from "@/modules/balance/i18n/ru";

export default {
  balance: {
    ...balanceRu,
  },

  //SYSTEM
  sys: {
    error: "Ошибка: ",
    descError: "Описание: ",
    wrongLogin: "Неверный логин",
    wrongPass: "Неверный пароль",
    unknown: "Не известно",
    unauthenticated: "Не авторизован",
    iikAlready: "Юридическое лицо с этим ИИК уже существует",
    noMoney: "Недостаточно средств на балансе",
    wrongPeriod: "ProPlus только на год",
    noPermissions: "У пользователя нет необходимых разрешений.",
    firstLoginManager: "Впервые выполнен вход, придумайте пароль",
    limitShops: "Создание торговой точки ограничено тарифом",
    limitLegalEntity: "Создание юридического лица ограничено тарифом",
    wrongTariff: "На вашем тарифе это недоступно",
    wrongTariffPromo: "Промокод не подходит для регистрации",
    tagExist: "Этот тег уже существует",
    503: "Сервер недоступен",
    blockManager: "Менеджер заблокирован",
    wrongCode: "Неверный код",
    codeExists: "Промокод партнера введен не верно",
    noExam: "Экзамен не сдан",
    noActivateAccount: "Аккаунт не активирован",
    emailExist:
      "Владелец с данной почтой уже зарегистрирован в сервисах RC GROUP",
    userExist:
      "Владелец с данной почтой уже зарегистрирован в сервисах RC GROUP",
    noAccepted: "Новое соглашение не принято",
    balanceNotEnough: "Недостаточно средств на балансе",
    innExist: "Юридическое лицо с этим ИНН уже существует",
    noTodayChangeTariff:
      "Изменить тариф можно только за день до окончания текущего тарифа",
    badToken: "Данная ссылка не действительна",
    wrongEmail: "Неправильная почта",
    emailTaken: "Электронный адрес уже занят",
    notAvailableCurrentTariff: "Недоступно текущему тарифу",
    dataNoCorrect: "Данные неверны",
    accountNotFound: "Аккаунт не найден",
    TPExist: "Торговая точка уже существует",
    serverError: "Ошибка сервера",
    ownerExist: "Аккаунт не найден",
    unauthorizedAction: "У вас недостаточно прав",
    legalEntityExist: "Юридическое лицо не найдено",
    tariffChangeOneDay:
      "Тариф можно изменить за сутки до окончания текущего тарифа",
    blockedByTariff: "Заблокирована по тарифу",
    documentInProcessOfCreation: "Документ в процессе формирования",
    emailAlreadyVerified: "Ваша почта уже подтверждена!",
    emailConfirmed: "Ваша почта успешно подтверждена!",
    codeDoesNotExist: "Введенный код недействителен",
    defaultCashbackCanNotBeDeleted:
      "Вознаграждение для клиентов без брендированной карты не может быть удалено",
    cashbackUpdateSuccessful: "Процент вознаграждения был успешно обновлен",
    cashbackDeleteSuccessful: "Вознаграждение удалено",
    cashbackCreateSuccessful: "Вознаграждение создано",
    fullCashback: "Клиент без брендированной карты",
    clientGroupAlreadyInUse:
      "Группа клиентов уже используется в группе кешбека",
    enteredAmountIsAlreadyInUse:
      "Введенная сумма вознаграждения уже выставлена",
    dateStartIsInvalid: "Дата начала периода введена некорректно",
    dateEndIsInvalid: "Дата конца периода введена некорректно",
    organizationNotConfirmed: "Юр.лицо не подтверждено",
    periodNotAvailable: "Выбранный период недоступен для покупки",
    notAvailableInYourCountry: "Выбранный период недоступен для покупки",
    exceededActivatedPromotionLimit:
      "Только 3 активированных акции на одну торговую точку",
    tradingPointLimitOnProPlus:
      "На тарифе ProPlus возможно создание не более 30 торговых точек",
    tradingPointLimitOnPromo:
      "На тарифе ProPlus возможно создание не более 15 торговых точек",
    tradingPointLimitOnLight:
      "На тарифе ProPlus возможно создание не более 5 торговых точек",
    wrongDateOrder:
      "Дата начала периода должна быть раньше, чем дата конца периода",
    descriptionFormatInvalid: "Описание не должно содержать хештегов и emoji",
    descriptionMustNotContainEmoji: "Описание не должно содержать emoji",
    descriptionMinLength:
      "Описание должно быть длинее 600 символов без учета пробелов",
    descriptionMaxLength: "Описание не должно быть длинее 3000 символов",
    maxFileSizeError: "Размер файла превышает лимит в 10 Мбайт",
    addFile:
      "Перетащите или <span style='color: #447ECB; cursor: pointer'>загрузите файлы</span>",
    balance: "Баланс",
    changeMainOrganizationError:
      "Изменить главную организацию возможно только через 30 дней после предыдущего изменения",
    resetWallet: "Не удалось сбросить дизайн wallet pass",
    updateWallet: "Не удалось обновить дизайн wallet pass",
    userUnauthorized: "Логин или пароль введены неверно",
    noData: "Нет данных",
    kb: "КБ",
    dateMask: "дд.мм.гггг",
    sort: "Сортировка",
    noDataTitle: "Данных пока нет",
    syncError: "Проблема с синхронизацией. Попробуйте еще раз позже.",

    enterName: "Введите название",
    countries: {
      ru: "Россия",
      kz: "Казахстан",
      md: "Молдова",
    },
    select_country: "Выберите страну",
    country: "Страна",
    q_date: {
      days: "Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота",
      daysShort: "Вс_Пн_Вт_Ср_Чт_Пт_Сб",
      months:
        "Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь",
      monthsShort: "Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек",
    },
    errorTitle: "Что-то пошло не так",
    dateFrom: "Данные доступны с ",
    dateToToday: " по сегодняшний день",
    dateFormat: "Формат дд.мм.гггг или дд.мм.гггг - дд.мм.гггг",
    showBy: "Показывать по: ",
    showByMobile: "По: ",
    searchResponseEmpty: "По вашему запросу ничего не найдено",
    search: "Поиск",
    imageSize: "Мб",
    tagSearchResponseEmpty:
      "Тег не найден. Для его добавления нажмите Enter или Пробел.",
    from: "От:",
    to: "До:",
    gender: {
      m: "Мужской",
      f: "Женский",
    },
    500: "Что-то пошло не так. Что-то пошло не так. Проверьте интернет-соединение.",
    soon: "Скоро!",
  },
  entity: {
    title: "Добавьте данные организации",
    thirdStepTitle:
      "Пожалуйста, проверьте правильность данных. Теперь вам необходимо подождать от 24 до 48 часов, что бы мы подтвердили ваше юридическое лицо.",
    accept: "Я принимаю условия договора",
    offers: "оферты",
    titleReject: "Заявка отклонена!",
    rejectDescription:
      "К сожалению, предоставленные вами данные оказались некорректными. Внимательно ознакомьтесь с ответом от модераторов и внесите изменения в информацию о юридическом лице.",
    message: "Сообщение от модераторов:",
    change: "Изменить данные",
    // titleWaiting: "Спасибо за ожидание", - изменено от (NK-589)
    titleWaiting: "Спасибо за регистрацию!",
    descriptionWaiting:
      "В данный момент ваша организация проходит модерацию. Вы сможете попасть в личный кабинет после того, как мы удостоверимся в корректности ваших данных. Как правило, проверка занимает от 24 до 48 часов.",
    descriptionWaitingRu:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p>  <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    descriptionWaitingRuWithoutPos:
      "<p>В данный момент ваша организация проходит модерацию. </p> <p>После того, как мы удостоверимся в корректности ваших данных \n" +
      "вы сможете войти в кабинет партнёра RC Group. </p> <p> Как правило, проверка занимает от 24 до 48 часов.</p>",
    // downloadAccept: "Скачать счет по акцептному платежу", - изменено от (NK-589)
    downloadAccept: "Скачать счет на оплату",
    addDone: "Поздравляем с созданием юрлица!",
    waitingApprove:
      "<p>В данный момент ваше юрлицо проходит проверку. Она занимает от 24 до 48 часов.</p><p>Для прохождения проверки вам необходимо оплатить счет с указанного юрлица, скачав его по кнопке ниже. Вы также можете найти счет в личном кабинете: в разделе «Мои  предприятия» - «Документы» под названием «Тестовый платеж».</p>",
  },

  landing,

  contacts: {
    title: "Контакты",
    subtitle:
      'Общество с ограниченной ответственностью "РК-ТЕХНОЛОГИИ" ( ООО "РК-ТЕХНОЛОГИИ" ) Юридический адрес: 191124, <br/>' +
      "Санкт-Петербург, вн.тер.г. муниципальный округ Смольнинское, ул. Красного Текстильщика, д.10-12, литера О, помещ. 1-Н <br/> ИНН/КПП 7806578914/780601001 ОГРН 1207800157256",
    mailAddress: "Адрес электронной почты: ",
  },

  failed: "Ошибка. Действие не выполнено",
  success: "Действие выполнено",
  navbar: {
    page1: "Главная",
    page2: "Регистрация",
    page3: "Авторизация",
    page4: "ЛК",
  },
  registerCode: {
    ttl: "Введите промокод партнера",
    promocode: "Промокод",
    invalidLength: "Длина промокода не верная",
    hasAccount: "Есть аккаунт?",
  },
  newPassword: {
    passwordChange: "Смена пароля",
    enterNewPassword:
      "Введите новый пароль, который будете использовать для входа в личный кабинет",
  },
  registerEmail: {
    ttl: "Введите email",
    hasEmail1: "Такая почта уже есть. Хотите",
    hasEmail2: "авторизоваться",
    enterEmailCode: "Введите код, присланный вам на email",
    noEmailCode: "Не пришел код?",
    sendCodeAgain: "Повторно отправить код можно через",
    spamTitle: "Если вы не получили письмо, проверьте папку «Спам»",
  },
  resetPage: {
    title: "Восстановление пароля",
    titleOk: "Пароль успешно изменен!",
    backToAuth: "Вернуться к авторизации",
    messageToMail: "На указанный адрес придет ссылка!",
    enterEmail: "Введите новую почту",
    descriptionNewEmail:
      "В целях безопасности, почта RC CITY должна быть уникальна. Пожалуйста, введите новую почту.",
    oldEmail: "Старая почта",
    newEmail: "Новая  почта",
    descriptionPassword: "Пароль не будет изменен",
    notice: "Не переживайте, мы вышлем вам ссылку восстановления на почту",
    loginInformation: "Информация для входа отправлена на вашу почту",
    resetPasswordLink: "Мы отправили ссылку для изменения пароля на почту",
    messageNotReceived: "Не пришло письмо?",
    resendMail: "Отправить письмо повторно через 00:%{variable}",
  },
  //cabinet layout
  leftPanel: {
    counterparties: "Проверка контрагентов",
    entities: "Юрлица",
    tradingPoint: "Торговые точки",
    paymentMethods: "Способы оплаты",
    employees: "Кассиры",
    documents: "Документы",
    businesses: "Бизнес",
    tariffs: "Тарифы",
    historyPayment: "История операций",
    promotions: "Акции",
    actsAndAccounts: "Акты и счета",
    accrualCashback: "Начисление баллов",
    managers: "Менеджеры",
    spread: "Распространение карт RC",
    analytics: "Аналитика",
    points: "Очки кассирам",
    enterprises: "Мои предприятия",
    paymentDecision: "Платежное решение",
    portfolio: "Портфель",
    employees_and_managers: "Мои сотрудники",
    loyalty: "Программа лояльности",
    clients: "Клиенты",
    claims: "Заявки",
    claims_history: "История заявок",
    claims_groups: "Группировка клиентов",
    reviews: "Отзывы",
    extendTariff: "Продлите действие тарифа",
    changeTo: "тариф изменится на",
    wallet: "Дизайн карты Wallet",
    accountIs: "Вы вошли как",
    manager: "менеджер",
    owner: "владелец",
    terminals: "Кассовые аппараты",
    terminals_MSPOS: "RC Processing 4 в 1",
    terminals_LIMON: "Технология MPOS",
    FAQ: "Подробно об RC CITY",
    corporate: "Связь",
    push: "Push-уведомления",
    tips: "Безналичные чаевые",
    eMenu: "Электронное меню",
    giftCard: "Подарочные карты",
    processing: "Processing",
    services: "Сервисы",
    wallets: "Карты лояльности",
    clientsList: "Список клиентов",
    marketplaces: "Маркетплейсы",
    PromotionServices: "Продвижение",
    PreOrders: "Заказы",
    PWA: "Установить приложение",
  },
  headerButtons: {
    addCoin: "Пополнить",
    profile: "Профиль",
    exit: "Выйти",
    themLight: "Светлая тема",
    themDark: "Темная тема",
  },
  //organization page
  organization: {
    title: "Мои организации",
    tooltip:
      "Главное Юр лицо используется для выставления счета при пополнении баланса. Изменять главное Юр лицо можно не чаще чем раз в 2 недели",
    name: "Название юрлица",
    iik: "ИИК",
    inn: "ИНН",
    bin: "БИН",
    bic: "Бик банка",
    codeFiscal: "Cod Fiscal",
    status: "Статус",
    answear: "Ответ модерации",
    button: "Подробнее",
    bill: "История",
    deposit_sum: "Сумма депозита",
    head_name_description:
      "Введите Ф.И.О. ответственного лица, на которое будут автоматически создаваться акты и счета",
    mainOrganization: "Основное юрлицо",
    mainOrganizationHint:
      "Основное юрлицо используется для покупки тарифа и остается активным при его деактивации. Изменить основное юрлицо можно не чаще чем раз в 14 дней",
    chooseMainOrganization: "Выберите Юр лицо",
    maxOrganizationError: "Создано максимальное количество организаций",
    moderOrganizationError:
      "Вы не можете добавить новую организацию, пока проходит процесс модерации",
    // about organization
    aboutTitle: "Информация о вашей организации",
    typeOrganization: "Тип организации",
    bankName: "Название банка",
    legalAddress: "Юридический адрес",
    currentAccountNumber: "Номер расчетного счета",
    correspondentAccount: "Корсчет",
    buttonChangeOrganization: "Изменить данные организации",
    buttonChangeRequisites: "Изменить данные",
    dataModeration: "Данные на модерации",
    organizationNotExist: "Такой организации не существует",
    vatCode: "Код НДС",
    internationalBank: "Международный номер банковского счета",
    prompt: "Минимальный остаток на счете",
    deposit_min: "Минимальное значение ",
    addressFieldRequired: "Не введен адрес юр.лица",
    metaIsRequired: "Не введены банковские данные",
    //add organization
    add: {
      title: "Добавьте данные организации",
      enterData: "Введите данные вашей организации",
      commercial: "Коммерческая организация",
      halal: "Халяльная организация",
      button: "Создать организацию",
    },
    // popup Aliexpress
    link: "Ссылка на сайт (не обязательно)",
    addAliexpress: "Добавить товары на AliExpress",
    entities: "Юрлица",

    modalTitle: "Изменение основного юрлица",
    modalText:
      "Основное юрлицо используется для выставление счета\n" +
      "        при пополнении баланса. Ваше основное юрлицо “{old}”,\n" +
      "        будет изменено на “{new}”",
    modalSubtext: "Изменить главное Юрлицо можно не чаще чем раз в 14 дней.",
    head_name: "Ф.И.О. руководителя",
    ogrn: "ОГРН",
    legalTypes: "Форма юрлица",
    taxSystem: "Тип налогообложения",
    monthlyTurnover: "Размер ежемесячного оборота",
    depositSum: "Сумма депозита",
    okvedLabel: "Основной вид деятельности",
  },
  //Trading Points page
  shop: {
    title: "Торговые точки",
    name: "Название торговой точки",
    organization: "Организация",
    status: "Статус",
    answear: "Ответ модерации",
    button: "Подробнее",
    //about Trading point
    sale: "Скидка для клиентов RC",
    acceptPoints: "Эта точка принимает баллы RC",
    noAcceptPoints: "Эта точка не принимает баллы RC",
    phoneAdmin: "Телефон администратора",
    mailAdmin: "Почта администратора",
    tradingPoint: "Торговая точка",
    address: "Адрес торговой точки",
    addressComment: "Комментарий адреса торговой точки",
    workTime: "Время работы",
    buttonChangeShop: "Изменить данные торговой точки",
    closeShop: "Закрыть торговую точку",
    checkboxFeedback: "Можно использовать для записи и обратной связи",
    checkboxPublish: "Публиковаться на сайте",
    changePoint: "Изменение торговой точки",
    maxShops:
      "Вы создали максимальное количество торговых точек для Вашего тарифа",
    noShops: "У вас еще нет торговых точек",
    addShopButton: "Добавить торговую точку",
    confirmationCloseShop: "Вы уверены что хотите удалить торговую точку?",
    deleteButton: "Удалить ТТ",
    //add trading point
    add: {
      title: "Создание торговой точки",
      about: "О заведении",
      emenu: "Товары и услуги",
      reviews: "Отзывы",
      description: "Описание",
      checkboxInternational: "Самостоятельно заполнить международную версию",
      descriptionInternational:
        "Если вы не заполните международную версию, она будет переведена автоматически",
      name: "Введите название, по которому можно найти торговую точку",
      hashtag: "Теги для торговой точки",
      searchTags: "Искать по тегам",
      internationalName:
        "Введите международное название (только на английском языке)",
      sale: "Введите скидку для участников программы RC",
      additionalOptions: "Дополнительные опции",
      address: "Адрес",
      addressHint:
        "Если на карте нет адреса вашей торговой точки, выберите ближайший и обратитесь в техническую поддержку для установки корректного адреса",
      internationalAddress:
        "Введите международный адрес и комментарий к торговой точке (только на английском языке)",
      phone: "Номер телефона",
      phoneInternational:
        "Введите международный рабочий телефон и почту администратора",
      image: "Добавьте фотографии, которые будут отображаться у пользователей",
      workTime: "Укажите время работы",
      button: "Добавить торговую точку",
      options: {
        baby_chair: "Детское кресло",
        children_room: "Детский уголок/комната",
        parking: "Парковка",
        wifi: "WiFi",
        animals: "Животные",
        round_the_clock: "Круглосуточно",
        over_sixteen: "16+",
        yandex_program: "Программа Яндекс",
        no_smoking: "Курение запрещено",
      },
      yandexTooltip: "Ваше заведение есть в сервисах Яндекс.Еда, Яндекс Лавка",
      errorNoTime: "Вы не можете создать торговую точку без времени работы",
      addNewTag: "Новый тэг создан",
      mainInfo: "Основная информация",
      subInfo: "Дополнительная информация",
      bonusProgram: "Бонусная программа",
      isBonusPayable: "Скидка участникам программы RC",
      activeWait: "Задержка начислений",
      cashbackPercent: "Начисление вознаграждения",
      addressAndWorkTime: "Адрес и время работы",
      addressAndWorkTimeWithoutSpace: "Адрес и время&nbsp;работы",
      contactInfo: "Контакты",
      siteLink: "Ссылка на сайт",
      addLinks: "Добавить другие социальные сети",
      addAddressAndTime: "Добавить адрес и время работы",
      step: "Шаг",
      of: "из",
      comment: "Комментарий",
      email: "Почта",
      time: "Часы работы",
      weekend: "Только по выходным",
      weekdays: "Только по будням",
      another: "Работает в указанные часы работы",
      contacts: "Контакты",
      links: "Ссылки",
      thatPointUseRC: "Эта точка принимает баллы RC",
      logoAndPhotos: "Логотип и фотографии",
      logo: "Логотип торговой точки",
      filling: "Фотографии торговой точки",
      banner: "Баннер торговой точки",
      logoFormat: "Соотношение сторон для логотипа — 1:1,",
      anotherImagesFormat: "Соотношение сторон для фото — 16:9",
      bannerImagesFormat: "Соотношение сторон для баннера — 2:1",
      imagesFormat: "в формате PNG, JPG и JPEG и весом не более 10 Мб",
      imagesFormatAndAmount:
        "PNG, JPG, HEIF — от {min} до {max} файлов, 10 Мб каждый",
      onlyOnline: "Торговая точка работает онлайн",
      useForRegistration: "Использовать для записи клиентов",
      addMorePhone: "Добавить резервный номер телефона",
      addMoreEmail: "Добавить резервный адрес электронной почты",
      linkTo: "Ссылка на ",
      linkName: "Название ссылки",
      cropLogo: "Обрежьте логотип",
      cropBanner: "Обрежьте баннер",
      cropPhoto: "Обрежьте фото",
      publicTradingPoint: "Опубликовать торговую точку на сайте rcpay.ru",
      maxLengthDescription: "> 600 символов",
      sparePhone: "Резервный номер телефона",
      spareEmail: "Резервная почта",
      //edit
      editTitle: "Просмотр торговой точки",
      noImage:
        "Если Вы не видите изображение для обрезки, значит загружаемый Вами файл не соответствует формату изображений. Пожалуйста, попробуйте загрузить другой файл.",
      noCanvas:
        "Не удалось обрезать изображение. Пожалуйста, попробуйте загрузить другой файл. ",
      newTag: "(Новый тег)",
      saveEditChanges:
        "Информация о Вашей торговой точке обновится после прохождения модерации",
      noCheck: "У Вас еще нет ни одного чека",
      noOperations: "Не было произведено ни одной операции",
      //edit
    },
    // history
    historyOperation: "История чеков",
    search: "Начните вводить покупателя",
    noHistory: "Ваша история пуста",
    employee: "Сотрудник",
    customer: "Покупатель",
    source: "Источник",
    typeOperation: "Тип операции",
    writing: "Списание",
    accrual: "Начисление",
    sumBuy: "Сумма покупки",
    sumWrite: "Сумма списания",
    toPay: "К оплате",
    cashback: "Баллы",
    totalCashback: "Общее вознаграждение",
    salePopup: "Скидка",
    types: {
      balance_up: "Пополнение баланса",
      balance_down: "Списание средств",
      tsp_approve: "Взаиморасчет по чеку",
      balance_refill: "Пополнение баланса",
      tariff_change: "Покупка тарифа",
      cashback_approve: "Клиентское путешествие",
      cashback_charge: "Чек. Списание баллов",
      certificate_charge: "Начисление. Использование подарочной карты",
      certificate_debt: "Списание. Выпуск подарочной карты",
      certificate_refund: "Возврат за выпуск подарочной карты",
      rc_withdraw: "Чек. Прием баллов",
      tsp_commission_fee: "Чек. Комиссия",
      tsp_commission_revert:
        "Доначисление некорректно списанной суммы за период с 1 апреля по дату начисления",
      tsp_commission_correct:
        "Корректировка взаиморасчетов в размере 1% от принятых баллов",
      manual_commission_approve: "Чек. Взаиморасчет по коммиссии",
      manual_withdraw: "Вывод средств",
      cashback_decline: "Отмена чека",
      rc_processing_receipts: "Чек кассового аппарата",
      cashback_approve_decline: "Отмена начисления за клиентское путешествие",
      partner_manual_withdraw: "Списание средств",
      inner_cashback_charge: "Чек. Списание вознаграждения",
      inner_cashback_approve: "Взаиморасчет по чеку",
      tsp_approve_payment: "Чек. Списание средств",
      rc_withdraw_approve: "Чек. Начисление за оплату баллами",
      withdraw_payment: "Чек. Списание средств",
      prodazha_top_mesta: "Покупка топ-места в rcpay",
      reserving_funds_withdrawal: "Зарезервировано к выплате",
      refund_reserved_funds_withdrawal: "Возврат резерва к выплате",
      withdraw_reserved_funds: "Вывод средств",
    },
    organizations: "Юрлицо",
    organizationsSelector: "Юрлица",
    moderationModalTitle: "Сообщение от модерации",
    tableSearch: "Поиск по таблице",
    customersSearch: "Поиск по покупателю",
    delete: "Вы уверены, что хотите удалить торговую точку?",
    nameSearch: "Поиск по названию",
    //edit
    editTitle: "Просмотр торговой точки",
    noImage:
      "Если Вы не видите изображение для обрезки, значит загружаемый Вами файл не соответствует формату изображений. Пожалуйста, попробуйте загрузить другой файл.",
    noCanvas:
      "Не удалось обрезать изображение. Пожалуйста, попробуйте загрузить другой файл. ",
    newTag: "(Новый тег)",
    saveEditChanges:
      "Информация о Вашей торговой точке обновится после прохождения модерации",
    dataTooltip:
      "Для круглосуточно работающего заведения часы работы устанавливаются следующим образом: начало рабочего дня 00:00, конец рабочего дня 00:00.",
    preview: {
      title: "Режим предпросмотра торговой точки",
      points: "Баллы от заведения",
      discount: "Скидка",
      payPoints: "Оплата баллами",
      fromCost: "от стоимости",
      enroll: "Связаться",
      onMap: "На карте",
      aroundTheClock: "Круглосуточно",
      yandex: "Программа Яндекс",
      online: "Онлайн",
      comment: "Комментарий к адресу: ",
      flags: {
        wifi: "WiFi",
        baby_chair: "Детское кресло",
        children_room: "Детская комната",
        animals: "Можно с животными",
        parking: "Парковка",
        no_smoking: "Курение запрещено",
        over_sixteen: "",
      },
      yandexTitle: "Условия Яндекс Еда",
      stepsYandexProgram: [
        {
          img: yandex_program_1,
          text: "Скажите Кассиру и официанту, что платите Ланч Картой",
        },
        {
          img: yandex_program_2,
          text: "Сканируйте QR-код на кассе или на своём столике",
        },
        {
          img: yandex_program_3,
          text: "Выберите способ оплаты “Бизнес-аккаунт” и оплачивайте счёт",
        },
        {
          img: yandex_program_4,
          text: "Готово! Покупки оплачены приятного аппетита!",
        },
        {
          img: yandex_program_5,
          text: "Если лимита бизнес-аккаунта не хватает, возможно оплатить часть заказа личной картой",
        },
      ],
    },
    clients: "клиенту: ",
    other: "комиссия: ",
    total: "Итого: ",
  },

  //Employee page
  employee: {
    title: "Сотрудники",
    personName: "Фамилия и Имя",
    shop: "Торговая точка",
    login: "Логин",
    password: "Пароль",
    last_name: "Фамилия кассира",
    first_name: "Имя кассира",
    createEmployee: "Создать",
    noShops:
      "Для создания сотрудника вам необходима хотя бы одна торговая точка",
    createShop: "Перейти к созданию торговой точки",
    noEmployees: "У вас еще нет сотрудников",
    addEmployee: "Добавить сотрудника",
    deleteEmployee: "Вы уверены что хотите удалить кассира?",
    downloadRCPay: "Скачать RC KASSA",
    //score
    titleScore: "Очки кассира",
    points: "очков",
    manageEmployee: "Кассиры",
  },
  // Managing managers page
  managers: {
    title: "Менеджеры",
    noManagersError: "У вас еще нет менеджеров",
    addManager: "Добавить менеджера",
    managerCreated: "Менеджер создан",
    managerReset: "Пароль менеджера был сброшен!",
    oneTimePass: "Одноразовый пароль",
    messageManagerCreated:
      "Внимание! Запишите данный пароль, при первой авторизации менеджера, ему необходимо будет создать новый пароль.",
    changePass:
      "Внимание! Запишите данный пароль, при первой авторизации менеджера, ему необходимо будет создать новый пароль",
    add: {
      title: "Добавление менеджера",
      second_name: "Фамилия менеджера",
      first_name: "Имя менеджера",
      patronymic: "Отчество менеджера",
      position: "Должность менеджера",
      phone: "Номер телефона менеджера",
      permissions: "Доступ к страницам кабинета",
      button: "Создать менеджера",
    },
    //change
    changeTitle: "Изменение менеджера",
    buttonChange: "Изменить данные менеджера",
    buttonResetPassword: "Сбросить пароль",
    //reset password
    newPassword: "Введите новый пароль",
    newPassRules1: "Должен содержать не менее 8 символов",
    newPassRules2: "Содержать буквы и цифры латинского алфавита",
    //delete manager
    deleteManager: "Вы уверены что хотите удалить менеджера?",
  },
  //Documents page
  documents: {
    title: "Документы",
    descriptionAcceptance: "Тестовый платеж",
    descriptionCertificate: "Сертификат",
    instruction: "Инструкция",
    shortInstruction: "Краткая инструкция",
    instructionDescription: "для использования приложения RC KASSA",
    instructionCabinetDescription: "по работе с личным кабинетом ТСП",
    shortInstructionRCKassa: "по работе с приложением RC KASSA",
    marketingMaterials: "Маркетинговые материалы",
    marketingMaterialsDescription1:
      "Файлы для оформления точек и шаблоны флаеров",
    agreementMerchantOffer: "Договор с ТСП оферта",
    agreementMerchantOfferDescription: "Договор по работе с ТСП",
    confidentialPolitics: "Политика конфиденциальности",
    confidentialPoliticsDescription:
      "Политика конфиденциальности персональных данных интернет-сайта",
    rewardScenario: "Сценарии распределения вознаграждения",
    rewardScenarioDescription:
      "Описание сценариев распределения вознаграждения",
    tspTariffs: "Тарифы для подключения ТСП",
    tspTariffsDescription: "Информация о тарифах",
    instructionCashier:
      "Инструкция по использованию кассового аппарата для кассира",
    commercialOffer: "Коммерческое предложение",
    instructionRCKassa: "по использованию приложения rc.kassa",
    dateOfSigningContract: "Дата подписания договора ",
  },
  //check counterparties page
  counterparties: {
    title: "Проверка контрагентов",
    buttonCheck: "Проверить",
    question:
      "Дата регистрации, ИНН, КПП, ОГРН,  Основной вид деятельности, Статус юрлица,ИФНС, Юридический адрес, Ген. директор, Учредители, Уставный капитал",
    instructionButton: "Инструкция для проверки контрагентов",
    listTitle: "Как использовать Бот для проверки контрагентов?",
    listItemFirst:
      "Введите ИНН или ОГРН организации, которую собираетесь проверять.",
    listItemSecond: "Telegram-Бот покажет вам всю необходимую информацию.",
    listItemThird:
      "Для проверки блокированных счетов нужно нажать на кнопку «Проверить блокировку счетов».",
    listItemFourth:
      "После нажатия на кнопку «Проверить блокировку счетов» Бот покажет все заблокированные счета (при их наличии), ФНС, дату блокировки, № постановления, основание  в соответствии со статьей. Данную информацию можно скачать в PDF-формате для подробного изучения: нажмите кнопку «Скачать файл отчетом».",
    instructionCardTitleFirst: "Зачем проверять контрагентов?",
    instructionCardTitleSecond: "Своевременно узнавайте о фактах.",
    instructionCardTextFirst:
      "<p>Проверка контрагентов необходима для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений — продолжить или прекратить сотрудничество. <br> Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания.</p>",
    instructionCardTextSecond:
      "<p>С помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретной выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.</p>",
  },

  //business page
  business: {
    title: "Бизнес",
    name: "Название бизнеса",
    type: "Тип деятельности",
    status: "Статус",
    answear: "Ответ модерации",
    noBusiness: "У вас еще нет бизнеса",
    addBusiness: "Добавить бизнес",
    createBusiness: "Создать бизнес",
    //about
    logoAndBanner: "Логотип и баннер компании",
    shops: "Торговые точки",
    socials: "Ссылки на соц.сети",
    description: "Описание бизнеса",
    changeBusiness: "Изменить данные бизнеса",
    fileSizeLogo: "Соотношение сторон логотипа 1 к 1",
    fileSizeBanner: "Соотношение сторон баннера 2 к 1",
    website: "Вебсайт",
    inst: "Instagram",
    vk: "VK",
    fb: "Facebook",
    //add
    add: {
      title: "Создание бизнеса",
      name: "Введите название бизнеса",
      nameInternational:
        "Введите международное название бизнеса (только на английском языке)",
      logo: "Загрузите логотип компании",
      banner: "Загрузите баннер для каталога",
      type: "Укажите тип деятельности",
      typeInternational: "Укажите международный тип деятельности",
      shops: "Привяжите торговые точки",
      socials: "Добавьте ссылки на соц.сети",
      description: "Добавьте описание бизнеса",
      descriptionInternational:
        "Добавьте международное описание бизнеса (только на английском языке)",
      button: "Добавить бизнес",
      activity_type: "Тип деятельности",
      filePrompt:
        "<li>Файл не может быть больше 10МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    },
    //change
    titleChange: "Изменение бизнеса",
    categories: {
      titles: {
        food: "Еда",
        shopping: "Шопинг",
        health_and_beauty: "Красота и здоровье",
        technique: "Техника",
        house: "Дом",
        for_children: "Детям",
        entertainment: "Развлечения",
        sport: "Спорт",
        hobby: "Хобби",
        services: "Услуги",
        other: "Другой",
        "arenda-i-bronirovanie": "Аренда и бронирование",
      },
      activities: {
        cafe: "Кафе",
        restaurants: "Рестораны",
        shops: "Магазины",
        bars_and_clubs: "Бары и клубы",
        fast_food: "Фастфуд",
        coffee_shops: "Кофейни",
        canteens: "Столовые",
        special: "Специальное",
        clothing: "Одежда",
        shoes: "Обувь",
        accessories: "Аксессуары",
        cosmetics: "Косметика",
        care: "Уход",
        spa: "Спа",
        medicine: "Медицина",
        pharmacy: "Аптеки",
        electronics: "Электроника",
        appliances: "Бытовая техника",
        office_equipment: "Оргтехника",
        communication_salons: "Салоны связи",
        household_goods: "Хозяйственные товары",
        repair: "Ремонт",
        furniture: "Мебель",
        garden: "Сад и огород",
        clothes_and_footwear: "Одежда и обувь",
        toys: "Игрушки",
        school: "Школа",
        education: "Обучение",
        parks: "Парки",
        cinema: "Кино",
        spaces: "Пространства",
        tourism: "Туризм",
        special_form: "Спец. форма",
        movement: "Передвижение",
        gyms: "Спортзалы",
        sections: "Секции",
        goods_for_creativity: "Товары для творчества",
        specialty_stores: "Специализированные магазины",
        studios: "Студии",
        renovation_and_construction: "Ремонт и строительство",
        health_and_beauty: "Красота и здоровье",
        computer_help: "Компьютерная помощь",
        photo_and_video: "Фото и видео",
        gifts_holidays: "Подарки, праздники",
        supermarkets: "Супермаркеты",
        leisure: "Досуг",
        auto: "Авто",
        pet_supplies: "Зоотовары",
        renovations_and_interiors: "Ремонт и интерьеры",
        rent_and_hire: "Аренда и прокат",
        books_and_stationery: "Книги и канцтовары",
        jewelry: "Ювелирные изделия",
        trips: "Путешествия",
        gas_station: "АЗС",
        halal: "Халяль",
        flowers: "Цветы",
        children_clothes_and_footwear: "Одежда и обувь",
        sport_clothes_and_footwear: "Одежда и обувь",
        other: "Другое",
      },
    },
    messages: {
      message: "Не верный тип файла!",
      caption: "Файл должен быть jpg или png",
      error_message: "Загрузите логотип и баннер!",
    },
  },
  //Tariffs page
  tariff: {
    title: "Тарифы",
    yourTariff: "Ваш тариф",
    popular: "Популярный",
    lite_p: {
      title: "Лайт",
      list: [
        '<span itemprop="name">Регистрация 1 организации',
        '<span itemprop="name">Создание 5 торговых точек',
        '<span itemprop="name">Упрощенная статистика',
        '<span itemprop="name">Доступ к RC KASSA',
        '<span itemprop="name">Электронный документооборот',
        '<span itemprop="name">Взаиморасчеты 2 раза в месяц',
        '<span itemprop="name">Раздел вопросов и ответов',
      ],
    },

    lite_p_nko: {
      title: "Базовый",
      list: [
        '<span itemprop="name">Первоначальный тариф',
        '<span itemprop="name">Доступен ограниченный функционал кабинета',
      ],
    },

    promo_p: {
      title: "Промо",
      beforeTariff: "Доступно всё из тарифа Лайт...",
      list: [
        '<span itemprop="name">Регистрация 15 организаций',
        '<span itemprop="name">Создание 15 торговых точек',
        '<span itemprop="name">Добавление 150 товаров',
        '<span itemprop="name">Push-рассылка для клиентов',
        '<span itemprop="name">Оцифровка клиентов',
        '<span itemprop="name">Распространение карт RC',
        '<span itemprop="name">Дизайн Wallet-карты',
        '<span itemprop="name">Электронное меню',
        '<span itemprop="name">Функция обратной связи',
        '<span itemprop="name">Приоритетная поддержка',
      ],
    },

    pluspro_p: {
      title: "Про+",
      beforeTariff: "Доступно всё из тарифов Лайт и Промо...",
      list: [
        '<span itemprop="name">Регистрация 30 организаций',
        '<span itemprop="name">Создание 30 торговых точек',
        '<span itemprop="name">Расширенная статистика',
        '<span itemprop="name">Добавление бесконечного числа товаров',
        '<span itemprop="name">Раздел электронного меню и категории',
        '<span itemprop="name">Приоритетная поддержка',
      ],
    },

    corporate: {
      title: "Корпоративный",
      list: [
        '<span itemprop="name">Оцифровка клиентов',
        '<span itemprop="name">Функционал «Пригласить»',
        '<span itemprop="name">Подраздел «Список клиентов»',
        '<span itemprop="name">Дизайн собственной Wallet-карты',
        '<span itemprop="name">Push-рассылка для клиентов',
        '<span itemprop="name">Функция обратной связи',
        '<span itemprop="name">Акция «-50% на продление тарифа»',
        '<p item="name">Монетизация до 30% от начисленного \n' +
          'вознаграждения клиента <span style="color: #CD5959">*</span></p>',
      ],
    },

    faq: [
      {
        title: "Как происходит оцифровка клиента?",
        text:
          "<p>Для оцифровки клиента вам необходимо предоставить ему промокод. Он может быть в виде реферальной ссылки, QR-кода или непосредственно самого кода оцифровки. С его использованием клиенту необходимо пройти регистрацию в клиентском сервисе rcpay.ru.</p>" +
          "<br>" +
          "<p>Обратите внимание: чтобы считать QR-код, клиенту понадобится камера с соответствующей функцией или специальное приложение для считывания QR-кодов.</p>",
      },
      {
        title: "Что значит «активный» клиент?",
        text:
          "<p>Это оцифрованный вами клиент, который совершил покупку в онлайн- или офлайн-заведении экосистемы RC Group. Онлайн-покупки подразумевают шопинг в RC Market, приобретение сертификатов, активацию сервисов Яндекс, покупку пользовательского тарифа. Операции в офлайн — это покупки в заведениях в городе с использованием RC.Kassa.</p>" +
          "<br>" +
          "<p>При покупке тарифа «Корпоративный» каждый партнер участвует в акции  «скидка 50%» на продление тарифа. Основное условие этой акции — оцифровать от 500 «активных» клиентов.</p>",
      },
      {
        title: "Что будет, если не продлить тариф?",
        text: "<p>В таком случае функционал кабинета сократится до уровня тарифа «Базовый». Вам останется доступен подраздел «Список клиентов», но вы лишитесь возможности выпускать карты Wallet, отправлять Push-уведомления и доступа к сервису «Безналичные чаевые».</p>",
      },
      {
        title: "Как привлечь и оцифровать больше клиентов?",
        text: "<p>Используйте функционал «Поделиться» для размещения в соцсетях реферальной ссылки на оцифровку. А также размещайте тейбл-тенты, наклейки-стикеры, листовки, воблеры в вашем заведении.</p>",
      },
      {
        title: "Моего вопроса нет, как получить ответ?",
        text:
          "<p>Задайте вопрос в техподдержку. Для этого нажмите на значок RC Group в правом нижнем углу, выберите «Telegram» и опишите в сообщении свою проблему, по необходимости приложите скрины. С вами обязательно свяжется наш специалист, чтобы решить вопрос в кратчайшие сроки. </p>" +
          "<br>" +
          "<p>Также можно отправить письмо на email: <a href='mailto:hello@rc.company'>hello@rc.company</a> </br>" +
          "<br>" +
          "Или позвонить по телефону: <b>8 (800) 500-57-62.</b></p>",
      },
    ],

    // developmentTariff: "Тариф в разработке",
    // lite: {
    //   ttl1: "Регистрация 1 организации",
    //   descr1: "Создание 5 торговых точек",
    //   product1: "Возможность создать 20 товаров",
    //   ttl2: "Упрощенная статистика",
    //   descr2: "Работа с организациями и торговыми точками",
    //   ttl3: "Доступ к RC KASSA",
    //   descr3: "Счета и акты онлайн",
    //   ttl4: "Промо-материалы",
    //   descr4: "FAQ"
    // },
    // promo: {
    //   ttl1: "Все преимущества тарифа Lite",
    //   descr1: "Регистрация 15 организаций",
    //   ttl2: "Создание 15 торговых точек",
    //   product2: "Возможность создать 150 товаров",
    //   descr2: "Дополнительная статистика",
    //   ttl3: "Портрет клиента",
    //   descr3: "Push-рассылка для клиентов",
    //   ttl4: "Функция обратной связи",
    //   descr4: "Дизайн собственной Wallet-карты"
    // },
    // proplus: {
    //   ttl1: "Все преимущества тарифа Promo",
    //   descr1: "Регистрация 30 организаций",
    //   ttl2: "Создание 30 торговых точек",
    //   product2: "Безлимит на создание товаров",
    //   descr2: "Расширенная статистика",
    //   ttl3: "Список клиентов",
    // },
    // popup: {
    //   chosePeriod: "Выберите период",
    //   description: "Его можно будет продлить в любое время",
    //   organization: "Организация списания",
    //   fullPrice: "Итоговая стоимость",
    // },
  },
  //History of payment page
  history: {
    title: "История операций",
    number: "Номер",
    entityName: "Название организации",
    type: "Тип операции",
    typeBuyTariff: "Покупка тарифа",
    typeAddBalance: "Пополнение баланса",
    typePaymentAct: "Оплата счета по акту",
    typePaymentAcceptance: "Оплата тестового платежа",
    amount: "Сумма",
    currency: "Валюта",
    date: "Дата и время",
    noHistory: "Ваша история пуста",
    operationsTab: "Все операции",
    processingTab: "RC Processing",
    reward: "Вознаграждение",
    paidFromPurchase:
      "{cash} {currency} оплачено <span>(с покупки на {amount} {currency})</span>",
    downloadModal: {
      title: "Скачать файл",
      fileType: "Тип файла",
      sendOnEmail: "Отправить копию на почту",
    },
  },
  //actsAndAccounts page
  actsAndAccounts: {
    title: "Акты и счета",
    number: "Номер",
    name: "Наименование",
    amount: "Сумма",
    date: "Дата и время",
    status: "Статус",
    button: "Скачать",
    accounts: "Счета",
    acts: "Акты",
    actsNew: "Акты (новые)",
    noActs: "У вас еще нет актов",
    buttonDetail: "Детальный просмотр",
    creteAccount: "Сформировать счет",
    accountsTypeText: "Счета на оплату",
    actsTypeText: "Акт проведенных операций за период",
    noAccounts: "Отсутствуют счета",
    arbitraryAct: "Сформировать произвольный акт",
    choseDate: "Выберите дату",
    downloadAct: "Скачать акт",
    viewAct: "Просмотреть акт",
    downloadArbitraryAct: "Скачать произвольный акт",
    noCustomActs: "За выбранный период нет чеков",
    hideTitle: "«Акты и счета» временно находятся на обновлении",
    hideBody:
      "<div>" +
      "<p>Обращаем Ваше внимание, что подраздел <b>«Акты и счета»</b> <br> в разделе <b>«Мои предприятия»</b> временно находится на обновлении.</p>" +
      "<p> Сформировать счет на оплату Вы сможете в разделе <b>«Баланс»</b>. <br> Выберите опцию <b>«Пополнить»</b> и <b>«Ввести сумму»</b>, введите сумму <br> и нажмите <b>«Выставить счет»</b>, после чего — <b>«Скачать счет»</b>. </p>" +
      "<p> Акт сверки можно запросить через тех. поддержку, написав на почту <a href='mailto:{link}'>{link}</a>.<br> Для обращения укажите юр. наименование и период,<br> за который Вам необходим акт сверки. </p>" +
      "<p>Приносим извинения за доставленные неудобства.</p>" +
      "<p><b>С уважением, RC Group.</b></p>" +
      "</div>",
  },
  // Claims and history claims page
  claims: {
    title: "Заявки",
    historytitle: "История заявок",
    group_name: "Название группы",
    group_nomination: "Наименование группы",
    group_sum: "Сумма покупок (без учета скидок)",
    create_group: "Создать новую группу",
    edit_group: "Редактировать группу",
    delete_group: "Вы уверены что хотите удалить группу?",
    sum: "Сумма",
    percent: "Процент начисления",
    noClients: "Пока что у вас не было ни одного клиента",
    oneClient: "У вас был только один клиент",
  },
  // Reviews page
  review: {
    title: "Отзывы в RC PAY",
    pageHint:
      "Данный подраздел предназначен для сбора обратной связи от посетителей ваших заведений, которая будет размещена на сайте rcpay.ru. Вы можете сами управлять какие отзывы отображать, кликнув на значок «глаз»",
    goodFirst: "Сначала положительные",
    badFirst: "Сначала отрицательные",
    newFirst: "По новизне",
    noTradingPointsData:
      "Для просмотра отзывов, необходимо создать торговую точку",
    hint: "Отзыв зависит от фильтра по датам",
    noReviews: "У вас еще нет отзывов",
    all: "Все отзывы",
    shown: "Отображаемые",
    hidden: "Скрытые",
    hideStatus: "Скрыть со страницы вашей торговой точки на сайте rcpay.ru?",
    showStatus: "Показать на странице вашей торговой точки на сайте rcpay.ru?",
    noDataLabel: "Данных по отзывам нет",
    noTradingPointText: "У вас пока нет Торговых точек ",
    noReview: "Пользователи еще не оставляли отзывы о Вашей торговой точке",
  },
  //login manager a login page
  login: {
    title: "Здравствуйте!",
    subTitle: "Пожалуйста, авторизуйтесь.",
    loginInput: "Логин",
    email: "Почта",
    passwordInput: "Пароль",
    repeatPassword: "Повторите пароль",
    approvePasswordInput: "Подтверждение пароля",
    passwordMismatch: "Пароли не совпадают",
    backArrow: "Вернуться на главную",
    forgotPassword: "Забыли пароль?",
    noAccount: "Нет аккаунта?",
    register: "Регистрация",
    backToService: "Отменить и вернуться к сервису",
  },
  //analytic page
  analytics: {
    title: "Аналитика",
    organizationSelect: "Выберите Юр лицо",
    organizationButton: "Юрлица",
    organizationCheckbox: "Все организации",
    newOrganizationCheckbox: "Все Юрлица",
    calendar: "Календарь",
    organizations: {
      newClients: "Новые клиенты",
      allClients: "Все клиенты",
      sales: "Продажи",
      amount: "Сумма продаж",
      amountCashback: "Из них выдано баллами",
      average: "Средний чек",
      averageCashback: "Среднее вознаграждение",
    },
    countClients: "Количество клиентов",
    topSumEmployee: "Топ кассиров по сумме продаж",
    topSaleEmployee: " Топ кассиров по продажам",
    points: "Очки",
    addPoints: "Начис. баллы",
    writePoints: "Принято баллами",
    rfmAnalysis: "RFM Анализ",
    rfmGroupSizes: "Размер RFM групп",
    clientPortrait: {
      btn: "Портрет клиента",
      title: "Клиенты",
      customerCount: "Количество клиентов:",
      receiptAvg: "Средний чек:",
      receiptCount: "Количество продаж:",
      receiptSum: "Сумма продаж:",
      maleFemale: "М/Ж:",
      noGender: "Пол не указан:",
    },
  },
  // Add points page
  points: {
    title: "Начисление очков",
    subTitle:
      "Введите количество очков, которые будут получать кассиры за проведение операций по начислению баллов клиентам",
    pointsForPoints: "За операции существующих клиентов",
    pointsForNewClient: "За операции новых клиентов",
    employeePoints: "Очки кассирам",
  },
  // Cashback page
  cashback: {
    title: "Начисление баллов",
    subTitle:
      "<b>Обратите внимание!</b><br>Клиенты на тарифе <b>FREE</b> получают <b>25%</b>,<br> Клиенты на тарифе <b>SMART</b> <b>50%</b>,<br> Ваши <b>оцифрованные</b> клиенты получают <b>50%</b><br> (комиссия не взымается)<br><br><a href='/public/docs/reward_scenario_1.0.pdf' target='_blank'>См. сценарии распределения вознаграждения</a>",
    statusName: "Клиенты без брендированной карты",
    delay: "Задержка начислений (В днях)",
    setDelay: "Изменить задержку",
    editDelay: "Изменить задержку начислений",
    maxDelay: "Максимальная задержка 14 дней",
    setReward: "Установить вознаграждение",
    selectGroup: "Выберите группу",
    rewardPercent: "Введите процент начисления",
    minReward: "Минимальный процент начисления - 4",
    maxReward: "Максимальный процент начисления - 25",
    delayTitle: "Текущая задержка торговой точки:",
    withoutActiveWait:
      "Ваш пользователь получит вознаграждение в течении 30 минут с момента проведения операции",
  },
  // Spread page
  spread: {
    title: "Распространение карт RC",
    p1: "Отсканировав данный код, ваши клиенты смогут получить карту.",
    p2: "Мы настоятельно рекомендуем разместить тейбл-тент с QR-кодом и надписью «Скачай бонусную карту! Накапливай бонусные баллы и получай особые условия на покупку» рядом с кассой или местом, где ваши покупатели осуществляют расчет. Например, на столиках в кафе, в зоне ожидания рядом с кассой в магазине, или в другом месте таким образом, чтобы ваши клиенты гарантированно увидели эту информацию.",
    getCard: "Получение карты по ссылке",
    ttl1: "Получение карты через RC KASSA",
    p4: "Используйте приложение RC KASSA для отображения вашего QR-кода: для этого перейдите в пункт меню «выдать карту Wallet». Предложите клиенту отсканировать код мобильным телефоном. Несмотря на простоту получения карты таким способом, мы рекомендуем распечатать и разместить QR-код с призывом к действию рядом с кассой.",
    ttl2: "Работа с сотрудниками",
    p5: "Мировой опыт показывает, что сотрудники зачастую бойкотируют внедрение программ лояльности. Это относится не только к устаревшим пластиковым, но и к цифровым решениям, таким как RC Group. Многим сотрудникам может казаться, что, рассказывая клиентам о программе лояльности, они уменьшают выручку организации. Такое утверждение является заблуждением. Чем выше распространение программы лояльности, тем выше средний чек, частота покупок и лояльность к бренду. Более того, высокое распространение программы позволяет уменьшить затраты бизнеса на СМС и другие рассылки и лучше узнавать собственных клиентов.",
    coped: "Скопировано",

    faq: [
      {
        title: "Размещение",
        text: "Мы настоятельно рекомендуем разместить тейбл-тент  с QR-кодом и надписью «Скачай бонусную карту! Накапливай бонусные баллы и получай особые условия на покупку» рядом с кассой или местом, где ваши покупатели осуществляют расчет. Например, на столиках в кафе, в зоне ожидания рядом с кассой в магазине, или в другом месте таким образом, чтобы ваши клиенты гарантировано увидели эту информацию.",
      },
      {
        title: "Получение карты через RC KASSA",
        text: 'Используйте приложение RC KASSA для отображения вашего QR-кода: для этого нажмите на три черных точки в правом верхнем углу и выберите "Открыть QR-код". Предложите клиенту отсканировать код мобильным телефоном.',
      },
      {
        title: "Работа с сотрудниками",
        text: "Мировой опыт показывает, что сотрудники зачастую бойкотируют внедрение программ лояльности. Это относится не только к устаревшим пластиковым, но и к цифровым решениям, таким как RC Group. Многим сотрудникам может казаться, что, рассказывая клиентам о программе лояльности, они уменьшают выручку организации. Такое утверждение является заблуждением. Чем выше распространение программы лояльности, тем выше средний чек, частота покупок и лояльность к бренду. Более того, высокое распространение программы позволяет уменьшить затраты бизнеса на СМС и другие рассылки и лучше узнавать собственных клиентов.",
      },
    ],

    faqNko: [
      {
        title: "Размещение",
        text: "Мы настоятельно рекомендуем разместить тейбл-тент  с QR-кодом и надписью «Скачай бонусную карту! Накапливай бонусные баллы и получай особые условия на покупку» рядом с кассой или местом, где ваши покупатели осуществляют расчет. Например, на столиках в кафе, в зоне ожидания рядом с кассой в магазине, или в другом месте таким образом, чтобы ваши клиенты гарантировано увидели эту информацию.",
      },
      {
        title: "Работа с сотрудниками",
        text: "Мировой опыт показывает, что сотрудники зачастую бойкотируют внедрение программ лояльности. Это относится не только к устаревшим пластиковым, но и к цифровым решениям, таким как RC Group. Многим сотрудникам может казаться, что, рассказывая клиентам о программе лояльности, они уменьшают выручку организации. Такое утверждение является заблуждением. Чем выше распространение программы лояльности, тем выше средний чек, частота покупок и лояльность к бренду. Более того, высокое распространение программы позволяет уменьшить затраты бизнеса на СМС и другие рассылки и лучше узнавать собственных клиентов.",
      },
    ],
  },
  // Wallet page
  wallet: {
    title: "Дизайн карты Wallet",
    viewCard: "Вид Wallet-карты",
    orderCard: "Заказать дизайн карты",
    imageFont: "Вставьте картинку для фона вашей Wallet-карты",
    imageLogo: "Вставьте логотип вашей Wallet-карты",
    choseTitle: "Выберите цвет QR-кода, заголовка и номера карты",
    colorQR: "Цвет QR-кода",
    colorTitle: "Цвет заголовка",
    colorNumberCard: "Цвет номера карты",
    confirmDesign: "Подтвердить дизайн",
    clearDesign: "Вернуть стандартный дизайн",
    titleNumber: "Номер карты",
    claimSend: "Заявка отправлена!",
    expectCall: "Ожидайте звонка нашего менеджера.",
    orderConfirmDesign: "Отправить ваш запрос на создание дизайна?",

    //new
    selectShop: "Выберите торговую точку",
    settingWalletColor: "Настройте цвет карты",
    colorBackground: "- Цвет фона",
    colorText: "- Цвет надписей",
    colorSubstrate: "- Цвет подложки",
    addLogo: "Добавьте логотип",
    withoutLogo: "Нет логотипа",
    haveLogo: "Есть логотип",
    addSubstrate: "Выберите цвет подложки",
    defaultColor: "Залить основным цветом",
    customColor: "Залить собственным цветом",
    customImage: "Установить изображение",
    logoPrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон логотипа 1 к 1</li>",
    substratePrompt:
      "<li>Файл не может быть больше 5МБ</li><li>Соотношение сторон подложки 3 к 1</li>",
    saveCustomDesign: "Сохранить дизайн",
    backDefaultDesign: "Вернуть стандартный дизайн",
    saveModal: "Отправить ваш запрос на создание дизайна?",

    // wallet-card
    walletCard: {
      title: "Вид Wallet карты",
      discount: "Скидка",
      balance: "Баланс",
    },
  },

  // Push notifications page
  notifications: {
    title: "Push-уведомления",
    modal:
      "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    description:
      "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
    notificationText: "Текст уведомления",
    sendError: "Не удалось отправить Push-уведомление",
    sendTime: "По московскому времени",
    noNotifications: "У вас нет созданных push-уведомлений",
    buttonNoData: "Создать PUSH-сообщение",
    table: {
      shopName: "Название торговой точки",
      header: "Заголовок",
      date: "Дата",
      time: "Время",
    },

    view: {
      shop: "Торговая точка",
      header: "Заголовок",
      message: "Текст сообщения",
      date: "Дата",
      time: "Время",
    },

    create: {
      warning:
        "Внимательно прочитайте текст. После создания уведомления, изменить его будет <span>невозможно.</span>",
      shop: "Выбор торговой точки",
      header: "Текст заголовка",
      message: "Текст сообщения",
      button: "Создать рассылку",
      messageAfterCreate:
        "Рассылка находится в обработке, Ваши пользователи получат push-уведомление в течение 15 минут",
    },
  },

  //Clients Page
  clients: {
    title: "Список клиентов",
    allClients: "Все клиенты",
    internalClients: "Внутренние",
    internalClient: "Внутренний",
    externalClients: "Внешние",
    externalClient: "Внешний",
    clientsType: "Тип клиента",

    noTradingPoints: {
      label: "Данных по клиентам нет",
      text: "У вас пока нет Торговых точек",
    },
    noClients: {
      label: "Данных по клиентам нет",
      text: "Оцифровывайте клиентов, чтобы они отображались в вашем списке",
    },

    table: {
      inviteDate: "Дата регистрации",
      fullName: "ФИО",
      phone: "Номер телефона",
      receiptsCount: "Кол-во покупок",
      receiptsSum: "Сумма покупок",
    },

    clientCard: {
      title: "Информация о клиенте",
      client: {
        title: "Клиент",
        fields: {
          fullName: "ФИО",
          gender: "Пол",
          birthDate: "Дата рождения",
          phone: "Номер телефона",
          clientType: "Тип клиента",
          inviteDate: "Дата регистрации",
          rcCard: "Номер карты RC",
          note: "Заметка",
          noteMax: "Макс. 1000 символов",
        },
      },
      statistics: {
        title: "Статистика",
        titles: {
          avgReceiptsSum: "Средний чек",
          receiptsCount: "Кол-во покупок",
          receiptsSum: "Оборот",
          writeOffPointsSum: "Оплата баллами",
        },
        totalNumberOfVisit: "Общее кол-во посещений",
        firstVisit: "Первое посещение",
        lastVisit: "Последнее посещение",
      },
      receipts: {
        title: "Чеки",
        status: {
          approved: "Проведен",
          new: "В ожидании",
          declined: "Отменен",
        },
        noReceipts: "Данных по чекам нет",
      },
      reviews: {
        title: "Отзывы",
        noReviews: "Данных по отзывам нет",
      },
    },
  },

  // Client Portrait Page
  clintPortrait: {
    title: "Портрет клиента",
  },

  // Terminals Page
  terminal: {
    listServiceCenters: "Список сервисных центров",
    mspos: "Инструкция mspos",
    instructions1C: "Инструкция 1С",
    title: "Заказ кассовых аппаратов",
    typeTerminal: "Товар",
    terminalName: "Контрольно-кассовый аппарат MSPOS E-Ф",
    fullNameTitle: "Данные получателя",
    fullName: "ФИО получателя",
    postAddress: "Почтовый адрес",
    acquirerBank: "Выбор банка эквайера",
    countTerminal: "Количество аппаратов",
    fullPrice: "Стоимость",
    delivery:
      "Обращаем Ваше внимание, что доставка оплачивается отдельно. По вопросу доставки с Вами дополнительно свяжется менеджер.",
    buy: "Купить",
    documents: "Документы",
    status: "Статус",
    // Documents
    specifications:
      "Инструкция по использованию кассового аппарата для кассира",
    specificationsDescription: "Описание устройства",
    instruction: "Инструкция",
    instructionDescription: "Описание устройства",
    manual: "Руководство по использованию аппарата",
    manualDescription: "Описание устройства",
    commercialOffer: "Коммерческое предложение",
    commercialOfferDescription: "Описание устройства",
    // Status
    buttonAddClaim: "Оставить еще одну заявку",
    device: "Устройство",
    deviceName: "Название",
    count: "Кол-во",
    aboutOrder: "О заказе",
    // Buy
    firstBlock: {
      title:
        "RC Group предлагает своим партнерам комплексное решение MSPOS 4 в 1",
    },
    secondBlock: {
      title: "Контрольно-кассовый аппарат MSPOS E-Ф",
      circle1: "Без скрытых платежей",
      circle2: "Соответствует 54-ФЗ",
      circle3: "Лицензия 1С в подарок",
      circle4: "Не продаем данные клиентов",
      characteristics: "Характеристики",
      OS: "Операционные система",
      dimensions: "Габариты",
      cm: "см",
      weight: "Вес",
      g: "гр",
      memory: "Память",
      screen: "Дисплей",
      screenDescription:
        "5.5 дюймов, 1280x720 px (пикселей), 320 ppi (пикселей на дюйм)",
    },
    thirdBlock: {
      title: "Платежное ПО",
      description:
        "Универсальная платежная платформа для автоматизации любых задач торгового и интернет-эквайринга",
    },
    fourthBlock: {
      title: "Кассовое ПО",
      description:
        "<ul> <li>Простое в освоении рабочее место кассира</li> <li>Продажи маркированного товара</li> <li>Заведение и редактирование новых тоаров, управление целями</li> <li>Продажи алкоголя (потребуется подключение к внешнему УТМ)</li> <li>Прямая отправка отчетов о продажах на указанный e-mail</li> <li>Простая синхронизация с интернет-сервисом 1С:Касса</li> <li>Интеграция по протоколам Enterprise Data и ККМ-оффлайн</li> <li> Есть возможность использования в режиме кассы самообслуживания </li> </ul>",
    },
    fifthBlock: {
      title: "Программа лояльности",
      subtitle1: "Оформляйте скидки и принимайте оплату баллами",
      kassaAllows: "RC KASSA позволяет",
      description:
        "<li>Формировать отчеты о транзакциях</li> <li>Принимать оплату наличными, банковской картой и баллами RC</li> <li>Отслеживать очки сотрудника</li> <li>Отслеживать очки коллег сотрудника</li>",
      stayClaim: "Оставить заявку",
    },
    //LIMON
    Limon: {
      title: "Технология MPOS",
      subTitle:
        "Аренда кассовых аппаратов. Зачем покупать, если можно арендовать?",
      button: "Оставить заявку",

      firstBlock: {
        title: "mPOS + Android",
        pros: [
          "Простой и удобный интерфейс приема платежа",
          "Можно отправить банковского слип-чек клиенту по смс/email",
          "История всех совершенных операций всегда под рукой",
          "В описании платежа можно вводить дополнительную информацию",
          "Работает в связке по  bluetooth со смартфоном (версия андроид от 8.1)",
          "Банковские карты (с магнитной полосой, чипом и NFC-модулем)",
          "Интеграция с любыми программами и системами",
        ],
        tableTitle: "Технические характеристики",
        table: [
          [
            "Интеграция с ККМ:  да",
            "Тип терминала:  мобильный",
            "Беспроводное подключение: да",
          ],
          [
            "Печать слипа: нет",
            "Бесконтактные платежи: да",
            "Проводное подключение: нет",
          ],
          ["Магнитные карты: да", "Экран: монохромный", "Карты с чипом: да"],
        ],
        benefit: [
          "Бесплатное оборудование для приема карт",
          "Соответствует 54-ФЗ",
          "Кассовая программа работает на платформе Android",
          "Принимает платежи всеми доступными на рынке способами",
          "Интеграция с любыми программами и системами, автоматизация финансового учета",
          "Оптимально для такси, курьерских служб и стационарных точек продаж с небольшим оборотом",
        ],
      },

      secondBlock: {
        title: "Смарт-терминал 4 в 1 со встроенным эквайрингом",
        pros: [
          "Кассовая программа",
          "Кассовое оборудование",
          "Терминал для приема банковских карт",
          "Программа лояльности",
          "Облачный бэк офис для предпринимателей",
        ],
        tableTitle: "Технические характеристики",
        table: [
          ["Размеры: 185х75х65 мм", "Вес: 408 грамм "],
          ["Доступ к интернету: Wi-Fi/2G /3G/4G", "Основная камера: 8 МП "],
          ["Навигация: GPS", "Необходим РКО: Нет"],
          [
            "Аккумулятор: 8 часов без подзарядки",
            "Фронтальная камера: 0,3 МП ",
          ],
        ],
        benefit: [
          "Быстрый запуск",
          "Соответствует 54-ФЗ",
          "Бесплатная аренда при обороте свыше 500 000 руб/мес",
          "Не требует РКО",
          "Подходит для всех отраслей бизнеса",
          "Интегрируется с большим количеством учетных системам",
        ],
      },

      loyalty: {
        title: "Программа лояльности",
        kassa: "RC KASSA",
        aboutKassa: "Оформляйте скидки и принимайте оплату баллами",
        kassaOpportunities: "RC KASSA позволяет:",
        opportunitiesList: [
          "Формировать отчеты о транзакциях",
          "Принимать оплату наличными, банковской картой и баллами RC",
          "Отслеживать очки сотрудника",
        ],
      },

      documents: {
        title: "Документы",
        documentsList: [
          {
            name: "Инструкция подключения терминала",
            link: "https://cdn.rc.today/storage/partners/common/docs/terminals/termial_connect_guide.pdf",
          },
          // {
          //   name: "Инструкция mspos",
          //   link: "https://cdn.rc.today/storage/partners/common/docs/terminals/mspos_guide.pdf"
          // },
          {
            name: "Инструкция банка втб (4 в 1)",
            link: "https://cdn.rc.today/storage/partners/common/docs/terminals/vtb_bank_guide.pdf",
          },
          // {name: "Процессинг", link: "https://cdn.rc.today/storage/partners/common/docs/terminals/processing_cp.pdf"},
        ],
        about: "Описание устройства",
        date: "18.04.2022",
      },

      modal: {
        warning: "Банком эквайером является ПАО Банк ВТБ",
        fullNameTitle: "ФИО",
        fullNamePlaceholder: "Введите ФИО",
        emailTitle: "Почта",
        emailPlaceholder: "Введите почту",
        phoneTitle: "Телефон",
        phonePlaceholder: "Введите телефон",
        commentTitle: "Комментарий",
        commentPlaceholder: "Введите текст сообщения",
        organization: "Выбор организации",
        terminal: "Выбор аппарата",
        success: "Заявка успешно создана",
      },
    },
    // Terminals About Order Page
    fromOrder: "О заказе от",
    recipient: "Получатель",
    cashRegister: "Контрольно-кассовый аппарат",
    fiscalAccumulator: "Фискальный накопитель",
    courierDelivery: "Доставка курьером",
    pickupDelivery: "Самовывоз из пункта",
    deliveryError: "В выбранный город доставка не осуществляется",
    insuranceAndPackaging: "Страховка товаров и упаковка",
    standardInsurance: "Стандартная страховка",
    finalSum: "Итоговая сумма",
    downloadInvoiceForPayment: "Скачать счет на оплату",
    // Buy-terminal page
    deleteFromClaim: "Убрать из заявки",
    recoverToClaim: "Восстановить",
    driveExpirationDate: "Срок действия накопителя (в месяцах)",
    descriptionFiscalSelect:
      "Выбор зависит от специфики бизнеса (продажа акцизных товаров, общепит и т.д.), организационно-правовой формы и режима налогообложения. По окончании срока действия накопитель подлежит замене.",
    countFiscal: "Количество накопителей",
    countFifteenMonth: "Количество накопителей на 15 месяцев",
    contThirtySixMonth: "Количество накопителей на 36 месяцев",
    amountOfGoods: "Сумма за товары",
    proceedToCheckout: "Перейти к оформлению заказа",
    mixedOrder: "Смешанный заказ",
    // Order-terminal Page
    orderTerminalTitle: "Заказ кассовых аппаратов",
    toOrder: "Заказать",
    clickAgree: "Нажимая, я соглашаюсь с условиями",
    ifNeedHelp:
      "Если вам нужна помощь в регистрации кассового аппарата в ФНС, сообщите об этом менеджеру",
    outcome: "Итог",
    issueCEP: "Регистрация в налоговой",
    issueOFD: "Регистрация ОФД",
    nalogReg: "Настройка кассы",
    issueCEP_description: "Создание квалифицированной электронной подписи",
    issueOFD_description:
      "Регистрация вашей кассы в операторе фискальных данных",
    nalogReg_description: "Настройка кассы для работы сразу после получения",
    serviceProvision: "Предоставление услуги",
  },

  // FAQ Page
  faq: {
    title: "FAQ",
    cabinetPartner: "RC CITY",
    rcKassa: "RC KASSA",

    sections: {
      balance: {
        title: "Баланс",

        subsections: {
          information: {
            title: "Информация о балансе",
            description:
              "Баланс отражает информацию о ваших средствах, которые будут выплачены вам в период взаиморасчетов." +
              "<br><br>" +
              "Также на странице баланс представлена полная его детализация, которая помогает  лучше контролировать свои финансы и понимать текущее состояние своих средств.",
            video: ["//video.platformcraft.ru/embed/670d20a20e47cf4d95e14284"],
          },
        },
      },
      analytics: {
        title: "Аналитика",

        subsections: {
          organization: {
            title: "Юрлица",
            description:
              "В разделе <b>«Аналитика»</b> представлена аналитика по всем вашим заведениям и клиентам, которые используют в RC PAY. Информация представлена на пяти вкладках с акцентом на разные данные." +
              "<br><br>" +
              "На вкладке <b>«Юрлица»</b> можно посмотреть общие данные по клиентам, в том числе новым, а также узнать средний чек, количество покупок и общую сумму продаж. Кроме того, предусмотрена возможность получить подробную информацию по каждой зарегистрированной организации и выбрать интересующий Вас период, что позволит узнать количество продаж за день, неделю, месяц, год.",
            video: [],
          },
          shops: {
            title: "Торговые точки",
            description:
              "На вкладке <b>«Торговые точки»</b> расположена расширенная аналитика предыдущего подраздела: " +
              "<ul class='doted_list'>" +
              "<li>данные по клиентам;</li>" +
              "<li>продажи;</li>" +
              "<li>сумма продаж за необходимый период;</li>" +
              "<li>данные по каждой организации;</li>" +
              "<li>информация об активности сотрудников;</li>" +
              "<li>сумма продаж по каждому сотруднику;</li>" +
              "<li>очки кассирам.</li>" +
              "</ul> Выбрав <b>«Все торговые точки»</b>, можно просмотреть общую информацию по всем имеющимся торговым точкам.",
            video: ["//video.platformcraft.ru/embed/61f795da0e47cf171e558af5"],
          },
          employee: {
            title: "Сотрудники",
            description:
              "На вкладке <b>«Сотрудники»</b> Вы можете отслеживать активность Ваших сотрудников и оценивать их продуктивность за тот или иной период по следующим критериям:" +
              "<ul class='doted_list'>" +
              "<li>имя сотрудника, проводившего транзакцию;</li>" +
              "<li>торговую точку;</li>" +
              "<li>количество продаж;</li>" +
              "<li>сумму продаж;</li>" +
              "<li>очки кассира;</li>" +
              "<li>количество списанных баллов;</li>" +
              "<li>количество начисленных баллов.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/61f795ed0e47cf171e558af6"],
          },
          RFM: {
            title: "RFM-анализ",
            description:
              "На вкладке <b>«RFM-анализ»</b> представлен анализ сегментации клиентов по частоте и сумме покупок. Он помогает выявлять тех клиентов, которые больше всего покупают у Вас." +
              "<br> <br>" +
              "Для получения информации выберите <b>«Юрлицо»</b> из всплывающего меню и период в <b>«Календаре»</b>. По итогу отобразится таблица с результатами анализа групп, которые вы сформировали в <b>«Начислении баллов»</b>.",
            video: ["//video.platformcraft.ru/embed/63aeb61c0e47cf7b45610124"],
          },
          clients: {
            title: "Портрет клиента",
            description:
              "На вкладке <b>«Портрет клиента»</b> Вы можете детально просмотреть информацию обо всех клиентах выбранной организации, выявить постоянных клиентов. На графике возраста можно ознакомиться с дополнительными данными по возрастным категориям:" +
              "<ul class='doted_list'>" +
              "<li>количеством клиентов данной возрастной категории;</li>" +
              "<li>их средним чеком;</li>" +
              "<li>количеством покупок;</li>" +
              "<li>суммой покупок.</li>" +
              "</ul>",
            video: ["//video.platformcraft.ru/embed/63aeb91c0e47cf7b45610131"],
          },
        },
      },

      loyaltyProgram: {
        title: "Программа лояльности",

        subsections: {
          addPoints: {
            title: "Начисление баллов",
            description:
              "В разделе «Начисление баллов» Вы можете настроить размер начисляемых баллов. Минимальный процент начисления – 4%, а максимальный – 25%. Чтобы усилить лояльность клиентов, группируйте их в зависимости от суммы покупок и назначайте каждой группе свой процент вознаграждения." +
              "<br>" +
              "Для создания группы клиентов выполните следующие действия:" +
              "<br>" +
              "<ol class='number_list'>" +
              "<li>Выберите торговые точки, для которых Вы хотите создать группу клиентов. Например:" +
              "<ul class='doted_list circle'>" +
              "<li>Новый клиент с покупками на сумму до 10 000 рублей – вознаграждение составит 5%.</li>" +
              "<li>Постоянный клиент с покупками от 10 000 рублей – вознаграждение составит 10%.</li>" +
              "<li>Любимый клиент с покупками от 50 000 рублей – вознаграждение составит 20%.</li>" +
              "</ul></li>" +
              "<li>Нажмите кнопку «Создать группу».</li>" +
              "</ol>" +
              "<br>На этой странице также есть возможность установить <b>«Текущую задержку торговой точки»</b> – это количество дней, спустя которое клиенту будет начислено вознаграждение за покупку. Значение может быть от 0 до 14 дней. Нажмите кнопку <b>«Изменить задержку»</b>, чтобы указать желаемый срок, а затем – <b>«Сохранить»</b>. Обратите внимание: функция будет работать корректно только при положительном балансе в RC CITY.",
            video: ["//video.platformcraft.ru/embed/658b5dd1ef3db51ca61a4ee1"],
          },
          promotions: {
            title: "Акции",
            description:
              "В подразделе <b>«Акции»</b> Вы можете создавать и отслеживать акции для своих заведений сразу или по отдельности. Информация представлена в виде таблицы со столбцами следующего содержания: название акции, торговая точка, где она проходит, процент начисления баллов (не суммируется с процентами начисления баллов из предыдущего раздела), процент скидки. Также напротив каждой акции предусмотрена возможность скрыть из <b>RC Кассы</b> у сотрудника редактировать и удалить." +
              "<br><br>" +
              "Для того, чтобы создать акцию в всплывшем окне укажите:" +
              "<ul class='doted_list'>" +
              "<li>торговую точку,</li>" +
              "<li>название акции,</li>" +
              "<li>процент начисления баллов,</li>" +
              "<li>процент скидки.</li>" +
              "</ul>" +
              "После успешного создания акции Вы можете зайти, как сотрудник в <b>RC KASSA</b>, и при нажатии кнопки <b>«Акции»</b>, отобразиться созданная в кабинете акция. ",
            video: ["//video.platformcraft.ru/embed/63aebbf9ef3db55eb798f8f2"],
          },
          historyPayment: {
            title: "История операций",
            description:
              "На вкладке <b>«Все операции»</b> в подразделе <b>«История операций»</b> будет отображена таблица со всеми пополнениями и списаниями средств с баланса в RC CITY. Чтобы получить более подробную информацию, Вы можете указать нужный вам период в календаре." +
              "<br> <br>" +
              "Также на этой странице Вы можете скачать историю операций за определенный период или за все время использования кабинета. Чтобы сделать это, нажмите кнопку <b>«Скачать»</b>, выберите формат скачивания и, по желанию, установите флажок <b>«Отправить копию на электронную почту»</b>. В этом случае файл будет отправлен на Вашу электронную почту, указанную при регистрации в RC CITY. После этого снова нажмите кнопку <b>«Скачать»</b>." +
              "<br> <br>" +
              "На вкладке <b>RC Processing</b> Вы можете увидеть информацию по всем проведенным операциям на кассовых аппаратах.",
            video: ["//video.platformcraft.ru/embed/658aeba50e47cf2ffec366a7"],
          },
        },
      },

      clients: {
        title: "Клиенты",

        subsections: {
          clients: {
            title: "Список клиентов",
            description:
              "В разделе <b>«Список клиентов»</b> доступна информация о Ваших покупателях. Чтобы просмотреть данные, выберите организацию из выпадающего списка или отметьте флажком пункт «Все организации»." +
              "<br> <br>" +
              "На вкладке <b>«Все клиенты»</b> вы увидите информацию обо всех клиентах Вашего заведения. <br>" +
              "Вкладка <b>«Внутренние клиенты»</b> покажет данные о клиентах, оцифрованных в Вашем заведении. <br>" +
              "На вкладке <b>«Внешние клиенты»</b> представлена информация о клиентах, которые посещали Ваше заведение и сделали в нем хотя бы одну покупку." +
              "<br> <br>" +
              "Внизу страницы Вы также найдете кнопку <b>«Пригласить»</b>, нажав на которую можно скопировать ссылку или скачать QR-код для регистрации клиента.",
            video: ["//video.platformcraft.ru/embed/658b5e700e47cf2ffec3675c"],
          },
          feedback: {
            title: "Отзывы",
            description:
              "В подразделе <b>«Отзывы»</b> отображаются отзывы всех клиентов, которые посещали Ваше заведение и оставили отзыв на сайте rcpay.ru или в приложении RC PAY. <br> Для удобной работы с отзывами доступно несколько фильтров:  " +
              "<ol class='number_list'>" +
              "<li><ul class='doted_list circle'>" +
              "<li>При нажатии кнопки <b>«Выберите торговую точку»</b> отобразятся все отзывы, оставленные клиентом о данной точке</li>" +
              "<li>При выборе пункта <b>«Все торговые точки»</b> Вы сможете увидеть отзывы на все торговые точки.</li>" +
              "</ul></li>" +
              "<li>Предусмотрена возможность посмотреть отзывы за определенный период или дату. Для этого откройте календарь, выберете из списка нужный период или задайте его в календаре</li>" +
              "<li>Отфильтровать отзывы можно по 3 характеристикам: <ul class='doted_list circle'>" +
              "<li>по новизне;</li>" +
              "<li>только положительные;</li>" +
              "<li>только отрицательные. </li>" +
              "</ul></li>" +
              "</ol>" +
              "<br><br>" +
              "Также Вы можете выбрать конкретные отзывы, которые будут отображены на сайте rcpay.ru и в приложении RC PAY. Для этого нажмите на знак просмотра у тех отзывов, которые хотите, чтобы отображались на сайте и в приложении. Обратите внимание, что скрытые отзывы не будут влиять на оценку заведения, которая будет отображаться на сайте.",
            video: ["//video.platformcraft.ru/embed/658b5e01ef3db51ca61a4ee2"],
          },
          pushNotifications: {
            title: "Push-уведомления",
            description:
              "В подразделе <b>«Push-уведомления»</b> можно настроить рассылку для Ваших клиентов, чтобы они всегда были в курсе Ваших акций и мероприятий." +
              "<ol class='number_list'>" +
              "<li>Для этого нажмите плюс напротив «Создать Push-рассылку» и заполните соответствующие поля;</li>" +
              "<li>Выберите Торговую точку, на клиентов которой планируете делать рассылку;</li>" +
              "<li>Укажите текст заголовка (например: «праздничные скидки всем»);</li>" +
              "<li>Введите текст сообщения push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.</li>" +
              "</ol>" +
              "<br>" +
              "Ваши клиенты получат push-уведомление в течение 15 минут. Обратите внимание на то, что после создания уведомления изменить его будет невозможно. Все разосланные оповещения отображаются в виде таблицы на этой же странице. По каждому можно нажать «Подробнее» и посмотреть Точку продаж, на которую сделали рассылку, дату отправки и текст.\n",
            video: ["//video.platformcraft.ru/embed/63aeb56e0e47cf7b45610121"],
          },
        },
      },

      wallet: {
        title: "Карты лояльности",

        subsections: {
          spread: {
            title: "Распространение карт Wallet",
            description:
              "Карта Wallet — это электронный эквивалент карт магазинов и заведений. В подразделе <b>«Распространение карты Wallet»</b> можно получить QR-код для использования его в оцифровке клиентов, а также ссылку для скачивания карты, которую можно разместить в социальных сетях, на сайте и любых других источниках, что позволит оцифровывать клиентов в любой точке мира.",
            video: ["//video.platformcraft.ru/embed/61f796daef3db553eb6a2312"],
          },
          designWallet: {
            title: "Дизайн карты Wallet",
            description:
              "В разделе <b>«Дизайн карты Wallet»</b> предусмотрена возможность создания уникального дизайн ваших карт. Для этого необходимо:" +
              "<ol class='number_list'>" +
              "<li>Выбрать вариант из выпадающего списка в разделе <b>«Выбрать организацию»</b>, для которого Вы хотите создать карты Wallet.</li>" +
              "<li>Выбрать фон: перетащите или загрузите файл с изображением в окно <b>«Вставьте картинку для фона вашей Wallet-карты»</b>. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 16:9.</li>" +
              "<li>Выбрать логотип. Перетащите или загрузите файл с изображением в окно Вставьте логотип вашей Wallet-карты. Обратите внимание, что вес файла не должен превышать 2 МБ, соотношение сторон 1:1.</li>" +
              "<li>Выберите цвет QR-кода, заголовка и номера карты.</li>" +
              "</ol>" +
              "Чтобы сохранить настройки, нажмите <b>«Изменить дизайн»</b>, а для сброса параметров — <b>«Вернуть стандартный дизайн»</b>.",
            video: ["//video.platformcraft.ru/embed/61f796e7ef3db553eb6a2313"],
          },
        },
      },

      employeesAndManagers: {
        title: "Мои сотрудники",

        subsections: {
          managingManagers: {
            title: "Менеджеры",
            description:
              "В разделе <b>«Менеджеры»</b> отображены все менеджеры, которых зарегистрировал владелец ТСП:" +
              "<ul class='doted_list'>" +
              "<li>Ф.И.О сотрудника;</li>" +
              "<li>должность;</li>" +
              "<li>номер телефона;</li>" +
              "<li>логин.</li>" +
              "</ul>" +
              "Также можно добавить, изменить, заблокировать и удалить менеджера. Для того, чтобы добавить менеджера нужно нажать плюс, указать фамилию, имя, отчество, должность, номер телефона и настроить доступ к страницам личного кабинета. Логин и пароль для входа будут сформированы программой автоматически. Помните, что пароль, после добавления менеджера, показывается единожды. Рекомендуем его сохранить в удобном для Вас месте, так как он не будет отображен в программе." +
              "<br><br>" +
              "Обратите внимание, что менеджер не имеет доступа к проведению транзакций в приложении RC KASSA.",
            video: ["//video.platformcraft.ru/embed/61f796ad0e47cf171e558b08"],
          },
          managingEmployee: {
            title: "Кассиры",
            description:
              "В разделе <b>«Кассиры»</b> представлены данные по всем созданным сотрудникам. Для создания сотрудника необходимо нажать плюс и ввести фамилию, имя, а также выбрать торговую точку сотрудника. В таблице вы найдете логин, пароль, которые необходимы для входа в RC.Kassa, и статус сотрудника." +
              "<br><br>" +
              "Кроме того, в этой вкладке можно посмотреть информацию по набранным баллам, заблокировать, либо удалить сотрудника.",
            video: ["//video.platformcraft.ru/embed/61f796c10e47cf171e558b09"],
          },
          addPoints: {
            title: "Очки кассирам",
            description:
              "В разделе <b>«Очки кассирам»</b> можно настроить количество выдаваемых сотруднику очков за проведение операции и проведение операции с новым клиентом.\n",
            video: ["//video.platformcraft.ru/embed/61f796ccef3db553eb6a2311"],
          },
        },
      },

      enterprises: {
        title: "Мои предприятия",

        subsections: {
          organization: {
            title: "Юрлица",
            description:
              "В подразделе <b>«Юрлица»</b> Вы можете добавить имеющуюся у Вас организацию, чтобы впоследствии на ее заведения распространялась программа лояльности. Для этого нажмите знак плюса напротив «Юрлица» и заполните все имеющиеся поля: " +
              "<ul class='doted_list'>" +
              "<li>ИНН организации;</li>" +
              "<li>юридическое наименование;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>банк, обслуживающий организацию; </li>" +
              "<li>БИК;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>Ф.И.О руководителя.</li>" +
              "</ul>" +
              "Обратите внимание особое внимание на корреспондентский и расчетный счета. В них должно быть одинаковое количество цифр и отсутствовать лишние знаки (пробелы и точки). Также при выборе типа организации «Халяль», необходимо загрузить подтверждающий документ в формате pdf, jpeg или png. " +
              "<br><br>" +
              "Кроме того, возможность добавить вторую и последующие организации присутствует только на платных тарифах. Если у Вас несколько организаций, то Вы можете выбрать, какая из них станет основной и будет действовать даже в случае понижения тарифа. После создании организации Вы сможете отслеживать статус проверки внесенной вами информации со стороны модерации:" +
              "<ul class='doted_list'>" +
              "<li>«Подтверждено», если все данные заполнены верно и проверка пройдена успешно;</li>" +
              "<li>«Отклонено», если информация заполнена неверно или требует уточнения. Пожалуйста, обращайте внимание на комментарии модератора, они подскажут Вам, какую информацию нужно скорректировать;</li>" +
              "<li>«На модерации», если организация ожидает проверки или уже проверяется модератором.</li>" +
              "</ul>" +
              "Если модерация будет отклонена, то в поле «Ответ модерации» появится поясняющее причину сообщение. Внимательно изучите его и выполните рекомендации модератора." +
              "<br><br>" +
              "Также Вы всегда можете посмотреть информацию о выбранной организации, нажав на кнопку «Подробнее» напротив наименования:" +
              "<ul class='doted_list'>" +
              "<li>наименование организации;</li>" +
              "<li>ИНН;</li>" +
              "<li>юридический адрес;</li>" +
              "<li>БИК банка;</li>" +
              "<li>расчетный счет;</li>" +
              "<li>корреспондентский счет;</li>" +
              "<li>сумма депозита.</li>" +
              "</ul>" +
              "На этой странице Вы сможете поменять данные и задать сумму депозита, нажав на кнопку «Изменить реквизиты организации»." +
              "<br><br>" +
              "Сумма депозита — это размер минимального остатка на счете, т.е. при превышении выбранного вами значения со стороны RC Group будет осуществлена выплата вашей компании, а при недостижении — оплата не происходит.",
            video: ["//video.platformcraft.ru/embed/61f795f9ef3db553eb6a2303"],
          },
          shops: {
            title: "Торговые точки",
            description:
              "В разделе <b>«Торговые точки»</b> Вы найдете список всех зарегистрированных точек, историю чеков. Также здесь есть возможность при необходимости удалить точку ." +
              "<br><br>" +
              "Для добавления новой торговой точки перейдите на вкладку <b>«Торговые точки»</b> и нажмите кнопку <b>«Создать»</b> в правом верхнем углу. Затем заполните все обязательные поля. Для Вашего удобства рядом с полями есть подсказки. Постарайтесь максимально подробно и точно описать деятельность Вашей торговой точки и подчеркнуть ее уникальность с помощью описания и фотографий. " +
              "<br><br>" +
              "Важно указать юрлицо, с которым будут производиться взаиморасчеты. Для этого выберите соответствующее юридическое лицо из выпадающего списка." +
              "<br>" +
              "Заполненная информация будет отправлена на модерацию." +
              "<br>" +
              "Вы сможете отслеживать статус проверки после того, как данные торговой точки будут отправлены на модерацию:" +
              "<ul class='doted_list'>" +
              "<li><i>«Подтверждено»</i>, если все данные заполнены корректно и проверка пройдена успешно. В этом случае Ваша точка будет размещена на сайте rcpay и в приложении RC PAY.</li>" +
              "<li><i>«Отклонено»</i>, если недостаточно информации или требуются уточнения. Вы увидите пояснительное сообщение от модератора с рекомендациями по исправлению.</li>" +
              "<li><i>«На модерации»</i>, если точка ожидает проверки или уже проверяется модератором.\n</li>" +
              "</ul>" +
              "<br><br>" +
              "<b>«История чеков»</b><br>" +
              "Чтобы просмотреть историю чеков, нажмите на кнопку <b>«История чеков»</b> в строке соответствующей торговой точки. Вы можете отфильтровать и отсортировать таблицы или просмотреть конкретную транзакцию более детально, нажав кнопку <b>«Подробнее»</b>.",
            video: ["//video.platformcraft.ru/embed/658b5ee80e47cf2ffec3675e"],
          },
          // paymentMethods: {
          //   title: "Способы оплаты",
          //   description:
          //     "Теперь Вы можете подключить электронный тип оплаты (банковская карта, СБП, Yandex Pay и др.) <br>" +
          //     "На странице <b>«Способы оплаты»</b> выберите юрлицо и перейдите на вкладку <b>«Документы»</b>. В этом разделе Вы найдете документы для подписания: <b>«Заявление о присоединении»</b>, <b>«Ставки банковского эквайринга»</b>, а также <b>«Инструкцию по активации ключей»</b>. В разделе <b>«Статус»</b> Вы можете отследить процесс подключения." +
          //     "<br><br>" +
          //     "После того как документы будут подписаны и пройдут модерацию, Вы увидите сообщение модератора о ставке, которая вам одобрена. <br>" +
          //     "Далее ожидайте ключи от нашего партнера «Рокет Ворк» на электронную почту, которую Вы указали при регистрации. <br>" +
          //     "С помощью <b>«Инструкции по активации ключей»</b> Вы сможете самостоятельно активировать кассу и начать принимать платежи в этот же день.",
          //   video: ["//video.platformcraft.ru/embed/658b5e9a0e47cf2ffec3675d"],
          // },
          documents: {
            title: "Документы",
            description:
              "В разделе <b>«Документы»</b> на вкладках «Документы» и «Маркетинговые материалы» Вы сможете ознакомиться с инструкциями и материалами, относящимся к продукции RC GROUP.",
            video: ["//video.platformcraft.ru/embed/61f79688ef3db553eb6a230f"],
          },
          actsAndAccounts: {
            title: "Акты и счета",
            description:
              "В подразделе <b>«Акты и счета»</b> можно просмотреть счета и акты по вашим организациям, выбрав нужную. На вкладке «Счета» можно ознакомиться с наименованием, суммой, датой и временем, статусом платежа, также скачать нужный Вам счет в любое время. На вкладке «Акты» представлены все выставленные акты, а также есть возможность сформировать по ним счет, скачать или просмотреть детальную информацию. Кроме того, в разделе «Акты» Вы можете указать период, за который Вы бы хотели сформировать акт." +
              "<br><br>" +
              "Для удобства клиентов с 01.04.2022 в отображающуюся информацию в актах сверки внесены изменения. В старой версии документа (сформированной до 31.03.2022) отражена информация только в баллах, а в новой версии (с 01.04.2022) — информация о фактическом пополнении и списании средств с баланса в RC CITY. Чтобы скачать документы, достаточно выбрать необходимый вам вид документа и нажать кнопку «Сформировать».",
            video: ["//video.platformcraft.ru/embed/61f796680e47cf171e558af9"],
          },
          tariffs: {
            title: "Тарифы",
            description:
              "В разделе <b>«Тарифы»</b> показаны все имеющиеся тарифы. Чтобы приобрести тариф, выберите интересующее Вас предложение и нажмите «Купить тариф», выберите «Юрлицо» и нажмите кнопку «Оплатить». <br>" +
              "Для оплаты тарифа вы должны пополнить баланс, для этого сформируйте счет в разделе «Баланс» на сумму стоимости тарифа и произведите оплату с реквизитов, принадлежащих выбранной организации.",
            video: ["//video.platformcraft.ru/embed/658b5e43ef3db51ca61a4ee3"],
          },
        },
      },

      services: {
        title: "Сервисы",

        subsections: {
          checkingCounterparties: {
            title: "Проверка контрагентов",
            description:
              "В подразделе <b>«Проверка контрагентов»</b> Вы можете с помощью бота проверить контрагентов. Это необходимо для оперативного выявления финансовых и налоговых рисков, которые могут возникнуть при работе с контрагентом, и принятия обоснованных решений: продолжить или прекратить сотрудничество." +
              "<br><br>" +
              "Бот автоматически проверяет новых и текущих контрагентов по разным источникам и сообщает вам о критических факторах и сведениях, требующих внимания." +
              "<ol class='number_list'>" +
              "<li>Статус юридического лица: «действующая», «в процессе ликвидации», «ликвидирована».</li>" +
              "<li>Руководители.</li>" +
              "<li>Уставной капитал.</li>" +
              "<li>Состояние счетов (если они заблокированы, то вряд ли контрагент сможет расплатиться).</li>" +
              "</ol>" +
              "Кроме того, с помощью бота вы можете проводить массовую проверку и мониторинг изменений по всей базе контрагентов или по конкретно выделенной. Это позволяет вовремя реагировать на существенные изменения у клиентов и партнеров, снижая риск невозврата денежных средств.",
            video: ["//video.platformcraft.ru/embed/63aec1bb0e47cf7b45610151"],
          },
          eMenu: {
            title: "Электронное меню",
            description:
              "На странице <b>«Электронное меню»</b> Вы можете добавить свои товары или услуги, чтобы клиенты могли ознакомиться с ассортиментом Вашего заведения или магазина на сайте rcpay.ru или в приложении RC PAY." +
              "<br>" +
              "Также внизу страницы Вы можете сформировать и скачать QR-код, который позволит перейти к меню Вашей торговой точки в несколько кликов.",
            video: ["//video.platformcraft.ru/embed/658aedae0e47cf2ffec366af"],
          },
        },
      },

      contacts: {
        title: "Контакты",

        subsections: {
          contacts: {
            title: "Способы связи",
            description:
              "По всем интересующим вопросам обращайтесь по телефону <a href='tel:88005005762' class='phone'>8 (800) 500-57-62</a> или по электронной почте  <a href='mailto:hello@rc.city' class='email'>hello@rc.city</a>. Либо напишите свой вопрос в форму обратной связи, которая расположена в правом нижнем углу, и мы свяжемся с Вами удобным для Вас способом.",
            // video: ["//video.platformcraft.ru/embed/658aecadef3db51ca61a4e4a"],
          },
        },
      },
    },
  },

  // Corporate Communication Page
  corporate: {
    title: "Корпоративная связь",
    view: "Просмотр",
    createClaim: "Создать заявку",
    suitableFor: "Для чего подойдет?",
    suitableParagraph:
      "Уникальное предложение на рынке мобильной связи — соотношение цены и качества, а также эксклюзивные функции выгодно выделяют клиентов RC Mobile от пользователей других сотовых операторов",
    suitable: "Подойдет",
    forTraveling: "Для путешествий",
    forCorporateTasks: "Для корпоративных задач",
    forBusinessTrips: "Для командировок",
    benefits: "Какие преимущества?",
    easyConnection: "Простое подключение",
    easyConnectionDescription:
      "Автоматическое определение оптимального оператора мобильной связи позволяет пользователям платить по ценам местных операторов связи, где действует RC Mobile",
    favorableRates: "Выгодные тарифы",
    favorableRatesDescription:
      "После оплаты тарифа вне зависимости от региона пребывания владельца сим-карты — он может пользоваться тарифом без дополнительных затрат не меняя мобильного оператора",
    technicalSupport: "Техподдержка",
    technicalSupportDescription:
      "Оперативная и удобная поддержка сервисной службы RC консультирует пользователей RC Mobile по всем возникающим вопросам прямо из приложения в пункте “Поддержка”",
    ourTariffs: "Наши тарифы",
    modernApplication: "Современное приложение",
    countSimCard: "Количество сим-карт",
    purposesPurchaseSIMCards: "Для каких целей приобретаете сим-карты",
    thanksForClaim:
      "Благодарим за Вашу заявку. <br> В ближайшее время с Вами свяжется менеджер для уточнения деталей.",
    forUseAbroad: "Для использования за границей",
    foUseYourCountry: "Для использования в своей стране",
    newGenerationMobileCommunication: "Мобильная связь нового поколения!",
    joinUs:
      "Присоединяйтесь к нам уже сейчас, скачав приложение от нашего технологического партнера Глонасс",
    countSim: "Кол-во сим-карт",
    enterFullName: "Введите ФИО",
    enterEmail: "Введите E-mail",
    contactPhone: "Контактный телефон",
    enterPhone: "Введите телефон",
    writeCountOfSIM:
      "Укажите сколько сим-карт Вы бы хотели заказать для корпоративного пользования",
    different: "Другое",
    comment: "Комментарий",
    commentDescription: "Введите цель приобретения",
  },

  // Answer moderation
  status: {
    moderation: "На модерации",
    confirmation: "Подтверждено",
    confirm: "Подтвержден",
    reject: "Отклонено",
    paid: "Оплачено",
    active: "Активен",
    locked: "Заблокирован",
    activateProcess: "В процессе активации",
    awaitingPayment: "Ожидает оплаты",
    sent: "Отправлен",
    received: "Получен",
    canceled: "Отменен",
    unknown: "Неизвестно",
    awaitingDocs: "Ожидает загрузки документа",
    base: "Базовая",
  },
  // Table
  table: {
    status: "Статус",
    nameStatus: "Название статуса",
    percentageAccrual: "Процент начисления",
    fullName: "ФИО",
    position: "Должность",
    phone: "Номер телефона",
    login: "Логин",
    number: "Номер",
    sum: "Сумма",
    customer: "Покупатель",
    cashback: "Баллы",
    currency: "Валюта",
    dateTime: "Дата и время",
    date: "Дата",
    paid: "Оплачено",
    purchase: "С покупки на",
    transaction: "Транзакция",
    type: "Тип",
    points: "Очки",
    name: "Имя",
    visits: "Частота визитов",
    averageCheck: "Сумма покупок",
    birthday: "День рождения",
    age: "Возраст",
    nameAndSurname: "Фамилия и Имя",
    store: "Торговая точка",
    entityName: "Название организации",
    operationType: "Тип операции",
  },
  // Common text
  common: {
    nodata: "У вас еще нет торговой точки",
    buttonNoData: "Создать торговую точку",
    organizationSelect: "Выберите организацию",
    allOrganizationCheckbox: "",
    tradingPointsSelect: "Выберите торговую точку",
    allTradingPointsCheckbox: "Все торговые точки",
    from: "c",
    util: "до",
    to: "по",
    fillField: "Заполните это поле",
    invalidEmail: "Неверная почта",
    minLengthPass: "Пароль не меньше 8 символов",
    fillTime: "Заполните время",
    calendar: "Календарь",
    filter: "Фильтр",
    back: "Назад",
    loadDescription: "Пожалуйста, загрузите сертификат халяльности продукции:",
    loadFormat: "Поддерживаются сертификаты в формате .PDF .JPG .PNG",
    loadSize: "Вес файла может быть не более 10 МБ",
    dropFiles: "Перетащите файл сюда или",
    uploadFiles: "загрузите",
    chooseFile: "Выбрать файл",
    fileName: "Имя файла",
    Monday: "Понедельник",
    Tuesday: "Вторник",
    Wednesday: "Среда",
    Thursday: "Четверг",
    Friday: "Пятница",
    Saturday: "Суббота",
    Sunday: "Воскресенье",
    1: "Понедельник",
    2: "Вторник",
    3: "Среда",
    4: "Четверг",
    5: "Пятница",
    6: "Суббота",
    7: "Воскресенье",
    dayAndNightTime: "Точка работает круглосуточно",
    notWorkTime: "Точка не работает",
    month: "месяц",
    mo: "мес",
    year: "год",
    organizations: "Организации",
    shops: "Торговые точки",
    employees: "Кассиры",
    managers: "Менеджеры",
    manager: "Менеджер",
    owner: "Владелец",
    business: "Бизнес",
    tariffs: "Тарифы",
    historyOperations: "История операций",
    actsAndAccounts: "Акты и счета",
    cashback: "Начисление баллов",
    documents: "Документы",
    addBalance: "Авансовый платеж",
    addPoints: "Начисление очков",
    application: "Оплата кассового аппарата",
    claims: "Заявки",
    analytics: "Аналитика",
    sortNew: "По новизне",
    sortAscStars: "По убыванию звезд",
    sortDscStars: "По возрастанию звезд",
    email: "email",
    lastName: "Фамилия",
    firstName: "Имя",
    error404: "Ошибка 404",
    noPage: "Страница не найдена",
    errorTypeFile: "Не верный тип файла!",
    tryTypeFile: "Файл должен быть jpg или png",
    clients: "Клиенты",
    exitAccount: "Выйти из аккаунта",
    accountsForAct: "Счет по акту",
    downloadOn: "доступно в",
    downloadOnRuStore: "скачайте из",
    phone: "Телефон",
    comment: "Комментарий",
    pcs: "шт",
    gb: "ГБ",
    min: "мин",
    group: "Группа",
    emptyField: "Заполните все поля",
    correctField: "Все поля должны быть корректны!",
    bigFile: "Большой размер файла",
    "e-menu": "Электронное меню",
  },
  // Buttons
  button: {
    invite: "Пригласить",
    download: "Скачать",
    edit: "Редактировать",
    save: "Сохранить",
    saveChange: "Сохранить изменения",
    accept: "Принять",
    change: "Изменить",
    login: "Войти",
    create: "Создать",
    confirm: "Подтвердить",
    details: "Подробнее",
    copy: "Скопировать",
    topUp: "Пополнить",
    back: "Назад",
    send: "Отправить",
    sendAgain: "Отправить повторно",
    sendCode: "Отправить код",
    next: "Далее",
    proceed: "Продолжить",
    buy: "Купить",
    goto: "Перейти",
    open: "Открыть",
    delete: "Удалить",
    cancel: "Отмена",
    pay: "Оплатить",
    ok: "Хорошо!",
    cancelClaim: "Отменить заявку",
    generate: "Сгенерировать",
    apply: "Применить",
    reset: "Сбросить",
    clearAll: "Очистить все",
    calendarButton: {
      today: "Сегодня",
      yesterday: "Вчера",
      lastWeek: "Последние 7 дней",
      last30Days: "Последние 30 дней",
      lastMonth: "Прошлый месяц",
      thisMonth: "Этот месяц",
      lastYear: "Последний год",
      thisYear: "Текущий год",
      allPeriod: "Весь период",
      selectRange: "Свой период",
    },
    preview: "Предпросмотр",
    resetAll: "Сбросить все",
    showResult: "Показать результаты",
    upload: "Загрузить",
    expand: "Развернуть",
    hide: "Скрыть",
    try: "Попробовать",
    add: "Добавить",
    reveal: "Показать",
    readAll: "Читать полностью",
    collapse: "Свернуть",
    logout: "Выйти",
    show: "Показать",
    moreDetails: "Подробнее",
    settings: "Настройки",
  },
  month: {
    1: "Январь",
    2: "Февраль",
    3: "Март",
    4: "Апрель",
    5: "Май",
    6: "Июнь",
    7: "Июль",
    8: "Август",
    9: "Сентябрь",
    10: "Октябрь",
    11: "Ноябрь",
    12: "Декабрь",
  },
  charts: {
    pointsAccrued: "Начисленные баллы",
    pointsWritten: "Принято баллами",
  },

  tips: {
    instruction: {
      title: "Инструкция для работы чаевых",
      tipsTitle: "Безналичные чаевые",
      tipsDescription:
        "Помощь ресторанному бизнесу и салонам красоты <br> 80% посетителей заведений оплачивают товары и услуги картами, а не наличными средствами. Это приводит к падению мотивации сотрудников и качества обслуживания: ведь персонал перестает получать чаевые. Безналичные чаевые — сервис, позволяющий мастерам и официантам получать чаевые от клиентов. Бизнес ничего не теряет — все комиссии лежат на мастерах и клиентах, к тому же чаевые не облагаются налогом.",
      howTitle: "Как это работает?",
      howDescription:
        "Персонал регистрируется в системе сервиса <br> Каждый зарегистрированный участник получает QR-код <br> QR-код можно разместить на чеке, визитке или любой другой печатной продукции <br> Клиент переводит чаевые по QR-коду <br> Персонал получает деньги <br> Успех! <br> Клиент также может взять на себя комиссию: тогда сотрудник получит полную сумму чаевых.",
    },
    presentation: "Презентация",
    forRestaurants: "Для ресторанов",
    forShops: "Для салонов",
    arrange: "Оформить заявку",
  },

  eMenu: {
    title: "Электронное меню",
    hint: '<p>С подробной информацией о работе функционала «Электронное меню» Вы можете ознакомиться в разделе Мои предприятия -  <a href={link} target="_blank">Документы</a> - Маркетинговые материалы - Инструкция по использованию RC CITY</p>',
    searchPlaceholder: "Поиск по товарам",
    productsStatuses: {
      all: "Все товары",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    categoriesStatuses: {
      all: "Все категории",
      moderation: "Модерация",
      reject: "Отклоненные",
    },
    noData: {
      title: "Данных по электронному меню нет",
      noTradingPoints: "У вас пока нет Торговых точек ",
      noProducts: "У вас пока не создано ни одного товара",
    },
    category: "Категория",

    table: {
      productName: "Название товара",
      category: "Категория",
      price: "Цена",
      status: "Статус",
      noCategory: "Без категории",
    },

    qr: "QR - код",

    QRModalTitle: "QR-код на меню",
    QRModalSubTitle:
      "Выберите торговую точку, QR-код на меню которой хотите скачать",

    productCard: {
      createTitle: "Создание карточки товара",
      editTitle: "Информация о товаре",
      images: "Изображения",
      sku: "Артикул",
      skuPlaceholder: "до 30 символов",
      productName: "Название товара",
      price: "Цена, {currency}",
      discount_price: "Цена со скидкой, {currency}",
      category: "Категория",
      description: "Описание",
      descriptionMax: "Макс. 1000 символов",
      image: {
        cover: "Обложка",
        coverTitle: "Загрузить обложку",
        cropPhoto: "Обрежьте фото",
        imageTitle: "Загрузить изображение",
        tooltip:
          "Добавьте обложку и изображения к товару. Максимальное количество изображений — 5.",
      },
      moderationMessageTitle: "Ответ модерации",
      dimensions: "Единицы измерения",
      dimensionsPlaceholder: "Выберите единицу измерения",
      noDimension: "Без единиц измерения",
    },

    pointsListTitle: "Отображение в торговой точке",

    deleteProduct: "Вы уверены, что хотите удалить “{label}”?",
    hideProduct: "Вы уверены, что хотите скрыть “{label}”?",
    revealProduct: "Вы уверены, что хотите показать “{label}”?",
    categoryPlaceholder: "Выберите категорию",

    limits: {
      lite: "Вы создали 20 товаров, ваш лимит исчерпан. <br> Чтобы добавить больше позиций, повысьте тариф до Промо.",
      promo:
        "Вы создали 150 товаров, ваш лимит исчерпан. <br> Для добавления товаров без ограничений повысьте тариф до Pro+",
      tariffs: "Тарифы",
    },
  },

  eMenuCategories: {
    title: "Категории",

    table: {
      name: "Название  категории",
      productCount: "Кол-во товаров",
      status: "Статус",
    },
  },

  validationErrors: {
    emptyField: "Это поле не может быть пустым",
    invalidEmailFormat: "Введите email вида {email}",
    invalidSiteFormat: "URL должен содержать http:// или https://",
    invalidFormat: "Неверный формат",
    incorrectValueStart: "Необходимо указать значение от",
    incorrectValueEnd: "до",
    invalidValueMaxLength: "Максимальное количество символов ",
    invalidValueMinLength: "Минимальное количество символов ",
    invalidHouseNumber: "Уточните номер дома",
    invalidAddress: "Уточните адрес",
    invalidWorkDays: "Необходимо выбрать хотя бы один рабочий день",
    invalidWorkTime: "Неверный формат времени",
    errorWorkTime:
      "Время открытия не может быть позже времени закрытия или совпадать с ним",
    notEnoughElement: "Недостаточное количество элементов",
    manyElement: "Вы превысили максимальное количество элементов",
    maxLessMin: "Меньше минимального",
    invalidAge: "Некоректный возраст",
    minDate: "Дата должна быть не позднее {date}",
    maxDate: "Дата должна быть не ранее {date}",
    requiredPhone: "Введите номер телефона",
    reqiredPromocode: "Промокод введен неверно",
    requiredCountry: "Необходимо выбрать страну",
    havePhone: "Этот телефон уже зарегистрирован",
    haveMail: "Данная почта уже зарегистрирована",
    invalidEmail: "Некорректная почта",
    email: "Введите почту",
    first_name: "Введите имя",
    last_name: "Введите фамилию",
    password: "Введите пароль",
    corresp_account: "Необходимо указать корсчет",
    number_account: "Необходимо указать номер расчетного счета",
    bik_of_bank: "Необходимо указать БИК банка",
    international_bank:
      "Необходимо указать международный номер банковского счета",
    vat_code: "Необходимо указать код НДС",
    inn: "Необходимо указать инн",
    entityAddress: "Необходимо указать юридический адрес",
    fullName: "Необходимо указать ФИО",
    ogrn: "Необходимо указать ОГРН",
    monthly_turnover: "Необходимо указать размер ежемесячного оборота",
    deposit_sum: "Необходимо указать размер депозита",
    tax_system: "Необходимо указать тип налогообложения",
    legal_type: "Необходимо указать форму юрлица",
    okved_code: "Необходимо указать основной вид деятельности",
    minFillingPhotos: "Минимальное количество файлов - {amount}",
    notValidPromocode: "Промокод не существует",
    cyrillicOnly: "Только кириллица и латиница",
    haveInn: "Юрлицо с этим {option} уже существует",
    addressWithoutNumber:
      "Необходимо указать номер здания/сооружения, помещения",
    login: "Введите логин",
    empryNamedField: "Необходимо указать {option}",
  },

  //  marketplaces Page
  marketplaces: {
    title: "Маркетплейсы",
    inn: "ИНН",
    phone: "Номер телефона",
    link: "Ссылка на сайт (не обязательно)",
    error: "Поля заполнены неверно",
  },

  promotions: {
    title: "Акции",
    createNewPromo: "Создать новую акцию",
    headersList: {
      name: "Название акции",
      tradingPoint: "Торговая точка",
      cashback: "Процент начисления",
      discount: "Процент скидки",
    },
    name: "Название акции",
    cashback: "Процент начисления",
    discount: "Процент скидки",
    createTitle: "Создание новой акции",
    aboutPageModal: {
      title: "Функционал подраздела “Акции”",
      body: 'Подраздел "Акции" помогает проводить товары с разным значением скидки и разным значением начисления вознаграждения. Созданная вами акция отобразится в приложении RC KASSA и может быть сразу применена.',
    },
    delete: "Вы уверены, что хотите удалить ",
    editTitle: "Редактор акций",
    deleteTitle: "Удалить акцию",
    activate: "Активировать акцию",
    deactivate: "Деактивировать акцию",
    noPromotions: "У вас еще нет акций",
    createWarning: "*До трёх активированных акций\n на одну торговую точку",
    createWarningLimit:
      "Создано максимальное количество акций для данной торговой точки",
  },

  tooltips: {
    tradingPoints: {
      name: "Данное название будет отображаться у пользователей в клиентском сервисе.",
      tags: "Будут использоваться для поиска Вашего заведения в клиентском сервисе. Правила написания: начало с букв русского/латинского алфавита, цифр,  до 50 символов, возможны с нижним подчеркиванием. Максимальное количество тегов - 20 шт.",
      description: "Будет отображаться у пользователей в клиентском сервисе.",
      businessType:
        "Служит для фильтрации и поиска Вашего заведения в клиентском сервисе.",
      isBonusPayable:
        "Укажите процент скидки, который будет предоставляться пользователям экосистемы RC Group, при покупке любого товара.",
      cashbackPercent: "Укажите общий процент вознаграждения. ",
      addressComment:
        "Вы можете указать уточнения к адресу, а именно: как пройти, предварительно позвонить и другую сопутствующую информацию.",
      links:
        'В "Название ссылки" укажите как бы Вы хотели, чтоб отображалась ссылка в клиентском сервисе. Например, https://vk.com/id123456  -  petrivanov.',
      logo: "Изображение с фирменным знаком, которое будет использоваться в качестве логотипа вашей торговой точки",
      filling:
        "Должны быть в максимально хорошем качестве, не содержать надписи, коллажи и водяные знаки",
      banner:
        "Обложка вашего заведения в каталоге торговых точек клиентского сервиса. Не должна быть логотипом, коллажом, содержать надписи и водяные знаки",
      activeWait:
        "Количество дней, через которые будет начислено вознаграждение",
    },

    registration: {
      deposit:
        "Минимальный остаток на счете. Сумма, которая остается на балансе при выводе средств.",
    },
  },

  globalMessagesButton: {
    "tt-update": "Перейти в новый подраздел “Торговые точки”",
    "clients-update": "Попробовать",
    "product-release": "Попробовать",
    "review-update-release": "Попробовать",
  },

  declinationHelperWords: {
    years: [" лет", " год", " года"],
    days: [" дней", " день", " дня"],
    grades: [" оценок", " оценка", " оценки"],
    symbols: [" символов", " символа", " символов"],
    result: [" рузальтатов", " результат", " результата"],
    review: [" отзывов", " отзыв", " отзыва"],
    star: [" звезд", " звезда", " звезды"],
    clients: [" клиентов", " клиент", " клиента"],
    product: [" товаров", " товар", " товара"],
  },

  noData: {
    title: "Данных нет",
    emptyHistory: "Ваша история пуста",
  },

  mobileFiltersName: {
    "trading-points": "Торговые точки",
    category: "Категория",
    "product-status": "Статус товара",
    "review-status": "Тип отзыва",
    sort: "Сортировка",
    date: "Период",
  },

  debt: {
    title: "Задолженность",
    text:
      "Оплатите ее с помощью счета, отправленного на электронную почту, или создайте его самостоятельно\n" +
      "в разделе “Баланс”",
    link: "Перейти к погашению",
    message:
      "Вам необходимо оплатить задолженность в размере {debt}&nbsp;{currency} <br>" +
      "Оплату можно произвести одним из способов: создать счет в личном кабинете или скачать счет, отправленный на электронную почту.",
  },

  paymentMethod: {
    title: "Способы оплаты",
    hint: "Быстрое и простое увеличение вариантов оплаты в вашей торговой точке с помощью инновационного платежного решения для бизнеса RC POS",
    nodataTitle: "Данных по способам оплаты нет",
    nodataDescription: "У вас пока нет торговых точек ",
    noModerationDataDescription:
      "У вас пока нет торговых точек, прошедших модерацию",
    haveAccountTitle: "Ваша почта уже зарегистрирована в RC POS",
    haveAccountText:
      "Если у вас ранее не были подключены способы оплаты, перейдите в свой кабинет RC POS",

    create: {
      firstStep: {
        asideTitle: "Несколько шагов и инновационное решение у вас в кармане!",
        asideText:
          "<p>Эта разработка позволяет не использовать дополнительное оборудование — нет необходимости приобретать кассу, терминал \n" +
          "и фискальный накопитель, а чековую ленту успешно заменили электронные чеки. </p>" +
          "<p>Подключение дополнительных способов оплаты\n" +
          "доступно на одну торговую точку.</p>" +
          "<p>Требования законодательства и партнерских договоров не позволяют нам обслуживать некоторые категории бизнеса:</p>",

        asideText_open:
          "<ul>" +
          "<li>— продающие алкоголь любого вида;</li>" +
          "<li>— продающие табачные изделия, наркосодержащие препараты, курительные смеси и их аналоги, включая электронные сигареты и их наполнители;</li>" +
          "<li>— продающие лекарственные средства, включая БАДы;</li>" +
          "<li>— продающие товары и контент сексуальной направленности («товары для взрослых», секс-шопы и т.п.);</li>" +
          "<li>— продающие б/у запчасти для автомобилей;</li>" +
          "<li>— продающие огнестрельное и холодное оружие, запасные части и боеприпасы к нему, а также любые товары и услуги военного назначения;</li>" +
          "<li>— организующие куплю-продажу валюты, включая криптовалюты, ценных бумаг, финансовых инструментов и их аналогов;</li>" +
          "<li>— связанные с азартными играми;</li>" +
          "<li>— букмекерские конторы;</li>" +
          "<li>— продающие товары или оказывающие услуги, нарушающие действующее законодательство или способные ввести в заблуждение или обмануть покупателя.</li>" +
          "</ul>" +
          '<p>С полным <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/ограничения.pdf">списком ограничений</a> можно ознакомиться в документе ограничений </p>',
        asideSubText: "На этом этапе происходит создание кабинета в RS POS.",
        //статичные данные
        title: "Юридическое лицо",
        tradingPoint: "Торговая точка",
        inn: "ИНН:",
        bic: "Бик банка:",
        headName: "Ф.И.О. руководителя:",
        bankName: "Название банка:",
        organizationType: "Тип организации:",
        currentAccountNumber: "Номер расчетного счета",
        address: "Юридический адрес:",
        correspondentAccount: "Корсчет:",
        // изменяемые даннык
        entityType: "Форма юрлица",
        taxType: "Тип налогообложения",
        monthlyTurnover: "Размер ежемесячного оборота",
        ogrn: "ОГРН",
        okved: "Основной вид деятельности",
        phone: "Телефон",
        oferta:
          'Я принимаю условия <a href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf" target="_blank">договора</a> сервиса RC POS',
      },
      secondStep: {
        title1: "Выберите подключаемые способы оплаты",
        online: "Онлайн",
        sbp: "СБП",
        // блок 2
        title2: "Личные данные",
        passportSeries: "Серия паспорта",
        passportNumber: "Номер паспорта",
        issuedPlace: "Кем выдан",
        dateOfIssue: "Дата выдачи",
        departmentCode: "Код подразделения",
        dateOfBirth: "Дата рождения",
        placeOfBirth: "Место рождения",
        // блок 3
        title3: "Данные о компании",
        headPosition: "Должность руководителя",
        VATPayer: "Плательщик НДС",
        procents: "Процентная ставка",
        procentsHint:
          "Будет внесена автоматически после прохождения проверки данных",
        preferredCommunicationChanel: "Канал для связи",
        factAddress: "Фактический адрес",
        processSellingGoodsAndServices: "Процесс реализации товаров/услуг",
        processSellingGoodsAndServicesHint:
          "Как это происходит и где, если у вас нет торговой точки/офиса",
        descriptionOfActivity: "Описание деятельности",
        descriptionOfActivityHint: "За что будет приниматься оплата",
        note: "Примечание",
      },

      thirdStep: {
        title: "Подписание документа",
      },
    },

    card: {
      tabs: {
        entities: "Юрлицо",
        "payment-methods": "Способы оплаты",
        documents: "Документы",
      },
    },
  },

  registration: {
    loyalty:
      'Я принимаю условия <a target="_blank" href="{link}">программы лояльности</a> партнеров RC Group',
    posOferta:
      'Я принимаю условия <a target="_blank" href="https://cdn.rc.today/storage/partners/common/docs/ru/pos/Договор%20о%20присоединении%20к%20RC%20POS.pdf">договора</a> сервиса RC POS',
    1: "Основная информация",
    2: "Персональная информация",
    3: "Юридическое лицо",
    haveAcc: "Есть аккаунт?",
    login: "Войти",

    mainInfo: {
      title: "Основная информация",
      promocode: "Промокод",
      country: "Страна",
      phone: "Номер телефона",
      checkbox:
        " Даю свое согласие на обработку персональных данных в соответствии\n" +
        "с <a download href='{link}'>Политикой конфиденциальности</a>",
      oferta: "Принимаю условия" + " <a download href='{link}'>Оферты</a>",
    },

    profileInfo: {
      title: "Персональная информация",
      firstName: "Имя",
      lastName: "Фамилия",
      middleName: "Отчество",
      email: "Почта",
      password: "Пароль",
      confPassword: "Повторите пароль",
      registerCode: "Код с почты",
      notSendCode: "Не пришел код ?",
      sendAgain: "Отправить повторно",
    },

    emailCode: {
      title: "Код подтверждения отправлен на вашу почту",
      subtitle:
        'Если вы не получили письмо на "{mail}", проверьте папку «Спам»',
      emailCode: "Код с почты",
      sendAgainTime: "Отправить код повторно через ",
      dontSend: "Не пришел код?",
      sendAgain: "Отправить повторно",
    },

    entity: {
      title: "Юридическое лицо",
      subTitle:
        "Пожалуйста, перед нажатием на кнопку «Далее», проверьте введенные данные",
      entityType: "Тип юрлица",
      halalCertificate: "Юрлицо имеет сертификат Халяль",
      fileType: "PNG, JPG, PDF - 1 файл до 10 Мб",
      commercial: "Коммерческое",
    },

    payment: {
      title: "Спасибо за регистрацию!",
      subtitle:
        "Теперь вы можете попасть в личный кабинет программы лояльности RC Group. проходит модерацию. Как правило, проверка занимает от 24 до 48 часов",
      examination:
        "Для прохождения проверки вам необходимо оплатить счет с указанного юрлица, скачав его по кнопке ниже. Вы также можете найти счет в личном кабинете: в разделе «Мои предприятия» - «Документы» под названием «Тестовый платеж».",
      pos: "Доступ к сервису RC POS вы получите после завершения проверки. Данные для входа будут отправлены на почту, указанную при регистрации.",
      toCabinet: "Перейти в кабинет",
    },
  },

  permissions: {
    analytics: "Аналитика",
    "legal-entity": "Юрлица",
    "trading-point": "Торговые точки",
    employee: "Кассиры",
    document: "Документы",
    tariff: "Тарифы",
    operation: "История операций",
    "act-and-receipt": "Акты и счета",
    manager: "Менеджеры",
    cashback: "Начисление баллов",
    balance: "Баланс",
    points: "Очки кассирам",
    // 'claim': 'claim',
    client: "Список клиентов",
    spread: "Распространение  карт Wallet",
    "wallet-pass": "Дизайн карты Wallet",
    review: "Отзывы",
    // 'application': 'application',
    "noncash-tips": "Безналичные чаевые",
    "check-counterparties": "Проверка контрагентов",
    "push-notifications": "Push-уведомления",
    // 'group': 'Начисление баллов (группы клиентов)',
    "client-portrait": "Портрет клиента",
    "active-wait": "Начисление баллов (Активное ожидание)",
    "e-menu": "Электронное меню",
    faq: "FAQ",
    terminal: "Processing",
    promotion: "Акции",
    cashback_group: "Начисление баллов (Группы клиентов)",
    payment_info: "Способы оплаты",
    orders: "Заказы",
    "trading-point-promotion": "Продвижение",
    "gift-card": "Подарочные карты",
  },

  promotionServices: {
    servicesList: {
      "xxl-tag": {
        name: "XXL-размер карточки",
        description:
          "Увеличение размера карточки вашего заведения и размещение на ней бейджа «Топ место»",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет увеличена в размере, а также выделена бейджем «Топ место» на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 1 неделя, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      tag: {
        name: "Бейдж «Топ место»",
        description:
          "Размещение на карточке вашего заведения бейджа «Топ место» и перемещение её выше в общем списке",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет выделена бейджем «Топ место» и перемещена выше в списке на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Срок размещения –  1 неделя, 2 недели, 1 месяц или 3 месяца с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>Выбрать срок размещения можно на этапе оформления заказа.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      top: {
        name: "Топ 1 или 2 место",
        description:
          "Размещение карточки вашего заведения на 1 или 2 местах в списке (без изменения её внешнего вида и размера)",
        fullDescription:
          "<ul>" +
          "<li><span>●</span><p>Карточка вашего заведения будет размещена на 1 или 2 месте на странице «Заведения» на сайте rcpay.ru и в мобильном приложении RC PAY в рамках того города и категории, к которым оно относится.</p></li>" +
          "<li><span>●</span><p>Размещение на 1 или 2 месте определяется очередностью оформления заказа и оплаты данной услуги.</p></li>" +
          "<li><span>●</span><p>Срок размещения – 2 недели с момента оформления и оплаты заказа в кабинете партнера.</p></li>" +
          "<li><span>●</span><p>При заказе данной услуги в подарок вы также получаете особую метку вашего заведения на карте.</p></li>" +
          "</ul>",
      },
      hint: "В настоящий момент все доступные слоты по данной услуге в городе вашей торговой точки заняты. Заказ будет возможен с указанной даты.",
    },
    periodsList: {
      "7 days": "1 неделя",
      "14 days": "2 недели",
      "30 days": "1 месяц (30 дн)",
      "90 days": "3 месяца (90 дн)",
    },
  },

  //   pre-orders page
  preOrders: {
    title: "Заказы",
    notAvailablePreOrders: "Заказы пока недоступны",
    noOrders: "Заказов пока нет",
    noTradingPoints:
      "<p>У вас пока нет торговых точек, которые прошли модерацию и размещены на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY</p>",
    noProducts:
      "Чтобы принимать заказы необходимо добавить товары или услуги в электронное меню",
    noOrdersText:
      "<p>Чтобы ваши товары или услуги были доступны для заказа на сайте <a class='blue-link' href='https://rcpay.ru/places/' target='_blank'>rcpay.ru</a> и в мобильном приложении RC PAY, необходимо добавить их в электронное меню и включить прием предзаказов в настройках</p>",
  },
};
