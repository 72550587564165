<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { MENU } from "../../constants/menu";
import type { TMenuItem } from "../../types";
import MainSidebarMenuItem from "./MainSidebarMenuItem.vue";
import { translateStore } from "@/store/translate";
import { dateToClintFormat } from "@/package/global-helpers/date-format";

defineOptions({
  name: "MainSidebarMenu",
});

const store = useStore();
const translate = translateStore();

const profile = computed(() => store.state.user.profile);
const tariffExpirationDate = computed(() => {
  try {
    return dateToClintFormat(
      store.state.user.profile.tariffs?.current?.expired_at
    );
  } catch {
    return null;
  }
});

const isShowWarning = computed(() => profile.value.has_debt);
const isShowWarningWithDate = computed(() => {
  return (
    store.state.user.profile.tariffs.is_purchase_available_now &&
    store.state.user.profile.tariffs?.current?.expired_at
  );
});

const isCollapsedSidebar = computed(() => {
  const value = store.state.main.isCollapsedSidebar;
  if (value) {
    collapseMenu("");
  }
  return value;
});

const tariffDebtTo = computed(() =>
  profile.value?.has_debt && isCollapsedSidebar.value ? "Balance" : "Tariff"
);
const changeTariffDate = computed(
  () =>
    `${tariffExpirationDate.value} ${translate.t("leftPanel.changeTo")} Lite`
);

const permissions = computed<Array<string>>(
  () => store.state.user.profile.permissions || []
);

const chekPermissions = (permissionsArray: Array<string>) => {
  return (
    Array.isArray(permissionsArray) &&
    permissionsArray.length &&
    permissionsArray.some(
      (el) => permissions.value.includes(el) || el === "all"
    )
  );
};

const isShowLink = (item: TMenuItem) => {
  return chekPermissions(item?.value || []) && item?.details?.length;
};

const filteredMenuArray = MENU.filter(
  (el) => isShowLink(el) || chekPermissions(el?.value)
).map((el) => {
  el.details = el.details.filter((e) => chekPermissions([e?.value]));
  return el;
});

const menuFiltered = ref(filteredMenuArray);

const collapseMenu = (nameMenu: string) => {
  menuFiltered.value.forEach((el) => {
    el.visible = el.name === nameMenu;
  });
};
</script>

<template>
  <div
    v-if="profile"
    class="main-sidebar-menu"
    :class="{ 'main-sidebar-menu_collapsed': isCollapsedSidebar }"
  >
    <RouterLink
      v-if="isShowWarning"
      class="warning"
      :class="{ warning_collapsed: isCollapsedSidebar }"
      :to="{ name: 'Balance' }"
    >
      <span class="icon-warning" />

      <div v-if="!isCollapsedSidebar" class="warning__body body">
        <p>Задолженность</p>
        <span class="icon-arrow-down warning__arrow" />
      </div>
    </RouterLink>

    <RouterLink
      v-if="isShowWarningWithDate"
      class="warning"
      :to="tariffDebtTo"
      :class="{ warning_collapsed: isCollapsedSidebar }"
    >
      <span class="icon-warning" />

      <div v-if="!isCollapsedSidebar" class="warning__message message">
        <div class="message__ttl">
          {{ translate.t("leftPanel.extendTariff") }}
        </div>

        <div class="message__content">
          {{ changeTariffDate }}
        </div>
      </div>
    </RouterLink>

    <MainSidebarMenuItem
      v-for="item in menuFiltered"
      :key="item.name"
      :item="item"
      :collapse="isCollapsedSidebar"
      @collapse="collapseMenu"
    />
  </div>
</template>

<style scoped lang="scss">
.main-sidebar-menu {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto; // Скрол нужен но скроллбар скрыт
  position: relative;
  z-index: 1;
  @include transition;

  &_collapsed {
    overflow: initial;
    gap: 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.warning {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--color-semantic-content-normal-negative);
  border-radius: 8px;
  padding: 8px;
  text-decoration: none;
  color: var(--color-semantic-content-normal-negative);
  flex-shrink: 0;
  min-height: 48px;
  @include transition;

  .icon-warning {
    width: 32px;
    height: 32px;
    background: var(--color-semantic-content-normal-negative);
    flex-shrink: 0;
  }

  &:hover {
    cursor: pointer;
    background: rgba(255, 122, 122, 0.1);
  }

  .message {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include transition;

    &__ttl {
      @include body-2;
      color: $red-second;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__content {
      @include caption;
      color: var(--color-semantic-content-normal-secondary);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .body {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    @include transition;

    > p {
      margin-right: auto;
      @include body-2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__arrow {
      width: 16px;
      height: 16px;
      background: var(--color-semantic-content-normal-negative);
      transform: rotate(-90deg);
    }
  }

  &_collapsed {
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .message,
    .body {
      animation: hide 0.3s ease-in-out forwards;
    }
  }
}

@keyframes hide {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>
