const analytics = {
  name: "analytics",
  path: "analytics",
  value: ["analytics"],
  details: [],
  visible: false,
  componentName: "Analytics",
  icon: "icon-chart_bar",
};

const loyalty = {
  name: "loyalty",
  value: ["cashback", "operation"],
  visible: false,
  icon: "icon-percent",
  details: [
    {
      name: "accrualCashback",
      parentName: "loyalty",
      path: "bally",
      value: "cashback",
      componentName: "Bally",
    },
    {
      name: "promotions",
      parentName: "loyalty",
      path: "promotions",
      value: "promotion",
      componentName: "Promotions",
    },
    {
      name: "historyPayment",
      parentName: "loyalty",
      path: "history",
      value: "operation",
      componentName: "History",
    },
  ],
};

const clients = {
  name: "clients",
  value: ["client", "review", "push-notifications"],
  visible: false,
  icon: "icon-group",
  details: [
    {
      name: "clientsList",
      parentName: "clients",
      path: "clients",
      value: "client",
      componentName: "Clients",
    },
    {
      name: "reviews",
      parentName: "clients",
      path: "reviews",
      value: "review",
      componentName: "Reviews",
    },
    {
      name: "push",
      parentName: "clients",
      path: "notifications",
      value: "push-notifications",
      componentName: "Notifications",
    },
  ],
};

const wallets = {
  name: "wallets",
  value: ["spread", "wallet-pass"],
  visible: false,
  icon: "icon-wallet_sidebar",
  details: [
    {
      name: "spread",
      parentName: "wallets",
      path: "spread",
      value: "spread",
      componentName: "Spread",
    },
    {
      name: "wallet",
      parentName: "wallets",
      path: "wallet",
      value: "wallet-pass",
      componentName: "Wallet",
    },
  ],
};

const employees_and_managers = {
  name: "employees_and_managers",
  value: ["manager", "employee", "points"],
  visible: false,
  icon: "icon-account",
  details: [
    {
      name: "managers",
      parentName: "employees",
      path: "managers",
      value: "manager",
      componentName: "Managers",
    },
    {
      name: "employees",
      parentName: "employees",
      path: "employee",
      value: "employee",
      componentName: "Employee",
    },
    {
      name: "points",
      parentName: "employees",
      path: "points",
      value: "points",
      componentName: "Points",
    },
  ],
};

const enterprises = {
  name: "enterprises",
  translate: "leftPanel.enterprises",
  value: [
    "legal-entity",
    "trading-point",
    "business",
    "document",
    "act-and-receipt",
    "tariff",
    "payment_info",
  ],
  visible: false,
  icon: "icon-building",
  details: [
    {
      name: "entities",
      parentName: "enterprises",
      path: "entities",
      value: "legal-entity",
      componentName: "Entities",
    },
    {
      name: "tradingPoint",
      parentName: "enterprises",
      path: "trading-point",
      value: "trading-point",
      componentName: "TradingPoint",
    },
    {
      name: "documents",
      parentName: "enterprises",
      path: "documents",
      value: "document",
      componentName: "Documents",
    },
    // {
    //   name: "actsAndAccounts",
    //   parentName: "enterprises",
    //   path: "acts",
    //   value: "act-and-receipt",
    //   componentName: "Acts",
    // },
    {
      name: "tariffs",
      parentName: "enterprises",
      path: "tariff",
      value: "tariff",
      componentName: "Tariff",
    },
  ],
};

const paymentDecision = {
  name: "paymentDecision",
  translate: "leftPanel.paymentDecision",
  value: ["payment_info"],
  visible: false,
  icon: "icon-qrcode",
  details: [
    {
      name: "paymentMethods",
      parentName: "PaymentDecision",
      path: "payment-methods",
      value: "payment_info",
      componentName: "PaymentMethods",
    },
  ],
};

const services = {
  name: "services",
  translate: "leftPanel.services",
  value: [
    "check-counterparties",
    "e-menu",
    "gift-card",
    "trading-point-promotion",
    "orders",
  ],
  visible: false,
  icon: "icon-service",
  details: [
    {
      name: "PromotionServices",
      parentName: "services",
      path: "promotion-services",
      value: "trading-point-promotion",
      componentName: "PromotionServices",
    },
    {
      name: "PreOrders",
      parentName: "services",
      path: "pre-orders",
      value: "orders",
      componentName: "PreOrders",
    },
    {
      name: "eMenu",
      parentName: "services",
      path: "e-menu",
      value: "e-menu",
      componentName: "Products",
    },
    {
      name: "giftCard",
      parentName: "services",
      path: "gift-card",
      value: "gift-card",
      componentName: "GiftCard",
    },
    {
      name: "counterparties",
      parentName: "services",
      path: "counterparties",
      value: "check-counterparties",
      componentName: "Counterparties",
    },
  ],
};

const processing = {
  translate: "leftPanel.processing",
  visible: false,
  icon: "icon-money",
  name: "terminals_MSPOS",
  path: "terminals-MSPOS",
  value: ["terminal"],
  componentName: "MSPOS",
  details: [],
};

const faq = {
  name: "FAQ",
  path: "FAQ",
  value: ["faq"],
  details: [],
  visible: false,
  componentName: "FAQ",
  icon: "icon-book-thin",
};

const PWA = {
  name: "PWA",
  path: "download",
  value: ["faq"],
  details: [],
  visible: false,
  componentName: "PWAView",
  icon: "icon-download-thin",
};

// Расположение здесь переменных влияет на расположение элементов в меню

export const MENU = [
  analytics,
  loyalty,
  clients,
  wallets,
  employees_and_managers,
  enterprises,
  paymentDecision,
  services,
  processing,
  faq,
  PWA,
];
