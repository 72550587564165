import { BeforeInstallPromptEvent } from "@/modules/pwa/types";
import { onMounted, ref } from "vue";

export function useDownloadAndroid() {
  const deferredPrompt = ref<BeforeInstallPromptEvent | null>(null);

  const downloadPWAAndroid = async () => {
    if (deferredPrompt.value) {
      //@ts-ignore
      await deferredPrompt.value.prompt();
      deferredPrompt.value = null;
    } else {
      console.log("Установка PWA недоступна");
    }
  };

  onMounted(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      deferredPrompt.value = event as BeforeInstallPromptEvent;
    });
  });

  return {
    downloadPWAAndroid,
  };
}
