import { format } from "date-fns";

export function dateToClintFormat(data: string): string {
  return format(data, "dd.MM.yyyy");
}

export function dateToClintFullDateFormat(data: string): string {
  return format(data, "dd.MM.yyyy HH:mm");
}

export function dateToClintTimeFormat(data: string): string {
  return format(data, "HH:mm");
}
