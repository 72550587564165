import { defineStore } from "pinia";

export enum THEME_COLORS {
  DARK = "dark",
  LIGHT = "light",
}
export type ThemeColor = "dark" | "light";

export const themeColorStore = defineStore("theme-color-store", {
  state: () => ({
    themeColor: THEME_COLORS.DARK as ThemeColor,
  }),

  getters: {
    shadowLow() {
      return "elevation-shadow-low";
    },
    shadowHigh() {
      return "elevation-shadow-high";
    },
  },

  actions: {
    SET_THEME_COLOR(value: ThemeColor) {
      // Сохраняем выбранный цвет в переменную
      this.themeColor = value;

      // Заменяем значение dataseta на выбранный цвет
      document.documentElement.dataset.theme = value;

      // Сохраняем выбранный цвет в localStorage
      localStorage.setItem("themeColor", value);
    },

    SET_THEME_COLOR_FROM_LOCAL_STORAGE() {
      // Получаем цвет для автоматичесой установки, если цвет не получили устанавливаем темный
      const color = (localStorage.getItem("themeColor") ||
        THEME_COLORS.DARK) as ThemeColor;
      // Если цвет отличается от установленной темы применяем его
      if (color !== null) {
        this.SET_THEME_COLOR(color);
      }
    },
  },
});
