function formatNumberToString(price: number): string {
  return price.toLocaleString();
}

function formatStringToString(price: string): string {
  const numberValue = formatValueToNumber(price);
  return formatNumberToString(numberValue);
}

export function formatValueToNumber(value: unknown): number {
  if (typeof value === "string") {
    const numberValue = Number(
      value.replaceAll(" ", "").replaceAll(" ", "").replaceAll(",", ".")
    );

    if (!isNaN(numberValue)) {
      return numberValue;
    }

    return 0;
  }

  return 0;
}

export function formatValueToString(value: unknown): string {
  if (typeof value === "string") {
    return formatStringToString(value);
  } else if (typeof value === "number") {
    return formatNumberToString(value);
  } else {
    return "0";
  }
}

export function insertSpacesInPrice(value: unknown) {
  if (!value) {
    return value;
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function removeSpacesFromPrice(value: unknown) {
  if (!value) {
    return value;
  }

  return Number(value.toString().replace(/\s+/g, ""));
}
